import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

interface fuelRecords {
  startDate: string,
  endDate: string,
  id: number,
  isProject: boolean,
  interval: string
}

@Injectable({
  providedIn: 'root'
})

export class AnalysisService {
  constructor(private router: Router) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('TOKEN');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
      }
    );
  }

  async getSystemAndCompanies(companyId: string, isEnergy: boolean = false) {
    try {
      const response = await axios.get(
        `${environment.apiUrl}users/filter/company/${companyId}/analysis?isEnergy=${isEnergy}`,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async fuelRecords(data:fuelRecords) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/analytics/fuel',
        {
          startDate: data.startDate,
          endDate: data.endDate,
          id: data.id,
          isProject: data.isProject,
          interval: data.interval
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async energyRecords(data:fuelRecords) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/analytics/energy',
        {
          startDate: data.startDate,
          endDate: data.endDate,
          id: data.id,
          isProject: data.isProject,
          interval: data.interval
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

// Dashboard Components
import { HomeComponent } from './pages/dashboard/home/home.component';
import { SystemsComponent } from './pages/dashboard/systems/systems.component';
import { ReportsComponent } from './pages/dashboard/reports/reports.component';
import { NotificationsComponent } from './pages/dashboard/notifications/notifications.component';
// balance
// analysis
import { ContactComponent } from './pages/dashboard/contact/contact.component';
import { ConfigsComponent } from './pages/dashboard/configs/configs.component';

import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

import { AuthGuard } from './guards/auth/auth.guard';
import { LoadingComponent } from './pages/loading/loading.component';

import { UsersComponent } from './pages/dashboard/users/users.component';

// t1 template
import { SystemComponent } from './pages/dashboard/systemPage/t1/system.component';
import { IndicatorComponent } from './pages/dashboard/indicatorPage/t1/indicator.component';
import { SummaryComponent } from './pages/dashboard/summaryPage/t1/summary.component';

import { AdmPanelComponent } from './pages/dashboard/adm-panel/adm-panel.component';
import { CompaniesComponent } from './pages/dashboard/companies/companies.component';
import { ProjectsComponent } from './pages/dashboard/projects/projects.component';
import { BalanceComponent } from './pages/dashboard/balance/balance.component';
import { AnalysisFuelComponent } from './pages/dashboard/analysis-fuel/analysis-fuel.component';
import { AnalysisEnergyComponent } from './pages/dashboard/analysis-energy/analysis-energy.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  { path: 'loading/:token', component: LoadingComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'systems',
        component: SystemsComponent
      },
      {
        path: 'configurations',
        component: ConfigsComponent
      },
      {
        path: 'system/t1/:id',
        component: SystemComponent
      },
      {
        path: 'indicator/t1/:id',
        component: IndicatorComponent
      },
      {
        path: 'summary/t1/:id',
        component: SummaryComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'adm-panel',
        component: AdmPanelComponent
      },
      {
        path: 'companies',
        component: CompaniesComponent
      },
      {
        path: 'projects',
        component: ProjectsComponent
      },
      {
        path: 'balance',
        component: BalanceComponent
      },
      {
        path: 'analysis-fuel',
        component: AnalysisFuelComponent
      },
      {
        path: 'analysis-energy',
        component: AnalysisEnergyComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('TOKEN');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   *  Get the companies list
   */
  public async getProjectList(companyId: any) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'projects/' + companyId,
        {
          headers: {}
        }
      );

      return response.data.projectsList;
    } catch (err) {
      return err;
    }
  }

  public async getLastProjectDaily(projectId: any) {
    let id = 1102;
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/project/' + id + '/daily',
        {
          headers: {}
        }
      );

      return response.data.projectDaily;
    } catch (err) {
      return err;
    }
  }

  public async getSystemsResumen(projectId: any) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'projects/' + projectId + '/fuel/resumen',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Add a project
   */
  public async addProject(name: string, companyId: any) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'projects',
        {
          name,
          companyId
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Update a project
   */
  public async updateProject(id: any, name: string) {
    try {
      const response = await axios.put(
        environment.apiUrl + 'projects/' + id,
        {
          name
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err.response.status;
    }
  }

  /**
   *  Delete a project
   */
  public async deleteProject(id) {
    try {
      const response = await axios.delete(
        environment.apiUrl + 'projects/' + id,
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
}

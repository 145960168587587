import {
  Component,
  HostListener,
  Output,
  OnInit,
  EventEmitter
} from '@angular/core';

import jwt_decode from 'jwt-decode';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from '../../services/translate-config/translate-config.service';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd,
  ParamMap
} from '@angular/router';
import { SystemsService } from '../../services/systems/systems.service';
import { SidenavService } from '../../services/sidenav/sidenav.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  sidenavMode = 'side';
  sidenavOpened = true;
  selectedLanguage: string;
  systemName: string;
  systemId: string;
  systemType: string;
  equipmentId: string;
  loadingRoutes = true;
  userProfile: any;
  summaryPage = true;
  totalAlertsAlarms = 0;
  haveHome = true;
  haveBalance = false;

  systemList: any[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private translate: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    public notificationsService: NotificationsService
  ) {
    this.initializeApp();
    this.selectedLanguage = this.translate.getDefaultLanguage();
  }

  async ngOnInit() {
    // this.userProfile = jwt_decode(localStorage.getItem('TOKEN'));
    
    this.userProfile = [];

    // Get systems by user
    const companyId = localStorage.getItem('COMPANY_ID');
    const userId = localStorage.getItem('USER_ID');
    console.log('companyId', companyId);
  
    if(companyId != '11'&& companyId != '0'){
        this.haveHome = false;
    }else{
        this.haveHome = true;
    }

    if(userId == '5' || userId == '7' || userId == '212' || userId == '216'){
      this.haveBalance = true;
    }else{
      this.haveBalance = false;
    }

    // let data = await this.systemsService.getSystemList(companyId);
    // this.systemList = data.systems;
    this.systemList = [
      {
        id: 1,
        type: 'system',
        attributes: {
          code: 'BIEABHB00Y',
          name: 'Hub Mcbo Norte',
          companyId: 1,
          projectId: 1,
          equipmentId: 1,
          timeZone: '-04:00',
          latitude: 10.671411514282228,
          longitude: -71.6159439086914,
          status: '10010',
          retries: 0,
          imgs: '["https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interMcbo1.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/InterMcbo2.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interMcbo3.jpeg"]',
          plantDetails: {
            device: {
              webserver: {
                sn: ['BIEABHB00Y'],
                key: 'zx6aiylp7b1uxti7esf85ry947689513'
              },
              krillboxInfo: {
                ders: {
                  device1: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  },
                  device2: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  }
                },
                port: 502,
                Simcard: '',
                baudrate1: 9600,
                baudrate2: 9600,
                sampleRate: 10,
                serialPort1: '/dev/ttyUSB1',
                serialPort2: '/dev/ttyUSB0'
              },
              measuredVariables: [
                {
                  oid: 'vpv',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '012',
                  varDesc: '',
                  varLabel: 'vPv',
                  multiplier: 1
                },
                {
                  oid: 'dcDcTemperature',
                  units: 'c',
                  source: 'sacolar',
                  varRef: '013',
                  varDesc: '',
                  varLabel: 'TBat',
                  multiplier: 1
                },
                {
                  oid: 'ppv',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '001',
                  varDesc: '',
                  varLabel: 'pPv',
                  multiplier: 0.001
                },
                {
                  oid: 'pBat',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '002',
                  varDesc: '',
                  varLabel: 'pBat',
                  multiplier: 0.001
                },
                {
                  oid: 'vBat',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '004',
                  varDesc: '',
                  varLabel: 'vBat',
                  multiplier: 1
                },
                {
                  oid: 'vGrid',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '005',
                  varDesc: '',
                  varLabel: 'vGrid',
                  multiplier: 1
                },
                {
                  oid: 'outPutVolt',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '006',
                  varDesc: '',
                  varLabel: 'vLoad',
                  multiplier: 1
                },
                {
                  oid: 'freqGrid',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '007',
                  varDesc: '',
                  varLabel: 'freqGrid',
                  multiplier: 1
                },
                {
                  oid: 'freqOutPut',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '008',
                  varDesc: '',
                  varLabel: 'freqLoad',
                  multiplier: 1
                },
                {
                  oid: 'outPutPower',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '009',
                  varDesc: '',
                  varLabel: 'pLoad',
                  multiplier: 0.001
                },
                {
                  oid: 'loadPercent',
                  units: 'N/A',
                  source: 'sacolar',
                  varRef: '010',
                  varDesc: '',
                  varLabel: 'loadPercent',
                  multiplier: 1
                },
                {
                  oid: 'pAcInPut',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '011',
                  varDesc: '',
                  varLabel: 'pGrid',
                  multiplier: 0.001
                }
              ]
            },
            system: {
              Elements: {
                Pv: {
                  pvInfo: 'Consort Science \n 21xCST330P6-24 \n 7kWp',
                  panelNumber: 7,
                  PvCapacityKwp: 3,
                  solarProfile_pv: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  dailyExpectedRadEnergy: 8.4
                },
                Bess: {
                  batInfo: 'Hresys VRLA \n 20 x 6-GFM(G)-150 \n 6 kWh',
                  ChrLimit: 0.1,
                  absCoeff: 0.72,
                  VBATrefGS: 0,
                  BatCapacityKwh: 36
                },
                Grid: {
                  gridInfo: 'CORPOELEC',
                  rKwhCost: 0,
                  lowervolt: 216,
                  uppervolt: 264
                },
                Load: {
                  pLoadkw: 2.2,
                  eOutProm: 24,
                  pMax_Load: 0.75,
                  loadProfile: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  xLoadPorcSacolar: 0.72
                },
                Common: {
                  rKwhCo2: 1,
                  maxCapkw: 8,
                  rCo2TreeEq: 0.1,
                  InverterQtty: 0,
                  ConverterQtty: 0,
                  SerialNumbers: ['BIE6A4501F']
                },
                Genset: {
                  type: 'linear',
                  maxLiters: 0,
                  FuelParams: {
                    inMax: 0,
                    inMin: 0,
                    outMax: 0,
                    outMin: 0
                  },
                  rDieselCost: 0,
                  rUsdCaseBase: 0,
                  fuelSensorMax: 0,
                  fuelsensorMin: 0,
                  rFuelBaseCase: 0,
                  volumeFactors: {
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0
                  },
                  sLitrosOptxhora: 0,
                  sLitrosStdxhora: 0
                },
                Inverter: {
                  peakPowerkw: 6,
                  inverterInfo: 'SACOLAR \n M6000L \n 6kW'
                }
              },
              Premisas: {
                tonCo2: 2.3,
                automEst: 10,
                automRes: 18,
                maxCapkw: 0,
                aportEnerg: 14.6,
                ePvPromDay: 8.2,
                promDayLoad: 84,
                rPaybackInitial: 0,
                autonomyElectrogeno: 0
              },
              Variables: {
                Diario: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo diario',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del dia',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio diario de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo diario de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo diario de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto diario de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio diario de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo diario de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo diario de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedios diario de pBat por hora',
                      varLabel: 'pBatHourlyMeans'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatDisToday',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatChrToday',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMtbDis',
                      varLabel: 'sBatMtbDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMDis',
                      varLabel: 'sBatMDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio diario de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo diario de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del dia de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del dia de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo diario de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del dia de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Maximo diario de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Ultimo valor del dia de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor del dia de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor del dia de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del dia de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '015',
                      varDesc: 'Ultimo valor del dia de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Status: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Status de las notificaciones del sistema',
                      varLabel: 'sNotifications'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Status del modulo de baterias del sistema',
                      varLabel: 'sBat'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Status de la red',
                      varLabel: 'sGrid'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Status de la carga',
                      varLabel: 'sLoad'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Status de la energia solar',
                      varLabel: 'sPv'
                    }
                  ]
                },
                Mensual: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo mensual',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del mes',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas del mes',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Cantidad de ciclos de la bateria en el mes',
                      varLabel: 'sCyclesMonth'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Promedio mensual de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Maximo mensual de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo mensual de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo absoluto mensual de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '003',
                      varDesc: 'Promedio mensual de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Maximo mensual de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Minimo mensual de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma mensual de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma total de eBatDisToday',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      varRef: '003',
                      varDesc:
                        'Acumulada de energía utilizada por la bateria en el mes',
                      varLabel: 'eBatUsed'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria en el mes',
                      varLabel: 'batDisCyclesRatio'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria desde la puesta en servicio del sistema',
                      varLabel: 'batDisCyclesRatioTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMax',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc:
                        'Promedio absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMean',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadMean x 24',
                      varLabel: 'eLoadMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadAbsoluteMean x 24',
                      varLabel: 'eLoadAbsoluteMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Ultimo valor del mes de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: '(eLoadTotal - lastRecord.eLoadTotal) / 1000',
                      varLabel: 'eLoadUsed'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sGridFail - lastRecord.sGridFail',
                      varLabel: 'sGridFailMonth'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo mensual de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '007',
                      varDesc:
                        'Maximo absoluto mensual de sLongestFailHour (lastRecord)',
                      varLabel: 'sAbsoluteLongestFailHour',
                      ignoresDefault: true
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del mes de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime - lastRecord.sGridDownTime',
                      varLabel: 'sGridDownTimeMonth'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime / sGridFail',
                      varLabel: 'tMeanGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTimeMonth / sGridFailMonth',
                      varLabel: 'tMeanGridDownTimeMonth'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo mensual de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo absoluto mensual de pPv (lastRecord)',
                      varLabel: 'pPvAbsoluteMax',
                      ignoresDefault: true
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del mes de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '009',
                      varDesc:
                        '(ePvTotal - lastRecord.ePvTotal) / (dias del mes)',
                      varLabel: 'ePvDailyMean'
                    },
                    {
                      varRef: '009',
                      varDesc: 'ePvTotal / (dias desde la instalacion)',
                      varLabel: 'ePvAbsoluteDailyMean'
                    },
                    {
                      varRef: '009',
                      varDesc:
                        '(ePvTotal - lastRecord.ePvTotal) / eLoadMean24h',
                      varLabel: 'sPvLoad24hRatio'
                    },
                    {
                      varRef: '009',
                      varDesc: 'ePvTotal / eLoadAbsoluteMean24h',
                      varLabel: 'sPvAbsoluteLoad24hRatio'
                    },
                    {
                      varRef: '009',
                      varDesc: '(ePvTotal - lastRecord.ePvTotal) / 1000',
                      varLabel: 'ePvUsed'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del mes de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '010',
                      varDesc: 'sLoadDownTime - lastRecord.sLoadDownTime',
                      varLabel: 'sLoadDownTimeMonth'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTime - sLoadDownTime',
                      varLabel: 'sDownTime'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTimeMonth - sLoadDownTimeMonth',
                      varLabel: 'sDownTimeMonth'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor del mes de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2 - lastRecord.sKgCo2',
                      varLabel: 'sKgCo2Month'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2 x rCo2TreeEq',
                      varLabel: 'sKgCo2TreeEq'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2Month x rCo2TreeEq',
                      varLabel: 'sKgCo2TreeEqMonth'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor del mes de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '013',
                      varDesc: '(eGridTotal - lastRecord.eGridTotal) / 1000',
                      varLabel: 'eGridUsed'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del mes de sGridEvents',
                      varLabel: 'sGridEvents'
                    },
                    {
                      varRef: '014',
                      varDesc: 'sGridEvents - lastRecord.sGridEvents',
                      varLabel: 'sGridEventsMonth'
                    },
                    {
                      varRef: '018',
                      varDesc: '(ePvUsed / eLoadUsed) / permisas.aportEnerg',
                      varLabel: 'uDiffAportePv'
                    },
                    {
                      varRef: '018',
                      varDesc: '(ePvTotal / eLoadTotal) / permisas.aportEnerg',
                      varLabel: 'uAbsoluteDiffAportePv'
                    },
                    {
                      varRef: '018',
                      varDesc:
                        '(eLoadUsed / (dias del mes)) / permisas.ePvPromDay',
                      varLabel: 'uDiffMeanELoad'
                    },
                    {
                      varRef: '018',
                      varDesc:
                        '(eLoadTotal / (dias de instalacion)) / permisas.ePvPromDay',
                      varLabel: 'uAbsoluteDiffMeanELoad'
                    },
                    {
                      varRef: '018',
                      varDesc: 'ePvDailyMean / permisas.ePvPromDay',
                      varLabel: 'uDiffMeanEPv'
                    },
                    {
                      varRef: '018',
                      varDesc:
                        '(ePvTotal / (dias de instalacion)) / permisas.ePvPromDay',
                      varLabel: 'uAbsoluteDiffMeanEPv'
                    }
                  ]
                },
                Semanal: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo semanal',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras de la semana',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas de la semana',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio semanal de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo semanal de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo semanal de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto semanal de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio semanal de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo semanal de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo semanal de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Promedio semanal de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo semanal de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Minimo semanal de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'eBatDisSum / sBatDis',
                      varLabel: 'eBatDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor de la semana de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo valor de la semana de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMDis',
                      varLabel: 'sBatMDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMDisSum / sBatDis',
                      varLabel: 'sBatMDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMtbDis',
                      varLabel: 'sBatMtbDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMtbDisSum / sBatDis',
                      varLabel: 'sBatMtbDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sampleTime / sBatDis',
                      varLabel: 'sBatDisSampleTimeRatio'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio semanal de eBatChrToday',
                      varLabel: 'eBatChrMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo semanal de eBatChrToday',
                      varLabel: 'eBatChrMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Minimo semanal de eBatChrToday',
                      varLabel: 'eBatChrMin'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Suma semanal de eBatChrToday',
                      varLabel: 'eBatChrSum'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor de la semana de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor de la semana de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Ultimo valor de la semana de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Maximo semanal de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor de la semana de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Maximo semanal de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor de la semana de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor de la semana de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '015',
                      varDesc: 'Ultimo valor de la semana de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '016',
                      varDesc: 'Ultimo valor de la semana de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '017',
                      varDesc: 'Ultimo valor de la semana de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Indirectas: {
                  vars: [
                    {
                      units: 'kWh',
                      varRef: '069',
                      varDesc:
                        'Potencia de carga en funcion de la potencia de la red',
                      varLabel: 'pLoad'
                    },
                    {
                      units: 'kWh',
                      varRef: '076',
                      varDesc:
                        'Potencia de la red en funcion de la potencia de la carga',
                      varLabel: 'pGrid'
                    },
                    {
                      units: '',
                      varRef: '021',
                      varDesc: 'Estado de la red',
                      varLabel: 'sGridState'
                    },
                    {
                      units: '',
                      varRef: '048',
                      varDesc: 'Estado de la carga',
                      varLabel: 'sLoadState'
                    },
                    {
                      units: '',
                      varRef: '071',
                      varDesc: 'Estado de la potencia del PV',
                      varLabel: 'sPvState'
                    },
                    {
                      units: '',
                      varRef: '072',
                      varDesc: 'Estado de la bateria',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'kWh',
                      varRef: '007',
                      varDesc: 'Daily grid energy',
                      varLabel: 'eGridToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '008',
                      varDesc: 'Total grid energy',
                      varLabel: 'eGridTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '009',
                      varDesc: 'Daily load energy',
                      varLabel: 'eLoadToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '010',
                      varDesc: 'Total load energy',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '011',
                      varDesc: 'Daily pv energy',
                      varLabel: 'ePvToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '012',
                      varDesc: 'Total pv energy',
                      varLabel: 'ePvTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '013',
                      varDesc: 'Daily bat discharged energy',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '014',
                      varDesc: 'Total bat discharged energy',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '015',
                      varDesc: 'Daily bat charged energy',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '016',
                      varDesc: 'Total bat charged energy',
                      varLabel: 'eBatChrTotal'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc:
                        'Cambio en la energia de la bateria desde la ultima lectura',
                      varLabel: 'eDelta'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc: 'Porcentaje de la carga en funcion de pBat',
                      varLabel: 'xSoC',
                      defaultValue: 85
                    },
                    {
                      units: '',
                      varRef: '027',
                      varDesc: 'Modo de operacion',
                      varLabel: 'sOpMode'
                    },
                    {
                      units: 'h',
                      varRef: '028',
                      varDesc: 'Time since grid is available',
                      varLabel: 'sGridAvailableTimestamp'
                    },
                    {
                      units: 'h',
                      varRef: '029',
                      varDesc: 'Time since load is available',
                      varLabel: 'sLoadAvailableTimestamp'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Flag de evento de carga',
                      varLabel: 'sLoadEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Amount of load events',
                      varLabel: 'sLoadEvents'
                    },
                    {
                      units: 'h',
                      varRef: '032',
                      varDesc: 'Tiempo de carga activa',
                      varLabel: 'sLoadUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '033',
                      varDesc: 'Tiempo de carga inactiva',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '034',
                      varDesc: 'Availability % of load',
                      varLabel: 'sLoadDisp'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Flag de evento de red',
                      varLabel: 'sGridEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Amount of grid events',
                      varLabel: 'sGridEvents'
                    },
                    {
                      units: 'h',
                      varRef: '036',
                      varDesc: 'Tiempo de red activa',
                      varLabel: 'sGridUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '037',
                      varDesc: 'Tiempo de red inactiva',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '038',
                      varDesc: 'Availability % of grid',
                      varLabel: 'sGridDisp'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Maximo tiempo consecutivo de falla de red',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Tiempo de falla actual de red',
                      varLabel: 'sActualFailTime'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Flag de falla de red',
                      varLabel: 'sGridFailFlag'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Contador de fallas de red',
                      varLabel: 'sGridFail'
                    },
                    {
                      units: 'h',
                      varRef: '045',
                      varDesc: 'Mean time before grid fail',
                      varLabel: 'sGridMtbf'
                    },
                    {
                      units: 'h',
                      varRef: '046',
                      varDesc: 'Mean time to repair grid fail',
                      varLabel: 'sGridMttr'
                    },
                    {
                      units: 'h',
                      varRef: '047',
                      varDesc: 'Hours of backup',
                      varLabel: 'sBackupHours'
                    },
                    {
                      units: 'Kg',
                      varRef: '052',
                      varDesc: 'kgco2 saved',
                      varLabel: 'sKgCo2'
                    },
                    {
                      units: '%',
                      varRef: '057',
                      varDesc: 'status de descarga de bateria (on/off)',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'none',
                      varRef: '057',
                      varDesc:
                        'Es 1 si ocurre una falla de red, y durante su prolongación. Es 0 cuando la red está presente',
                      varLabel: 'sBatDisFlag'
                    },
                    {
                      units: '',
                      varRef: '070',
                      varDesc:
                        'Ciclos de carga de la bateria dados xSoC y sBatDisFlag',
                      varLabel: 'sCycles'
                    },
                    {
                      units: 'kWh[]',
                      varRef: '056',
                      varDesc: 'Perfil de carga por hora',
                      varLabel: 'pLoadProfile',
                      ignoresDefault: true
                    },
                    {
                      units: 'h',
                      varRef: '053',
                      varDesc: 'Resilient autonomy',
                      varLabel: 'tAutonomyRest'
                    },
                    {
                      units: 'kWh[]',
                      varRef: '053',
                      varDesc: 'Perfil del dia de PV por hora',
                      varLabel: 'pPvProfile',
                      ignoresDefault: true
                    },
                    {
                      units: 'h',
                      varRef: '074',
                      varDesc: 'Standard autonomy given pLoadProfile',
                      varLabel: 'tAutonomyStd'
                    },
                    {
                      units: 'USD',
                      varRef: '073',
                      varDesc: 'Ahorro en USD por kWh de PV',
                      varLabel: 'rKwhCost'
                    },
                    {
                      units: '',
                      varRef: '089',
                      varDesc: 'pPv / pPvProfile[hour]',
                      varLabel: 'sAprovPv'
                    },
                    {
                      units: '',
                      varRef: '089',
                      varDesc: 'pPv / pLoad',
                      varLabel: 'sAportePv'
                    },
                    {
                      units: '',
                      varRef: '090',
                      varDesc: 'tAutonomyStd / permisas.automEst',
                      varLabel: 'uDiffAutonomyStd'
                    },
                    {
                      units: '',
                      varRef: '090',
                      varDesc:
                        'if (tAutonomyRest) tAutonomyRest / permisas.automRes else undefined',
                      varLabel: 'uDiffAutonomyRest'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la carga',
                      varLabel: 'iLoad'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la bateria',
                      varLabel: 'iBat'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la red',
                      varLabel: 'iGrid'
                    }
                  ],
                  UpdatesTimestamps: {
                    dailyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    weeklyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    firstEventUpdateTimestamp: '2021-01-01 00:00:00.000000'
                  }
                },
                Notificaciones: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería baja',
                      varLabel: 'vBatLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado baja',
                      varLabel: 'vBatVeryLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería alta',
                      varLabel: 'vBatHigh'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado alta',
                      varLabel: 'vBatVeryHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería alta',
                      varLabel: 'TBatHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería demasiado alta',
                      varLabel: 'TBatVeryHigh'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el primer rango',
                      varLabel: 'xSoCFirstRange'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el segundo rango',
                      varLabel: 'xSoCSecondRange'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Estado de carga de las baterías bajo',
                      varLabel: 'xSoCLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Autonomia proyectada minimamente baja',
                      varLabel: 'tAutonomyStdLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga',
                      varLabel: 'tAutonomyStdDis'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga extendida',
                      varLabel: 'tAutonomyStdDisExtended'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Carga del sistema no conectada',
                      varLabel: 'pLoadLow'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Arreglo solar desconectado',
                      varLabel: 'vPvLow'
                    }
                  ],
                  parameters: {
                    vPv: {
                      lowThreshold: 60
                    },
                    TBat: {
                      highThreshold: 40,
                      veryHighThreshold: 45
                    },
                    vBat: {
                      lowThreshold: {
                        lower: 42,
                        upper: 44
                      },
                      highThreshold: 58.5,
                      veryLowThreshold: 42,
                      veryHighThreshold: {
                        lower: 57,
                        upper: 58.5
                      }
                    },
                    xSoC: {
                      lowThreshold: 5,
                      firstRangeThreshold: {
                        lower: 50,
                        upper: 75
                      },
                      secondRangeThreshold: {
                        lower: 25,
                        upper: 50
                      }
                    },
                    pLoad: {
                      lowThreshold: 150
                    },
                    tAutonomyStd: {
                      disThreshold: {
                        lower: 4,
                        upper: 6
                      },
                      lowThreshold: 2,
                      disExtendedThreshold: {
                        lower: 2,
                        upper: 4
                      }
                    }
                  }
                }
              },
              FrontLimits: {
                xMaxBB: 4,
                xMaxBC: 100,
                xMaxGB: 0,
                xMaxGC: 0,
                xMaxGG: 0,
                xMaxLB: 2.2,
                xMaxLC: 40,
                xMaxRB: 2.2,
                xMaxRC: 40,
                xMaxRG: 264,
                xMaxSB: 6,
                xMaxSC: 6,
                xMinGG: 0,
                xMinRG: 216,
                xMaxHC1: 18,
                limitBar: 5,
                xMaxAxe1BG: 100,
                xMaxAxe1GG: 0,
                xMaxAxe1LG: 3.5,
                xMaxAxe1RG: 280,
                xMaxAxe1SG: 8,
                xMaxAxe2BG: 4,
                xMinAxe1BG: 0,
                xMinAxe1GG: 0,
                xMinAxe1LG: -1,
                xMinAxe1RG: 190,
                xMinAxe1SG: -2,
                xMixAxe2BG: -4,
                xMaxAxelIDGA: 8,
                xMaxAxelIDGB: 120,
                xMaxAxexIRPA: 150000,
                xMinAxelIDGA: -2,
                xMinAxelIDGB: -2,
                xMinAxevIRPA: 0
              },
              SutentabilityData: {
                var: 'epvTotal',
                value: 0.2025
              }
            }
          },
          structure: {
            common: {
              ha1: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'horas',
                label: 'OPERACIÓN RESPALDADA'
              },
              ha2: {
                dir: 'records',
                var: 'rUsdSaved',
                unit: 'USD',
                label: 'AHORRO FINANCIERO'
              },
              ha3: {
                dir: 'records',
                var: 'sKgCo2',
                unit: 'kgCO2',
                label: 'REDUCCION EMISIONES'
              },
              hb1: {
                dir: 'records',
                var: 'sWeatherIc',
                unit: '',
                label: ''
              },
              hb2: {
                dir: 'records',
                var: 'temp',
                unit: '°C',
                label: ''
              },
              hb3: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'date',
                label: ''
              },
              hb4: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'hour',
                label: ''
              },
              hb5: {
                dir: 'status',
                var: 'conn',
                unit: '',
                label: ''
              },
              hb6: {
                dir: 'status',
                var: 'lastReport',
                unit: '',
                label: ''
              },
              hc1: {
                dir: 'records',
                var: 'tAutonomyRest',
                unit: 'horas',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxHC1'
                }
              },
              lg1: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg2: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg3: {
                dir: 'records',
                var: 'pGrid',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg4: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg5: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              systemTabs: {
                load: true,
                solar: true,
                batteries: true,
                electricNet: true,
                generatorSet: false
              },
              indicatorRoute: true,
              inactiveIndicators: {
                availability: true,
                profitability: false,
                sustainability: true
              }
            },
            system: {
              ba: {
                dir: '',
                var: 'sBatState',
                unit: '',
                label: 'Batería'
              },
              bb: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: 'Potencia batería',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBB'
                }
              },
              bc: {
                dir: 'records',
                var: 'xSoC',
                unit: '%',
                label: 'Estado de carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBC'
                }
              },
              bd: {
                dir: 'records',
                var: 'vBat',
                unit: 'V',
                label: 'Tensión batería',
                subUnit: ''
              },
              be: {
                dir: 'records',
                var: 'iBat',
                unit: 'A',
                label: 'Corriente batería',
                subUnit: ''
              },
              bf: {
                dir: 'records',
                var: 'TBat',
                unit: '°C',
                label: 'Temperatura baterías',
                subUnit: ''
              },
              bg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: ['Estado de carga', 'Potencia (kW)'],
                limits: {
                  dir: 'plantDetails',
                  varMax: ['xMaxAxe1BG', 'xMaxAxe2BG'],
                  varMin: ['xMinAxe1BG', 'xMinAxe2BG']
                }
              },
              ga: {
                dir: 'records',
                var: 'engineState',
                unit: '',
                label: 'Grupo electrógeno'
              },
              gb: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: 'Potencia GE',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGB'
                }
              },
              gc: {
                dir: 'records',
                var: 'xFuelPercent',
                unit: '%',
                label: 'Nivel combustible',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGC'
                }
              },
              gd: {
                dir: 'records',
                var: 'vGenset',
                unit: 'V',
                label: 'Tensión GE',
                subUnit: ''
              },
              ge: {
                dir: 'records',
                var: 'sGenAvailable',
                unit: '',
                label: 'Último arranque',
                subUnit: ''
              },
              gf: {
                dir: 'records',
                var: 'xFuelLevel',
                unit: 'L',
                label: 'Combustible disponible',
                subUnit: ''
              },
              gg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinGG',
                  upper: 'xMaxGG',
                  varMax: 'xMaxAxe1GG',
                  varMin: 'xMinAxe1GG'
                }
              },
              la: {
                dir: '',
                var: 'sLoadState',
                unit: '',
                label: 'Carga'
              },
              lb: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: 'Potencia carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLB'
                }
              },
              lc: {
                dir: 'weekly',
                var: 'eLoadTotal',
                unit: 'kWh',
                label: 'Consumo promedio diario',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLC'
                }
              },
              ld: {
                dir: 'records',
                var: 'vLoad',
                unit: 'V',
                label: 'Tensión carga',
                subUnit: ''
              },
              le: {
                dir: 'records',
                var: 'iLoad',
                unit: 'A',
                label: 'Corriente de carga',
                subUnit: ''
              },
              lf: {
                dir: 'records',
                var: 'pfLoad',
                unit: 'H',
                label: 'Factor de potencia',
                subUnit: 'z'
              },
              lg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1LG',
                  varMin: 'xMinAxe1LG'
                }
              },
              ra: {
                dir: '',
                var: 'sGridState',
                unit: '',
                label: 'Red eléctrica'
              },
              rb: {
                dir: 'records',
                var: 'pGrid',
                unit: 'kW',
                label: 'Potencia red',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRB'
                }
              },
              rc: {
                dir: 'weekly',
                var: 'eGridTotal',
                unit: 'kWh',
                label: 'Consumo promedio diario',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRC'
                }
              },
              rd: {
                dir: 'records',
                var: ['vGrid'],
                unit: 'V',
                label: 'Tensión de red',
                subUnit: ''
              },
              re: {
                dir: 'records',
                var: ['iGrid'],
                unit: 'A',
                label: 'Corriente de red',
                subUnit: ''
              },
              rf: {
                dir: 'records',
                var: 'freqGrid',
                unit: 'H',
                label: 'Frecuencia',
                subUnit: 'z'
              },
              rg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinRG',
                  upper: 'xMaxRG',
                  varMax: 'xMaxAxe1RG',
                  varMin: 'xMinAxe1RG'
                }
              },
              sa: {
                dir: '',
                var: 'sPvState',
                unit: '',
                label: 'Solar'
              },
              sb: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: 'Potencia PV',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSB'
                }
              },
              sc: {
                dir: 'weekly',
                var: 'eGridTotal',
                unit: 'kWh',
                label: 'Aporte promedio diario',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSC'
                }
              },
              sd: {
                dir: 'records',
                var: 'vPv',
                unit: 'V',
                label: 'Tensión PV',
                subUnit: ''
              },
              se: {
                dir: 'records',
                var: 'TWeather',
                unit: '°C',
                label: 'Temperatura ambiente',
                subUnit: ''
              },
              sf: {
                dir: 'records',
                var: 'sAprovPV',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              sg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1SG',
                  varMin: 'xMinAxe1SG'
                }
              }
            },
            summary: {
              cards: {
                fb1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fb2: {
                  dir: 'records',
                  var: 'sBatState',
                  unit: '',
                  label: 'charging'
                },
                fb3: {
                  dir: 'records',
                  var: 'sBatState',
                  unit: '',
                  label: 'discharging'
                },
                fb4: {
                  dir: 'records',
                  var: 'xSoC',
                  unit: '%',
                  label: 'Estado de carga',
                  subUnit: ''
                },
                fb5: {
                  dir: 'records',
                  var: 'vBat',
                  unit: 'V',
                  label: 'Tensión batería',
                  subUnit: 'DC'
                },
                fg1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fg2: {
                  dir: 'records',
                  var: 'sGenState',
                  unit: '',
                  label: 'connected'
                },
                fg3: {
                  dir: 'records',
                  var: 'sGenState',
                  unit: '',
                  label: 'disconnected'
                },
                fg4: {
                  dir: 'records',
                  var: 'xFuelPercent',
                  unit: '%',
                  label: 'Nivel combustible',
                  subUnit: ''
                },
                fg5: {
                  dir: 'records',
                  var: 'sGenAvailable',
                  unit: '',
                  label: 'Último arranque',
                  subUnit: ''
                },
                fl1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fl2: {
                  dir: 'records',
                  var: 'sLoadState',
                  unit: '',
                  label: 'connected'
                },
                fl3: {
                  dir: 'records',
                  var: 'sLoadState',
                  unit: '',
                  label: 'disconnected'
                },
                fl4: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: 'kW',
                  label: 'Potencia carga'
                },
                fl5: {
                  dir: 'weekly',
                  var: 'eLoadTotal',
                  unit: 'kWh',
                  label: 'Consumo promedio diario'
                },
                fr1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fr2: {
                  dir: 'records',
                  var: 'sGridState',
                  unit: '',
                  label: 'connected'
                },
                fr3: {
                  dir: 'records',
                  var: 'sGridState',
                  unit: '',
                  label: 'disconnected'
                },
                fr4: {
                  dir: 'records',
                  var: 'vGrid',
                  unit: 'V',
                  label: 'Tensión red',
                  subUnit: 'AC'
                },
                fr5: {
                  dir: 'records',
                  var: 'sGridAvailableTimestamp',
                  unit: '',
                  label: 'Disponible desde'
                },
                fs1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fs2: {
                  dir: 'records',
                  var: 'sPvState',
                  unit: '',
                  label: 'connected'
                },
                fs3: {
                  dir: 'records',
                  var: 'sPvState',
                  unit: '',
                  label: 'disconnected'
                },
                fs4: {
                  dir: 'records',
                  var: 'sAprovPV',
                  unit: '%',
                  label: 'Aporte solar',
                  subUnit: ''
                },
                fs5: {
                  dir: 'records',
                  var: 'sAprovPV',
                  unit: '%',
                  label: 'Desempeño solar',
                  subUnit: ''
                }
              },
              promises: {
                pa: {
                  dir: 'plantDetails',
                  var: 'maxCapkw',
                  unit: 'kW',
                  label: 'Energía diaria carga'
                },
                pb: {
                  dir: 'plantDetails',
                  var: 'promDayLoad',
                  unit: 'kWh',
                  label: 'Energía diaria carga'
                },
                pc: {
                  dir: 'plantDetails',
                  var: 'automEst',
                  unit: 'h',
                  label: 'Autonomía estandar'
                },
                pd: {
                  dir: 'plantDetails',
                  var: 'automRes',
                  unit: 'h',
                  label: 'Autonomía de resiliente'
                },
                pe: {
                  dir: 'plantDetails',
                  var: 'autonomyElectrogeno',
                  unit: 'dias',
                  label: 'Autonomía de electrógeno'
                },
                t1: {
                  label: 'Premisas'
                },
                t2: {
                  label: 'de operación'
                }
              }
            },
            indicator: {
              idda: {
                dir: 'plantDetails',
                var: 'promDayLoad',
                unit: 'kWh',
                label: 'Energía diaria carga',
                subUnit: ''
              },
              iddb: {
                dir: 'records',
                var: 'weekpromLoad',
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddc: {
                dir: ['plantDetails', 'records'],
                var: ['promDayLoad', 'weekpromLoad'],
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddd: {
                dir: 'plantDetails',
                var: 'automEst',
                unit: 'h',
                label: 'Autonomía estándar',
                subUnit: ''
              },
              idde: {
                dir: 'records',
                var: 'tAutonomyStd',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddf: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyStd', 'automEst'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddg: {
                dir: 'plantDetails',
                var: 'automRes',
                unit: 'h',
                label: 'Autonomía resiliente',
                subUnit: ''
              },
              iddh: {
                dir: 'records',
                var: 'tAutonomyRest',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddi: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyRest', 'automRes'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddj: {
                dir: 'plantDetails',
                var: 'autonomyElectrogeno',
                unit: 'días',
                label: 'Autonomía de electrógeno',
                subUnit: ''
              },
              iddk: {
                dir: 'records',
                var: 'tAutonomyGen',
                unit: 'días',
                label: '',
                subUnit: ''
              },
              iddl: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyGen', 'autonomyElectrogeno'],
                unit: 'días',
                label: '',
                subUnit: ''
              },
              idga: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                title: 'Energia semana anterior',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGB',
                  varMin: 'xMinAxelIDGB'
                }
              },
              idgb: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Energía acumulada (kWh)',
                title: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGA',
                  varMin: 'xMinAxelIDGA'
                }
              },
              idpa: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Próximo retanqueo',
                subUnit: ''
              },
              idpb: {
                dir: 'records',
                var: '',
                unit: 'kWh',
                label: 'Consumo mensual',
                subUnit: ''
              },
              idpc: {
                dir: 'records',
                var: '',
                unit: 'H',
                label: 'Promedio de cortes',
                subUnit: ''
              },
              idpd: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Promedio entre cortes',
                subUnit: ''
              },
              idpe: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 85, 25, 15, 10, 0, 0, 0
                      ],
                      label: 'Autonomía estándar',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 85, 65, 45, 15, 5, 0
                      ],
                      label: 'Autonomía resiliente',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(0,70,210,0.40)',
                      backgroundColor: 'rgba(0,205,255,0.5)',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB. SATISFACER DEMANDA', 'DURACIÓN FALLA (horas)'],
                title: 'Autonomía estocástica',
                subUnit: ''
              },
              idpf: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 90, 75, 50, 25, 15, 5
                      ],
                      type: 'line',
                      label: 'Autonomía estándar',
                      yAxisID: 'linear',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(255,0,0,0.7)',
                      backgroundColor: 'rgba(255,0,0,1)',
                      pointBackgroundColor: 'rgba(255,0,0,0.7)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB.Vida Util BESS', 'Años'],
                title: 'Expectativa de vida estocástica',
                subUnit: ''
              },
              idva: {
                dir: 'records',
                var: 'sLoadDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvb: {
                dir: 'records',
                var: 'sGridDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvc: {
                dir: 'records',
                var: 'eOutputTotal',
                unit: 'load image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvd: {
                dir: 'records',
                var: 'sLoadEvents',
                unit: '#',
                label: 'Eventos',
                subUnit: ''
              },
              idve: {
                dir: 'records',
                var: 'sGridFail',
                unit: '#',
                label: 'Cortes',
                subUnit: ''
              },
              idvf: {
                dir: 'records',
                var: 'eInputTotal',
                unit: 'electricalnet image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvg: {
                dir: 'records',
                var: 'sLoadupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvh: {
                dir: 'records',
                var: 'sGridupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvi: {
                dir: 'records',
                var: 'eBatDisToday',
                unit: 'battery image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvj: {
                dir: 'records',
                var: 'sLoadDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvk: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvl: {
                dir: 'records',
                var: 'ePVTotal',
                unit: 'solar image',
                label: 'kWh totales',
                subUnit: ''
              },
              irda: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Horas de uso Genset',
                subUnit: ''
              },
              irdb: {
                dir: 'records',
                var: 'sGensetupTime',
                unit: '',
                label: 'h',
                subUnit: ''
              },
              irdc: {
                dir: 'records',
                var: ['sGensetDownTime', 'sGensetupTime', 'sGensetDownTime'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdd: {
                dir: 'records',
                var: 'rFuelBaseCase',
                unit: 'l',
                label: 'Consumo combustible',
                subUnit: ''
              },
              irde: {
                dir: 'records',
                var: 'mFuelCount',
                unit: '',
                label: 'l',
                subUnit: ''
              },
              irdf: {
                dir: ['plantDetails', 'records', 'plantDetails'],
                var: ['rFuelBaseCase', 'mFuelCount', 'rFuelBaseCase'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdg: {
                dir: 'records',
                var: 'rUsdCaseBasegrid',
                unit: 'USD',
                label: 'Gastos operativos',
                subUnit: ''
              },
              irdh: {
                dir: 'records',
                var: 'rUsdSystem',
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdi: {
                dir: 'records',
                var: ['rUsdCaseBasegrid', 'rUsdSystem'],
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdj: {
                dir: 'plantDetails',
                var: 'rPaybackInitial',
                unit: 'yrs',
                label: 'Payback simple',
                subUnit: ''
              },
              irdk: {
                dir: 'records',
                var: 'rPaybackSystem',
                unit: '',
                label: 'yrs',
                subUnit: ''
              },
              irdl: {
                dir: 'records',
                var: 'rDifPayb',
                unit: '',
                label: '%',
                subUnit: ''
              },
              irpa: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'USD ($)',
                title: 'Proyección ahorros USD',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxexIRPA',
                  varMin: 'xMinAxevIRPA'
                }
              },
              irpb: {
                dir: 'records',
                var: 'N/A',
                unit: 'motor image',
                label: 'Días / próximo retanqueo',
                subUnit: ''
              },
              irpc: {
                dir: 'records',
                var: ' N/A',
                unit: 'motor image',
                label: 'Días / próximo mantenimiento',
                subUnit: ''
              },
              isda: {
                dir: 'plantDetails',
                var: 'ePvPromDay',
                unit: 'kWh/día',
                label: 'Energía diaria PV',
                subUnit: ''
              },
              isdb: {
                dir: 'records',
                var: 'weekpromPv',
                unit: '',
                label: 'kWh/día',
                subUnit: ''
              },
              isdc: {
                dir: 'records',
                var: 'uSolarDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdd: {
                dir: 'records',
                var: 'ukgCO2BaseCase',
                unit: 'TON',
                label: 'Emisiones generadas',
                subUnit: ''
              },
              isde: {
                dir: 'records',
                var: 'ukgCO2System',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdf: {
                dir: 'records',
                var: 'sKgCO2',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdg: {
                dir: 'records',
                var: 'aportEnerg',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              isdh: {
                dir: 'records',
                var: 'uPVvsLoadSystem',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdi: {
                dir: 'records',
                var: 'uPVvsLoadDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              ispa: {
                dir: 'week table, indicator-sustainability-projection',
                var: '',
                unit: '°C',
                label: '',
                subUnit: ''
              },
              ispb: {
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        5.39, 5.75, 6.18, 5.83, 5.51, 5.48, 5.78, 5.89, 5.8,
                        5.38, 5.03, 4.97
                      ],
                      label: 'PV',
                      yAxisID: 'first-y-axis',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(26,46,127,0.7)',
                      backgroundColor: 'rgba(26,46,127,0.38)',
                      pointBackgroundColor: 'rgba(26,46,127,0.7)'
                    }
                  ],
                  labels: [
                    'ene.',
                    'feb.',
                    'mar.',
                    'abr.',
                    'may.',
                    'jun.',
                    'jul.',
                    'ago.',
                    'sept.',
                    'oct.',
                    'nov.',
                    'dic.'
                  ]
                },
                label: 'Potencia (W)',
                title: 'Radiación promedio mensual'
              }
            }
          },
          connected: true
        }
      },
      {
        id: 2,
        type: 'system',
        attributes: {
          code: 'BIE5A21029',
          name: 'Hub CrediCard',
          companyId: 1,
          projectId: 1,
          equipmentId: 1,
          timeZone: '-04:00',
          latitude: 10.492719650268556,
          longitude: -66.8675765991211,
          status: '10010',
          retries: 0,
          imgs: '["https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interCredicard1.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interCredicard2.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interCredicard3.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interCredicard4.jpeg"]',
          plantDetails: {
            device: {
              webserver: {
                sn: ['BIE5A21029'],
                key: 'zx6aiylp7b1uxti7esf85ry947689513'
              },
              krillboxInfo: {
                ders: {
                  device1: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  },
                  device2: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  }
                },
                port: 502,
                Simcard: '',
                baudrate1: 9600,
                baudrate2: 9600,
                sampleRate: 10,
                serialPort1: '/dev/ttyUSB1',
                serialPort2: '/dev/ttyUSB0'
              },
              measuredVariables: [
                {
                  oid: 'dcDcTemperature',
                  units: 'c',
                  source: 'sacolar',
                  varRef: '013',
                  varDesc: '',
                  varLabel: 'TBat',
                  multiplier: 1
                },
                {
                  oid: 'pBat',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '002',
                  varDesc: '',
                  varLabel: 'pBat',
                  multiplier: 0.001
                },
                {
                  oid: 'vBat',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '004',
                  varDesc: '',
                  varLabel: 'vBat',
                  multiplier: 1
                },
                {
                  oid: 'vGrid',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '005',
                  varDesc: '',
                  varLabel: 'vGrid',
                  multiplier: 1
                },
                {
                  oid: 'outPutVolt',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '006',
                  varDesc: '',
                  varLabel: 'vLoad',
                  multiplier: 1
                },
                {
                  oid: 'freqGrid',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '007',
                  varDesc: '',
                  varLabel: 'freqGrid',
                  multiplier: 1
                },
                {
                  oid: 'freqOutPut',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '008',
                  varDesc: '',
                  varLabel: 'freqLoad',
                  multiplier: 1
                },
                {
                  oid: 'outPutPower',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '009',
                  varDesc: '',
                  varLabel: 'pLoad',
                  multiplier: 0.001
                },
                {
                  oid: 'loadPercent',
                  units: 'N/A',
                  source: 'sacolar',
                  varRef: '010',
                  varDesc: '',
                  varLabel: 'loadPercent',
                  multiplier: 1
                },
                {
                  oid: 'pAcInPut',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '011',
                  varDesc: '',
                  varLabel: 'pGrid',
                  multiplier: 0.001
                }
              ]
            },
            system: {
              Elements: {
                Pv: {
                  pvInfo: '',
                  panelNumber: 0,
                  PvCapacityKwp: 0,
                  solarProfile_pv: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  dailyExpectedRadEnergy: 0
                },
                Bess: {
                  batInfo: 'Hresys VRLA \n 20 x 6-GFM(G)-150 \n 6 kWh',
                  ChrLimit: 0.1,
                  absCoeff: 0.86,
                  VBATrefGS: 0,
                  BatCapacityKwh: 36
                },
                Grid: {
                  gridInfo: 'CORPOELEC',
                  rKwhCost: 1,
                  lowervolt: 188,
                  uppervolt: 220
                },
                Load: {
                  pLoadkw: 2,
                  eOutProm: 24,
                  pMax_Load: 2,
                  loadProfile: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  xLoadPorcSacolar: 0.86
                },
                Common: {
                  rKwhCo2: 1,
                  maxCapkw: 8,
                  rCo2TreeEq: 0.1,
                  InverterQtty: 0,
                  ConverterQtty: 0,
                  SerialNumbers: ['BIE5A21029']
                },
                Genset: {
                  type: 'linear',
                  maxLiters: 0,
                  FuelParams: {
                    inMax: 0,
                    inMin: 0,
                    outMax: 0,
                    outMin: 0
                  },
                  rDieselCost: 0,
                  rUsdCaseBase: 0,
                  fuelSensorMax: 0,
                  fuelsensorMin: 0,
                  rFuelBaseCase: 0,
                  volumeFactors: {
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0
                  },
                  sLitrosOptxhora: 0,
                  sLitrosStdxhora: 0
                },
                Inverter: {
                  peakPowerkw: 6,
                  inverterInfo: 'SACOLAR \n M6000L \n 6kW'
                }
              },
              Premisas: {
                tonCo2: 2.3,
                automEst: 14,
                automRes: 14,
                aportEnerg: 14.6,
                ePvPromDay: 8.2,
                promDayLoad: 60,
                rPaybackInitial: 4,
                autonomyElectrogeno: 'N/A'
              },
              Variables: {
                Diario: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo diario',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del dia',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio diario de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo diario de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo diario de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto diario de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio diario de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo diario de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo diario de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedios diario de pBat por hora',
                      varLabel: 'pBatHourlyMeans'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatDisToday',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatChrToday',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMtbDis',
                      varLabel: 'sBatMtbDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMDis',
                      varLabel: 'sBatMDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio diario de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo diario de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del dia de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del dia de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo diario de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del dia de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor del dia de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del dia de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '015',
                      varDesc: 'Ultimo valor del dia de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Status: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Status de las notificaciones del sistema',
                      varLabel: 'sNotifications'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Status del modulo de baterias del sistema',
                      varLabel: 'sBat'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Status de la red',
                      varLabel: 'sGrid'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Status de la carga',
                      varLabel: 'sLoad'
                    }
                  ]
                },
                Mensual: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo mensual',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del mes',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas del mes',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Cantidad de ciclos de la bateria en el mes',
                      varLabel: 'sCyclesMonth'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Promedio mensual de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Maximo mensual de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo mensual de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo absoluto mensual de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '003',
                      varDesc: 'Promedio mensual de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Maximo mensual de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Minimo mensual de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma mensual de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma total de eBatDisToday',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      varRef: '003',
                      varDesc:
                        'Acumulada de energía utilizada por la bateria en el mes',
                      varLabel: 'eBatUsed'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria en el mes',
                      varLabel: 'batDisCyclesRatio'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria desde la puesta en servicio del sistema',
                      varLabel: 'batDisCyclesRatioTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMax',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc:
                        'Promedio absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMean',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadMean x 24',
                      varLabel: 'eLoadMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadAbsoluteMean x 24',
                      varLabel: 'eLoadAbsoluteMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Ultimo valor del mes de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: '(eLoadTotal - lastRecord.eLoadTotal) / 1000',
                      varLabel: 'eLoadUsed'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sGridFail - lastRecord.sGridFail',
                      varLabel: 'sGridFailMonth'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo mensual de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '007',
                      varDesc:
                        'Maximo absoluto mensual de sLongestFailHour (lastRecord)',
                      varLabel: 'sAbsoluteLongestFailHour',
                      ignoresDefault: true
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del mes de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime - lastRecord.sGridDownTime',
                      varLabel: 'sGridDownTimeMonth'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime / sGridFail',
                      varLabel: 'tMeanGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTimeMonth / sGridFailMonth',
                      varLabel: 'tMeanGridDownTimeMonth'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del mes de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '010',
                      varDesc: 'sLoadDownTime - lastRecord.sLoadDownTime',
                      varLabel: 'sLoadDownTimeMonth'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTime - sLoadDownTime',
                      varLabel: 'sDownTime'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTimeMonth - sLoadDownTimeMonth',
                      varLabel: 'sDownTimeMonth'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor del mes de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '013',
                      varDesc: '(eGridTotal - lastRecord.eGridTotal) / 1000',
                      varLabel: 'eGridUsed'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del mes de sGridEvents',
                      varLabel: 'sGridEvents'
                    },
                    {
                      varRef: '014',
                      varDesc: 'sGridEvents - lastRecord.sGridEvents',
                      varLabel: 'sGridEventsMonth'
                    }
                  ]
                },
                Semanal: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo semanal',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras de la semana',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas de la semana',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio semanal de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo semanal de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo semanal de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto semanal de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio semanal de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo semanal de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo semanal de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Promedio semanal de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo semanal de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Minimo semanal de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'eBatDisSum / sBatDis',
                      varLabel: 'eBatDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor de la semana de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo valor de la semana de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMDis',
                      varLabel: 'sBatMDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMDisSum / sBatDis',
                      varLabel: 'sBatMDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMtbDis',
                      varLabel: 'sBatMtbDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMtbDisSum / sBatDis',
                      varLabel: 'sBatMtbDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sampleTime / sBatDis',
                      varLabel: 'sBatDisSampleTimeRatio'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio semanal de eBatChrToday',
                      varLabel: 'eBatChrMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo semanal de eBatChrToday',
                      varLabel: 'eBatChrMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Minimo semanal de eBatChrToday',
                      varLabel: 'eBatChrMin'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Suma semanal de eBatChrToday',
                      varLabel: 'eBatChrSum'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor de la semana de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor de la semana de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Ultimo valor de la semana de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Maximo semanal de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor de la semana de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor de la semana de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '016',
                      varDesc: 'Ultimo valor de la semana de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '017',
                      varDesc: 'Ultimo valor de la semana de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Indirectas: {
                  vars: [
                    {
                      units: 'kWh',
                      varRef: '069',
                      varDesc:
                        'Potencia de carga en funcion de la potencia de la red',
                      varLabel: 'pLoad'
                    },
                    {
                      units: 'kWh',
                      varRef: '076',
                      varDesc:
                        'Potencia de la red en funcion de la potencia de la carga',
                      varLabel: 'pGrid'
                    },
                    {
                      units: '',
                      varRef: '021',
                      varDesc: 'Estado de la red',
                      varLabel: 'sGridState'
                    },
                    {
                      units: '',
                      varRef: '048',
                      varDesc: 'Estado de la carga',
                      varLabel: 'sLoadState'
                    },
                    {
                      units: '',
                      varRef: '072',
                      varDesc: 'Estado de la bateria',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'kWh',
                      varRef: '007',
                      varDesc: 'Daily grid energy',
                      varLabel: 'eGridToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '008',
                      varDesc: 'Total grid energy',
                      varLabel: 'eGridTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '009',
                      varDesc: 'Daily load energy',
                      varLabel: 'eLoadToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '010',
                      varDesc: 'Total load energy',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '013',
                      varDesc: 'Daily bat discharged energy',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '014',
                      varDesc: 'Total bat discharged energy',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '015',
                      varDesc: 'Daily bat charged energy',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '016',
                      varDesc: 'Total bat charged energy',
                      varLabel: 'eBatChrTotal'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc:
                        'Cambio en la energia de la bateria desde la ultima lectura',
                      varLabel: 'eDelta'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc: 'Porcentaje de la carga en funcion de pBat',
                      varLabel: 'xSoC',
                      defaultValue: 85
                    },
                    {
                      units: '',
                      varRef: '027',
                      varDesc: 'Modo de operacion',
                      varLabel: 'sOpMode'
                    },
                    {
                      units: 'h',
                      varRef: '028',
                      varDesc: 'Time since grid is available',
                      varLabel: 'sGridAvailableTimestamp'
                    },
                    {
                      units: 'h',
                      varRef: '029',
                      varDesc: 'Time since load is available',
                      varLabel: 'sLoadAvailableTimestamp'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Flag de evento de carga',
                      varLabel: 'sLoadEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Amount of load events',
                      varLabel: 'sLoadEvents'
                    },
                    {
                      units: 'h',
                      varRef: '032',
                      varDesc: 'Tiempo de carga activa',
                      varLabel: 'sLoadUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '033',
                      varDesc: 'Tiempo de carga inactiva',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '034',
                      varDesc: 'Availability % of load',
                      varLabel: 'sLoadDisp'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Flag de evento de red',
                      varLabel: 'sGridEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Amount of grid events',
                      varLabel: 'sGridEvents'
                    },
                    {
                      units: 'h',
                      varRef: '036',
                      varDesc: 'Tiempo de red activa',
                      varLabel: 'sGridUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '037',
                      varDesc: 'Tiempo de red inactiva',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '038',
                      varDesc: 'Availability % of grid',
                      varLabel: 'sGridDisp'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Maximo tiempo consecutivo de falla de red',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Tiempo de falla actual de red',
                      varLabel: 'sActualFailTime'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Flag de falla de red',
                      varLabel: 'sGridFailFlag'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Contador de fallas de red',
                      varLabel: 'sGridFail'
                    },
                    {
                      units: 'h',
                      varRef: '045',
                      varDesc: 'Mean time before grid fail',
                      varLabel: 'sGridMtbf'
                    },
                    {
                      units: 'h',
                      varRef: '046',
                      varDesc: 'Mean time to repair grid fail',
                      varLabel: 'sGridMttr'
                    },
                    {
                      units: 'h',
                      varRef: '047',
                      varDesc: 'Hours of backup',
                      varLabel: 'sBackupHours'
                    },
                    {
                      units: '%',
                      varRef: '057',
                      varDesc: 'status de Descarga de Bateria (on/off)',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'none',
                      varRef: '057',
                      varDesc:
                        'Es 1 si ocurre una falla de red, y durante su prolongación. Es 0 cuando la red está presente',
                      varLabel: 'sBatDisFlag'
                    },
                    {
                      units: '',
                      varRef: '070',
                      varDesc:
                        'Ciclos de carga de la bateria dados xSoC y sBatDisFlag',
                      varLabel: 'sCycles'
                    },
                    {
                      units: 'kWh[]',
                      varRef: '056',
                      varDesc: 'Perfil de carga por hora',
                      varLabel: 'pLoadProfile',
                      ignoresDefault: true
                    },
                    {
                      units: 'h',
                      varRef: '074',
                      varDesc: 'Standard autonomy given pLoadProfile',
                      varLabel: 'tAutonomyStd'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la carga',
                      varLabel: 'iLoad'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la bateria',
                      varLabel: 'iBat'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la red',
                      varLabel: 'iGrid'
                    }
                  ],
                  UpdatesTimestamps: {
                    dailyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    weeklyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    firstEventUpdateTimestamp: '2021-01-01 00:00:00.000000'
                  }
                },
                Notificaciones: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería baja',
                      varLabel: 'vBatLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado baja',
                      varLabel: 'vBatVeryLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería alta',
                      varLabel: 'vBatHigh'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado alta',
                      varLabel: 'vBatVeryHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería alta',
                      varLabel: 'TBatHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería demasiado alta',
                      varLabel: 'TBatVeryHigh'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el primer rango',
                      varLabel: 'xSoCFirstRange'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el segundo rango',
                      varLabel: 'xSoCSecondRange'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Estado de carga de las baterías bajo',
                      varLabel: 'xSoCLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Autonomia proyectada minimamente baja',
                      varLabel: 'tAutonomyStdLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga',
                      varLabel: 'tAutonomyStdDis'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga extendida',
                      varLabel: 'tAutonomyStdDisExtended'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Carga del sistema no conectada',
                      varLabel: 'pLoadLow'
                    }
                  ],
                  parameters: {
                    vPv: {
                      lowThreshold: 60
                    },
                    TBat: {
                      highThreshold: 40,
                      veryHighThreshold: 45
                    },
                    vBat: {
                      lowThreshold: {
                        lower: 42,
                        upper: 44
                      },
                      highThreshold: 58.5,
                      veryLowThreshold: 42,
                      veryHighThreshold: {
                        lower: 57,
                        upper: 58.5
                      }
                    },
                    xSoC: {
                      lowThreshold: 5,
                      firstRangeThreshold: {
                        lower: 50,
                        upper: 75
                      },
                      secondRangeThreshold: {
                        lower: 25,
                        upper: 50
                      }
                    },
                    pLoad: {
                      lowThreshold: 150
                    },
                    tAutonomyStd: {
                      disThreshold: {
                        lower: 4,
                        upper: 6
                      },
                      lowThreshold: 2,
                      disExtendedThreshold: {
                        lower: 2,
                        upper: 4
                      }
                    }
                  }
                }
              },
              FrontLimits: {
                xMaxBB: 4,
                xMaxBC: 100,
                xMaxGB: 0,
                xMaxGC: 0,
                xMaxGG: 0,
                xMaxLB: 2,
                xMaxLC: 30,
                xMaxRB: 2,
                xMaxRC: 30,
                xMaxRG: 220,
                xMaxSB: 0,
                xMaxSC: 0,
                xMinGG: 0,
                xMinRG: 188,
                xMaxHC1: 14,
                limitBar: 5,
                xMaxAxe1BG: 100,
                xMaxAxe1GG: 0,
                xMaxAxe1LG: 3.5,
                xMaxAxe1RG: 240,
                xMaxAxe1SG: 0,
                xMaxAxe2BG: 4,
                xMinAxe1BG: 0,
                xMinAxe1GG: 0,
                xMinAxe1LG: -1,
                xMinAxe1RG: 160,
                xMinAxe1SG: 0,
                xMixAxe2BG: -4,
                xMaxAxelIDGA: 8,
                xMaxAxelIDGB: 120,
                xMaxAxexIRPA: 150000,
                xMinAxelIDGA: -2,
                xMinAxelIDGB: -2,
                xMinAxevIRPA: 0
              },
              SutentabilityData: {
                var: 'epvTotal',
                value: 0.2025
              }
            }
          },
          structure: {
            common: {
              ha1: {
                dir: 'records',
                var: 'sBatDisTime',
                unit: 'horas',
                label: 'OPERACIÓN RESPALDADA'
              },
              ha2: {
                dir: 'records',
                var: 'rUsdSaved',
                unit: 'N/A',
                label: 'AHORRO FINANCIERO'
              },
              ha3: {
                dir: 'records',
                var: 'sKgCO2',
                unit: 'N/A',
                label: 'REDUCCION EMISIONES'
              },
              hb1: {
                dir: 'records',
                var: 'sWeatherIc',
                unit: '',
                label: ''
              },
              hb2: {
                dir: 'records',
                var: 'temp',
                unit: '°C',
                label: ''
              },
              hb3: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'date',
                label: ''
              },
              hb4: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'hour',
                label: ''
              },
              hb5: {
                dir: 'status',
                var: 'conn',
                unit: '',
                label: ''
              },
              hb6: {
                dir: 'status',
                var: 'lastReport',
                unit: '',
                label: ''
              },
              hc1: {
                dir: 'records',
                var: 'tAutonomyStd',
                unit: 'horas',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxHC1'
                }
              },
              lg1: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg2: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg3: {
                dir: 'records',
                var: 'pInput',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg4: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg5: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              systemTabs: {
                load: false,
                solar: false,
                batteries: true,
                electricNet: true,
                generatorSet: false
              },
              indicatorRoute: false,
              inactiveIndicators: {
                availability: false,
                profitability: false,
                sustainability: false
              }
            },
            system: {
              ba: {
                dir: '',
                var: 'pBat',
                unit: '',
                label: 'Baterías'
              },
              bb: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: 'Potencia batería',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBB'
                }
              },
              bc: {
                dir: 'records',
                var: 'xSoC',
                unit: '%',
                label: 'Estado de carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBC'
                }
              },
              bd: {
                dir: 'records',
                var: 'vBat',
                unit: 'V',
                label: 'Tensión batería',
                subUnit: ''
              },
              be: {
                dir: 'records',
                var: 'iBat',
                unit: 'A',
                label: 'Corriente batería',
                subUnit: ''
              },
              bf: {
                dir: 'records',
                var: 'TBat',
                unit: '°C',
                label: 'Temperatura baterías',
                subUnit: ''
              },
              bg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: ['Estado de carga', 'Potencia (kW)'],
                limits: {
                  dir: 'plantDetails',
                  varMax: ['xMaxAxe1BG', 'xMaxAxe2BG'],
                  varMin: ['xMinAxe1BG', 'xMinAxe2BG']
                }
              },
              ga: {
                dir: 'records',
                var: 'engineState',
                unit: '',
                label: 'Grupo electrógeno'
              },
              gb: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: 'Potencia GE',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGB'
                }
              },
              gc: {
                dir: 'records',
                var: 'xFuelPercent',
                unit: '%',
                label: 'Nivel combustible',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGC'
                }
              },
              gd: {
                dir: 'records',
                var: 'vGenset',
                unit: 'V',
                label: 'Tensión GE',
                subUnit: ''
              },
              ge: {
                dir: 'records',
                var: 'sGenAvailable',
                unit: '',
                label: 'Último arranque',
                subUnit: ''
              },
              gf: {
                dir: 'records',
                var: 'xFuelLevel',
                unit: 'L',
                label: 'Combustible disponible',
                subUnit: ''
              },
              gg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinGG',
                  upper: 'xMaxGG',
                  varMax: 'xMaxAxe1GG',
                  varMin: 'xMinAxe1GG'
                }
              },
              la: {
                dir: '',
                var: 'pLoad',
                unit: '',
                label: 'Carga'
              },
              lb: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: 'Potencia carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLB'
                }
              },
              lc: {
                dir: 'records',
                var: 'eOutputToday',
                unit: 'kWh',
                label: 'Consumo del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLC'
                }
              },
              ld: {
                dir: 'records',
                var: 'vOutput',
                unit: 'V',
                label: 'Tensión carga',
                subUnit: ''
              },
              le: {
                dir: 'records',
                var: 'iOutput',
                unit: 'A',
                label: 'Corriente de carga',
                subUnit: ''
              },
              lf: {
                dir: 'records',
                var: 'fOutput',
                unit: 'H',
                label: 'Frecuencia',
                subUnit: 'z'
              },
              lg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1LG',
                  varMin: 'xMinAxe1LG'
                }
              },
              ra: {
                dir: '',
                var: 'gridState',
                unit: '',
                label: 'Red eléctrica'
              },
              rb: {
                dir: 'records',
                var: 'pInput',
                unit: 'kW',
                label: 'Potencia red',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRB'
                }
              },
              rc: {
                dir: 'records',
                var: 'eInputToday',
                unit: 'kWh',
                label: 'Consumo del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRC'
                }
              },
              rd: {
                dir: 'records',
                var: ['vGrid', 'vGridb', 'vGridc'],
                unit: 'V',
                label: 'Tensión de red',
                subUnit: ''
              },
              re: {
                dir: 'records',
                var: ['iGrid', 'iGridb', 'iGridc'],
                unit: 'A',
                label: 'Corriente de red',
                subUnit: ''
              },
              rf: {
                dir: 'records',
                var: 'fGrid',
                unit: 'H',
                label: 'Frecuencia',
                subUnit: 'z'
              },
              rg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinRG',
                  upper: 'xMaxRG',
                  varMax: 'xMaxAxe1RG',
                  varMin: 'xMinAxe1RG'
                }
              },
              sa: {
                dir: '',
                var: 'vPv',
                unit: '',
                label: 'Solar'
              },
              sb: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: 'Potencia PV',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSB'
                }
              },
              sc: {
                dir: 'records',
                var: 'ePVToday',
                unit: 'kWh',
                label: 'Aporte del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSC'
                }
              },
              sd: {
                dir: 'records',
                var: 'vPv',
                unit: 'V',
                label: 'Tensión PV',
                subUnit: ''
              },
              se: {
                dir: 'records',
                var: 'TWeather',
                unit: '°C',
                label: 'Temperatura ambiente',
                subUnit: ''
              },
              sf: {
                dir: 'records',
                var: 'sAprovPV',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              sg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1SG',
                  varMin: 'xMinAxe1SG'
                }
              }
            },
            summary: {
              cards: {
                fb1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fb2: {
                  dir: 'records',
                  var: 'pBat',
                  unit: '',
                  label: 'charging'
                },
                fb3: {
                  dir: 'records',
                  var: 'pBat',
                  unit: '',
                  label: 'discharging'
                },
                fb4: {
                  dir: 'records',
                  var: 'xSoC',
                  unit: '%',
                  label: 'Estado de carga',
                  subUnit: ''
                },
                fb5: {
                  dir: 'records',
                  var: 'vBat',
                  unit: 'V',
                  label: 'Tensión batería',
                  subUnit: 'DC'
                },
                fg1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fg2: {
                  dir: 'records',
                  var: 'engineState',
                  unit: '',
                  label: 'connected'
                },
                fg3: {
                  dir: 'records',
                  var: 'engineState',
                  unit: '',
                  label: 'disconnected'
                },
                fg4: {
                  dir: 'records',
                  var: 'xFuelPercent',
                  unit: '%',
                  label: 'Nivel combustible',
                  subUnit: ''
                },
                fg5: {
                  dir: 'records',
                  var: 'sGenAvailable',
                  unit: '',
                  label: 'Último arranque',
                  subUnit: ''
                },
                fl1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fl2: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: '',
                  label: 'connected'
                },
                fl3: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: '',
                  label: 'disconnected'
                },
                fl4: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: 'kW',
                  label: 'Potencia carga'
                },
                fl5: {
                  dir: 'records',
                  var: 'eOutputToday',
                  unit: 'kWh',
                  label: 'Consumo del día'
                },
                fr1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fr2: {
                  dir: 'records',
                  var: 'gridState',
                  unit: '',
                  label: 'connected'
                },
                fr3: {
                  dir: 'records',
                  var: 'gridState',
                  unit: '',
                  label: 'disconnected'
                },
                fr4: {
                  dir: 'records',
                  var: 'vGrid',
                  unit: 'V',
                  label: 'Tensión red',
                  subUnit: 'AC'
                },
                fr5: {
                  dir: 'records',
                  var: 'sGridAvailable',
                  unit: '',
                  label: 'Disponible desde'
                },
                fs1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fs2: {
                  dir: 'records',
                  var: 'vPv',
                  unit: '',
                  label: 'connected'
                },
                fs3: {
                  dir: 'records',
                  var: 'vPv',
                  unit: '',
                  label: 'disconnected'
                },
                fs4: {
                  dir: 'records',
                  var: 'sAprovPV',
                  unit: '%',
                  label: 'Aporte solar',
                  subUnit: ''
                },
                fs5: {
                  dir: 'records',
                  var: 'pPv',
                  unit: '%',
                  label: 'Desempeño solar',
                  subUnit: ''
                }
              },
              promises: {
                pa: {
                  dir: 'plantDetails',
                  var: 'promDayLoad',
                  unit: 'kWh',
                  label: 'Energía diaria carga'
                },
                pb: {
                  dir: 'plantDetails',
                  var: 'automEst',
                  unit: 'h',
                  label: 'Autonomía estándar'
                },
                pc: {
                  dir: 'plantDetails',
                  var: 'automRes',
                  unit: 'h',
                  label: 'Autonomía resiliente'
                },
                pd: {
                  dir: 'plantDetails',
                  var: 'autonomyElectrogeno',
                  unit: 'dias',
                  label: 'Autonomía de electrógeno'
                },
                pe: {
                  dir: 'plantDetails',
                  var: 'autonomyElectrogeno',
                  unit: 'dias',
                  label: 'Autonomía de electrógeno'
                },
                t1: {
                  label: 'Premisas'
                },
                t2: {
                  label: 'de operación'
                }
              }
            },
            indicator: {
              idda: {
                dir: 'plantDetails',
                var: 'promDayLoad',
                unit: 'kWh',
                label: 'Energía diaria carga',
                subUnit: ''
              },
              iddb: {
                dir: 'records',
                var: 'weekpromLoad',
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddc: {
                dir: ['plantDetails', 'records'],
                var: ['promDayLoad', 'weekpromLoad'],
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddd: {
                dir: 'plantDetails',
                var: 'automEst',
                unit: 'h',
                label: 'Autonomía estándar',
                subUnit: ''
              },
              idde: {
                dir: 'records',
                var: 'tAutonomyStd',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddf: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyStd', 'automEst'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddg: {
                dir: 'plantDetails',
                var: 'automRes',
                unit: 'h',
                label: 'Autonomía resiliente',
                subUnit: ''
              },
              iddh: {
                dir: 'records',
                var: 'tAutonomyRest',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddi: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyRest', 'automRes'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddj: {
                dir: 'plantDetails',
                var: 'autonomyElectrogeno',
                unit: 'días',
                label: 'Autonomía de electrógeno',
                subUnit: ''
              },
              iddk: {
                dir: 'records',
                var: 'tAutonomyGen',
                unit: 'días',
                label: '',
                subUnit: ''
              },
              iddl: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyGen', 'autonomyElectrogeno'],
                unit: 'días',
                label: '',
                subUnit: ''
              },
              idga: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                title: 'Energia semana anterior',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGB',
                  varMin: 'xMinAxelIDGB'
                }
              },
              idgb: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Energía acumulada (kWh)',
                title: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGA',
                  varMin: 'xMinAxelIDGA'
                }
              },
              idpa: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Próximo retanqueo',
                subUnit: ''
              },
              idpb: {
                dir: 'records',
                var: '',
                unit: 'kWh',
                label: 'Consumo mensual',
                subUnit: ''
              },
              idpc: {
                dir: 'records',
                var: '',
                unit: 'H',
                label: 'Promedio de cortes',
                subUnit: ''
              },
              idpd: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Promedio entre cortes',
                subUnit: ''
              },
              idpe: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 85, 25, 15, 10, 0, 0, 0
                      ],
                      label: 'Autonomía estándar',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 85, 65, 45, 15, 5, 0
                      ],
                      label: 'Autonomía resiliente',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(0,70,210,0.40)',
                      backgroundColor: 'rgba(0,205,255,0.5)',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB. SATISFACER DEMANDA', 'DURACIÓN FALLA (horas)'],
                title: 'Autonomía estocástica',
                subUnit: ''
              },
              idpf: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 90, 75, 50, 25, 15, 5
                      ],
                      type: 'line',
                      label: 'Autonomía Estándar',
                      yAxisID: 'linear',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(255,0,0,0.7)',
                      backgroundColor: 'rgba(255,0,0,1)',
                      pointBackgroundColor: 'rgba(255,0,0,0.7)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB.Vida Util BESS', 'Años'],
                title: 'Expectativa de vida estocástica',
                subUnit: ''
              },
              idva: {
                dir: 'records',
                var: 'sLoadDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvb: {
                dir: 'records',
                var: 'sGridDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvc: {
                dir: 'records',
                var: 'eOutputTotal',
                unit: 'load image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvd: {
                dir: 'records',
                var: 'sLoadEvents',
                unit: '#',
                label: 'Eventos',
                subUnit: ''
              },
              idve: {
                dir: 'records',
                var: 'sGridFail',
                unit: '#',
                label: 'Cortes',
                subUnit: ''
              },
              idvf: {
                dir: 'records',
                var: 'eInputTotal',
                unit: 'electricalnet image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvg: {
                dir: 'records',
                var: 'sLoadupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvh: {
                dir: 'records',
                var: 'sGridupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvi: {
                dir: 'records',
                var: 'eBatDisToday',
                unit: 'battery image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvj: {
                dir: 'records',
                var: 'sLoadDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvk: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvl: {
                dir: 'records',
                var: 'ePVTotal',
                unit: 'solar image',
                label: 'kWh Totales',
                subUnit: ''
              },
              irda: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Horas de uso Genset',
                subUnit: ''
              },
              irdb: {
                dir: 'records',
                var: 'sGensetupTime',
                unit: '',
                label: 'h',
                subUnit: ''
              },
              irdc: {
                dir: 'records',
                var: ['sGensetDownTime', 'sGensetupTime', 'sGensetDownTime'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdd: {
                dir: 'records',
                var: 'rFuelBaseCase',
                unit: 'l',
                label: 'Consumo combustible',
                subUnit: ''
              },
              irde: {
                dir: 'records',
                var: 'mFuelCount',
                unit: '',
                label: 'l',
                subUnit: ''
              },
              irdf: {
                dir: ['plantDetails', 'records', 'plantDetails'],
                var: ['rFuelBaseCase', 'mFuelCount', 'rFuelBaseCase'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdg: {
                dir: 'records',
                var: 'rUsdCaseBasegrid',
                unit: 'USD',
                label: 'Gastos operativos',
                subUnit: ''
              },
              irdh: {
                dir: 'records',
                var: 'rUsdSystem',
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdi: {
                dir: 'records',
                var: ['rUsdCaseBasegrid', 'rUsdSystem'],
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdj: {
                dir: 'plantDetails',
                var: 'rPaybackInitial',
                unit: 'yrs',
                label: 'Payback simple',
                subUnit: ''
              },
              irdk: {
                dir: 'records',
                var: 'rPaybackSystem',
                unit: '',
                label: 'yrs',
                subUnit: ''
              },
              irdl: {
                dir: 'records',
                var: 'rDifPayb',
                unit: '',
                label: '%',
                subUnit: ''
              },
              irpa: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'USD ($)',
                title: 'Proyección ahorros USD',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxexIRPA',
                  varMin: 'xMinAxevIRPA'
                }
              },
              irpb: {
                dir: 'records',
                var: 'N/A',
                unit: 'motor image',
                label: 'Días / próximo retanqueo',
                subUnit: ''
              },
              irpc: {
                dir: 'records',
                var: ' N/A',
                unit: 'motor image',
                label: 'Días / próximo mantenimiento',
                subUnit: ''
              },
              isda: {
                dir: 'plantDetails',
                var: 'ePvPromDay',
                unit: 'kWh/día',
                label: 'Energía Diaria PV',
                subUnit: ''
              },
              isdb: {
                dir: 'records',
                var: 'weekpromPv',
                unit: '',
                label: 'kWh/día',
                subUnit: ''
              },
              isdc: {
                dir: 'records',
                var: 'uSolarDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdd: {
                dir: 'records',
                var: 'ukgCO2BaseCase',
                unit: 'TON',
                label: 'Emisiones generadas',
                subUnit: ''
              },
              isde: {
                dir: 'records',
                var: 'ukgCO2System',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdf: {
                dir: 'records',
                var: 'sKgCO2',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdg: {
                dir: 'records',
                var: 'aportEnerg',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              isdh: {
                dir: 'records',
                var: 'uPVvsLoadSystem',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdi: {
                dir: 'records',
                var: 'uPVvsLoadDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              ispa: {
                dir: 'week table, indicator-sustainability-projection',
                var: '',
                unit: '°C',
                label: '',
                subUnit: ''
              },
              ispb: {
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        5.39, 5.75, 6.18, 5.83, 5.51, 5.48, 5.78, 5.89, 5.8,
                        5.38, 5.03, 4.97
                      ],
                      label: 'PV',
                      yAxisID: 'first-y-axis',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(26,46,127,0.7)',
                      backgroundColor: 'rgba(26,46,127,0.38)',
                      pointBackgroundColor: 'rgba(26,46,127,0.7)'
                    }
                  ],
                  labels: [
                    'ene.',
                    'feb.',
                    'mar.',
                    'abr.',
                    'may.',
                    'jun.',
                    'jul.',
                    'ago.',
                    'sept.',
                    'oct.',
                    'nov.',
                    'dic.'
                  ]
                },
                label: 'Potencia (W)',
                title: 'Radiación promedio mensual'
              }
            }
          },
          connected: true
        }
      },
      {
        id: 5,
        type: 'system',
        attributes: {
          code: 'MQG1A3200Z',
          name: 'Hub Los Olivos',
          companyId: 1,
          projectId: 1,
          equipmentId: 1,
          timeZone: '-04:00',
          latitude: 10.665488243103027,
          longitude: -71.6300048828125,
          status: '10010',
          retries: 0,
          imgs: '["https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interOlivos1.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interOlivos2.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interOlivos3.jpeg","https://krill-systems-energy.s3.amazonaws.com/systems/Inter-Maracaibo/interOlivos4.jpeg"]',
          plantDetails: {
            device: {
              webserver: {
                sn: ['MQG1A3200Z'],
                key: 'zx6aiylp7b1uxti7esf85ry947689513'
              },
              krillboxInfo: {
                ders: {
                  device1: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  },
                  device2: {
                    Ip: '0.0.0.0',
                    Name: '',
                    port: 502,
                    comType: 'tcp'
                  }
                },
                port: 502,
                Simcard: '',
                baudrate1: 9600,
                baudrate2: 9600,
                sampleRate: 10,
                serialPort1: '/dev/ttyUSB1',
                serialPort2: '/dev/ttyUSB0'
              },
              measuredVariables: [
                {
                  oid: 'vpv',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '012',
                  varDesc: '',
                  varLabel: 'vPv',
                  multiplier: 1
                },
                {
                  oid: 'dcDcTemperature',
                  units: 'c',
                  source: 'sacolar',
                  varRef: '013',
                  varDesc: '',
                  varLabel: 'TBat',
                  multiplier: 1
                },
                {
                  oid: 'ppv',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '001',
                  varDesc: '',
                  varLabel: 'pPv',
                  multiplier: 0.001
                },
                {
                  oid: 'pBat',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '002',
                  varDesc: '',
                  varLabel: 'pBat',
                  multiplier: 0.001
                },
                {
                  oid: 'vBat',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '004',
                  varDesc: '',
                  varLabel: 'vBat',
                  multiplier: 1
                },
                {
                  oid: 'vGrid',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '005',
                  varDesc: '',
                  varLabel: 'vGrid',
                  multiplier: 1
                },
                {
                  oid: 'outPutVolt',
                  units: 'v',
                  source: 'sacolar',
                  varRef: '006',
                  varDesc: '',
                  varLabel: 'vLoad',
                  multiplier: 1
                },
                {
                  oid: 'freqGrid',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '007',
                  varDesc: '',
                  varLabel: 'freqGrid',
                  multiplier: 1
                },
                {
                  oid: 'freqOutPut',
                  units: 'Hz',
                  source: 'sacolar',
                  varRef: '008',
                  varDesc: '',
                  varLabel: 'freqLoad',
                  multiplier: 1
                },
                {
                  oid: 'outPutPower',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '009',
                  varDesc: '',
                  varLabel: 'pLoad',
                  multiplier: 0.001
                },
                {
                  oid: 'loadPercent',
                  units: 'N/A',
                  source: 'sacolar',
                  varRef: '010',
                  varDesc: '',
                  varLabel: 'loadPercent',
                  multiplier: 1
                },
                {
                  oid: 'pAcInPut',
                  units: 'w',
                  source: 'sacolar',
                  varRef: '011',
                  varDesc: '',
                  varLabel: 'pGrid',
                  multiplier: 0.001
                }
              ]
            },
            system: {
              Elements: {
                Pv: {
                  pvInfo: 'Consort Science \n 21xCST330P6-24 \n 7kWp',
                  panelNumber: 8,
                  PvCapacityKwp: 1,
                  solarProfile_pv: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  dailyExpectedRadEnergy: 1
                },
                Bess: {
                  batInfo: 'Hresys VRLA \n 20 x 6-GFM(G)-150 \n 6 kWh',
                  ChrLimit: 0.1,
                  absCoeff: 0.85,
                  VBATrefGS: 0,
                  BatCapacityKwh: 17
                },
                Grid: {
                  gridInfo: 'CORPOELEC',
                  rKwhCost: 0,
                  lowervolt: 216,
                  uppervolt: 264
                },
                Load: {
                  pLoadkw: 1.6,
                  eOutProm: 24,
                  pMax_Load: 1.6,
                  loadProfile: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0
                  ],
                  xLoadPorcSacolar: 2.78
                },
                Common: {
                  rKwhCo2: 1,
                  maxCapkw: 8,
                  rCo2TreeEq: 0.1,
                  InverterQtty: 0,
                  ConverterQtty: 0,
                  SerialNumbers: ['MQG1A3200Z']
                },
                Genset: {
                  type: 'linear',
                  maxLiters: 0,
                  FuelParams: {
                    inMax: 0,
                    inMin: 0,
                    outMax: 0,
                    outMin: 0
                  },
                  rDieselCost: 0,
                  rUsdCaseBase: 0,
                  fuelSensorMax: 0,
                  fuelsensorMin: 0,
                  rFuelBaseCase: 0,
                  volumeFactors: {
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0
                  },
                  sLitrosOptxhora: 0,
                  sLitrosStdxhora: 0
                },
                Inverter: {
                  peakPowerkw: 12,
                  inverterInfo: 'SACOLAR 12kwh'
                }
              },
              Premisas: {
                tonCo2: 2.3,
                automEst: 7,
                automRes: 23,
                aportEnerg: 14.6,
                ePvPromDay: 8.2,
                promDayLoad: 48,
                rPaybackInitial: 4,
                autonomyElectrogeno: 'N/A'
              },
              Variables: {
                Diario: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo diario',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del dia',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio diario de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo diario de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo diario de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto diario de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio diario de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo diario de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo diario de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedios diario de pBat por hora',
                      varLabel: 'pBatHourlyMeans'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatDisToday',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de eBatChrToday',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMtbDis',
                      varLabel: 'sBatMtbDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del dia de sBatMDis',
                      varLabel: 'sBatMDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio diario de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo diario de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del dia de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del dia de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo diario de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del dia de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Maximo diario de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Ultimo valor del dia de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor del dia de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor del dia de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del dia de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '015',
                      varDesc: 'Ultimo valor del dia de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Status: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Status de las notificaciones del sistema',
                      varLabel: 'sNotifications'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Status del modulo de baterias del sistema',
                      varLabel: 'sBat'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Status de la red',
                      varLabel: 'sGrid'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Status de la carga',
                      varLabel: 'sLoad'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Status de la energia solar',
                      varLabel: 'sPv'
                    }
                  ]
                },
                Mensual: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo mensual',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras del mes',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas del mes',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Cantidad de ciclos de la bateria en el mes',
                      varLabel: 'sCyclesMonth'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Promedio mensual de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Maximo mensual de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo mensual de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Minimo absoluto mensual de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '003',
                      varDesc: 'Promedio mensual de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Maximo mensual de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Minimo mensual de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma mensual de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '003',
                      varDesc: 'Suma total de eBatDisToday',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      varRef: '003',
                      varDesc:
                        'Acumulada de energía utilizada por la bateria en el mes',
                      varLabel: 'eBatUsed'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria en el mes',
                      varLabel: 'batDisCyclesRatio'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Profundidad promedio por ciclo de descarga de la bateria desde la puesta en servicio del sistema',
                      varLabel: 'batDisCyclesRatioTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMax',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc:
                        'Promedio absoluto mensual de pLoad (lastRecord)',
                      varLabel: 'pLoadAbsoluteMean',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadMean x 24',
                      varLabel: 'eLoadMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'pLoadAbsoluteMean x 24',
                      varLabel: 'eLoadAbsoluteMean24h'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Ultimo valor del mes de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '006',
                      varDesc: '(eLoadTotal - lastRecord.eLoadTotal) / 1000',
                      varLabel: 'eLoadUsed'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor del mes de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sGridFail - lastRecord.sGridFail',
                      varLabel: 'sGridFailMonth'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo mensual de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '007',
                      varDesc:
                        'Maximo absoluto mensual de sLongestFailHour (lastRecord)',
                      varLabel: 'sAbsoluteLongestFailHour',
                      ignoresDefault: true
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor del mes de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime - lastRecord.sGridDownTime',
                      varLabel: 'sGridDownTimeMonth'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTime / sGridFail',
                      varLabel: 'tMeanGridDownTime'
                    },
                    {
                      varRef: '008',
                      varDesc: 'sGridDownTimeMonth / sGridFailMonth',
                      varLabel: 'tMeanGridDownTimeMonth'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo mensual de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Maximo absoluto mensual de pPv (lastRecord)',
                      varLabel: 'pPvAbsoluteMax',
                      ignoresDefault: true
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del mes de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '009',
                      varDesc:
                        '(ePvTotal - lastRecord.ePvTotal) / (dias del mes)',
                      varLabel: 'ePvDailyMean'
                    },
                    {
                      varRef: '009',
                      varDesc: 'ePvTotal / (dias desde la instalacion)',
                      varLabel: 'ePvAbsoluteDailyMean'
                    },
                    {
                      varRef: '009',
                      varDesc:
                        '(ePvTotal - lastRecord.ePvTotal) / eLoadMean24h',
                      varLabel: 'sPvLoad24hRatio'
                    },
                    {
                      varRef: '009',
                      varDesc: 'ePvTotal / eLoadAbsoluteMean24h',
                      varLabel: 'sPvAbsoluteLoad24hRatio'
                    },
                    {
                      varRef: '009',
                      varDesc: '(ePvTotal - lastRecord.ePvTotal) / 1000',
                      varLabel: 'ePvUsed'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor del mes de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '010',
                      varDesc: 'sLoadDownTime - lastRecord.sLoadDownTime',
                      varLabel: 'sLoadDownTimeMonth'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTime - sLoadDownTime',
                      varLabel: 'sDownTime'
                    },
                    {
                      varRef: '011',
                      varDesc: 'sGridDownTimeMonth - sLoadDownTimeMonth',
                      varLabel: 'sDownTimeMonth'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor del mes de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2 - lastRecord.sKgCo2',
                      varLabel: 'sKgCo2Month'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2 x rCo2TreeEq',
                      varLabel: 'sKgCo2TreeEq'
                    },
                    {
                      varRef: '012',
                      varDesc: 'sKgCo2Month x rCo2TreeEq',
                      varLabel: 'sKgCo2TreeEqMonth'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor del mes de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '013',
                      varDesc: '(eGridTotal - lastRecord.eGridTotal) / 1000',
                      varLabel: 'eGridUsed'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor del mes de sGridEvents',
                      varLabel: 'sGridEvents'
                    },
                    {
                      varRef: '014',
                      varDesc: 'sGridEvents - lastRecord.sGridEvents',
                      varLabel: 'sGridEventsMonth'
                    }
                  ]
                },
                Semanal: {
                  vars: [
                    {
                      varRef: '000',
                      varDesc: 'Total tiempo de muestreo semanal',
                      varLabel: 'sampleTime'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras de la semana',
                      varLabel: 'batchSize'
                    },
                    {
                      varRef: '000',
                      varDesc: 'Cantidad de muestras instantaneas de la semana',
                      varLabel: 'trueBatchSize'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Promedio semanal de xSoC',
                      varLabel: 'xSoCMean'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Maximo semanal de xSoC',
                      varLabel: 'xSoCMax'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo semanal de xSoC',
                      varLabel: 'xSoCMin'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Minimo absoluto semanal de xSoC (lastRecord)',
                      varLabel: 'xSoCAbsoluteMin',
                      ignoresDefault: true
                    },
                    {
                      varRef: '006',
                      varDesc: 'Promedio semanal de pBat',
                      varLabel: 'pBatMean'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Maximo semanal de pBat',
                      varLabel: 'pBatMax'
                    },
                    {
                      varRef: '006',
                      varDesc: 'Minimo semanal de pBat',
                      varLabel: 'pBatMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Promedio semanal de eBatDisToday',
                      varLabel: 'eBatDisMean'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo semanal de eBatDisToday',
                      varLabel: 'eBatDisMax'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Minimo semanal de eBatDisToday',
                      varLabel: 'eBatDisMin'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de eBatDisToday',
                      varLabel: 'eBatDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatDis',
                      varLabel: 'sBatDis'
                    },
                    {
                      varRef: '007',
                      varDesc: 'eBatDisSum / sBatDis',
                      varLabel: 'eBatDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Ultimo valor de la semana de sBatDisTime',
                      varLabel: 'sBatDisTime'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Maximo valor de la semana de sLongestDisHour',
                      varLabel: 'sLongestDisHour'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMDis',
                      varLabel: 'sBatMDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMDisSum / sBatDis',
                      varLabel: 'sBatMDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Suma semanal de sBatMtbDis',
                      varLabel: 'sBatMtbDisSum'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sBatMtbDisSum / sBatDis',
                      varLabel: 'sBatMtbDisRatio'
                    },
                    {
                      varRef: '007',
                      varDesc: 'sampleTime / sBatDis',
                      varLabel: 'sBatDisSampleTimeRatio'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Promedio semanal de eBatChrToday',
                      varLabel: 'eBatChrMean'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Maximo semanal de eBatChrToday',
                      varLabel: 'eBatChrMax'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Minimo semanal de eBatChrToday',
                      varLabel: 'eBatChrMin'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Suma semanal de eBatChrToday',
                      varLabel: 'eBatChrSum'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Ultimo valor de la semana de sBatChrTime',
                      varLabel: 'sBatChrTime'
                    },
                    {
                      varRef: '009',
                      varDesc: 'Ultimo valor del mes de sCycles',
                      varLabel: 'sCycles'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Promedio mensual de pLoad',
                      varLabel: 'pLoadMean'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Maximo mensual de pLoad',
                      varLabel: 'pLoadMax'
                    },
                    {
                      varRef: '010',
                      varDesc: 'Ultimo valor de la semana de eLoadTotal',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Ultimo valor de la semana de sGridFail',
                      varLabel: 'sGridFail'
                    },
                    {
                      varRef: '011',
                      varDesc: 'Maximo semanal de sLongestFailHour',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      varRef: '012',
                      varDesc: 'Ultimo valor de la semana de sGridDownTime',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Maximo semanal de pPv',
                      varLabel: 'pPvMax'
                    },
                    {
                      varRef: '013',
                      varDesc: 'Ultimo valor de la semana de ePvTotal',
                      varLabel: 'ePvTotal'
                    },
                    {
                      varRef: '014',
                      varDesc: 'Ultimo valor de la semana de sLoadDownTime',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      varRef: '015',
                      varDesc: 'Ultimo valor de la semana de sKgCo2',
                      varLabel: 'sKgCo2'
                    },
                    {
                      varRef: '016',
                      varDesc: 'Ultimo valor de la semana de eGridTotal',
                      varLabel: 'eGridTotal'
                    },
                    {
                      varRef: '017',
                      varDesc: 'Ultimo valor de la semana de sGridEvents',
                      varLabel: 'sGridEvents'
                    }
                  ]
                },
                Indirectas: {
                  vars: [
                    {
                      units: 'kWh',
                      varRef: '069',
                      varDesc:
                        'Potencia de carga en funcion de la potencia de la red',
                      varLabel: 'pLoad'
                    },
                    {
                      units: 'kWh',
                      varRef: '076',
                      varDesc:
                        'Potencia de la red en funcion de la potencia de la carga',
                      varLabel: 'pGrid'
                    },
                    {
                      units: '',
                      varRef: '021',
                      varDesc: 'Estado de la red',
                      varLabel: 'sGridState'
                    },
                    {
                      units: '',
                      varRef: '048',
                      varDesc: 'Estado de la carga',
                      varLabel: 'sLoadState'
                    },
                    {
                      units: '',
                      varRef: '071',
                      varDesc: 'Estado de la potencia del PV',
                      varLabel: 'sPvState'
                    },
                    {
                      units: '',
                      varRef: '072',
                      varDesc: 'Estado de la bateria',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'kWh',
                      varRef: '007',
                      varDesc: 'Daily grid energy',
                      varLabel: 'eGridToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '008',
                      varDesc: 'Total grid energy',
                      varLabel: 'eGridTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '009',
                      varDesc: 'Daily load energy',
                      varLabel: 'eLoadToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '010',
                      varDesc: 'Total load energy',
                      varLabel: 'eLoadTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '011',
                      varDesc: 'Daily pv energy',
                      varLabel: 'ePvToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '012',
                      varDesc: 'Total pv energy',
                      varLabel: 'ePvTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '013',
                      varDesc: 'Daily bat discharged energy',
                      varLabel: 'eBatDisToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '014',
                      varDesc: 'Total bat discharged energy',
                      varLabel: 'eBatDisTotal'
                    },
                    {
                      units: 'kWh',
                      varRef: '015',
                      varDesc: 'Daily bat charged energy',
                      varLabel: 'eBatChrToday'
                    },
                    {
                      units: 'kWh',
                      varRef: '016',
                      varDesc: 'Total bat charged energy',
                      varLabel: 'eBatChrTotal'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc:
                        'Cambio en la energia de la bateria desde la ultima lectura',
                      varLabel: 'eDelta'
                    },
                    {
                      units: '%',
                      varRef: '075',
                      varDesc: 'Porcentaje de la carga en funcion de pBat',
                      varLabel: 'xSoC',
                      defaultValue: 85
                    },
                    {
                      units: '',
                      varRef: '027',
                      varDesc: 'Modo de operacion',
                      varLabel: 'sOpMode'
                    },
                    {
                      units: 'h',
                      varRef: '028',
                      varDesc: 'Time since grid is available',
                      varLabel: 'sGridAvailableTimestamp'
                    },
                    {
                      units: 'h',
                      varRef: '029',
                      varDesc: 'Time since load is available',
                      varLabel: 'sLoadAvailableTimestamp'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Flag de evento de carga',
                      varLabel: 'sLoadEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '031',
                      varDesc: 'Amount of load events',
                      varLabel: 'sLoadEvents'
                    },
                    {
                      units: 'h',
                      varRef: '032',
                      varDesc: 'Tiempo de carga activa',
                      varLabel: 'sLoadUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '033',
                      varDesc: 'Tiempo de carga inactiva',
                      varLabel: 'sLoadDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '034',
                      varDesc: 'Availability % of load',
                      varLabel: 'sLoadDisp'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Flag de evento de red',
                      varLabel: 'sGridEventFlag'
                    },
                    {
                      units: 'N/A',
                      varRef: '035',
                      varDesc: 'Amount of grid events',
                      varLabel: 'sGridEvents'
                    },
                    {
                      units: 'h',
                      varRef: '036',
                      varDesc: 'Tiempo de red activa',
                      varLabel: 'sGridUpTime'
                    },
                    {
                      units: 'h',
                      varRef: '037',
                      varDesc: 'Tiempo de red inactiva',
                      varLabel: 'sGridDownTime'
                    },
                    {
                      units: 'N/A',
                      varRef: '038',
                      varDesc: 'Availability % of grid',
                      varLabel: 'sGridDisp'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Maximo tiempo consecutivo de falla de red',
                      varLabel: 'sLongestFailHour'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Tiempo de falla actual de red',
                      varLabel: 'sActualFailTime'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Flag de falla de red',
                      varLabel: 'sGridFailFlag'
                    },
                    {
                      units: '',
                      varRef: '039',
                      varDesc: 'Contador de fallas de red',
                      varLabel: 'sGridFail'
                    },
                    {
                      units: 'h',
                      varRef: '045',
                      varDesc: 'Mean time before grid fail',
                      varLabel: 'sGridMtbf'
                    },
                    {
                      units: 'h',
                      varRef: '046',
                      varDesc: 'Mean time to repair grid fail',
                      varLabel: 'sGridMttr'
                    },
                    {
                      units: 'h',
                      varRef: '047',
                      varDesc: 'Hours of backup',
                      varLabel: 'sBackupHours'
                    },
                    {
                      units: 'Kg',
                      varRef: '052',
                      varDesc: 'kgco2 saved',
                      varLabel: 'sKgCo2'
                    },
                    {
                      units: '%',
                      varRef: '057',
                      varDesc: 'status de Descarga de Bateria (on/off)',
                      varLabel: 'sBatState'
                    },
                    {
                      units: 'none',
                      varRef: '057',
                      varDesc:
                        'Es 1 si ocurre una falla de red, y durante su prolongación. Es 0 cuando la red está presente',
                      varLabel: 'sBatDisFlag'
                    },
                    {
                      units: '',
                      varRef: '070',
                      varDesc:
                        'Ciclos de carga de la bateria dados xSoC y sBatDisFlag',
                      varLabel: 'sCycles'
                    },
                    {
                      units: 'kWh[]',
                      varRef: '056',
                      varDesc: 'Perfil de carga por hora',
                      varLabel: 'pLoadProfile',
                      ignoresDefault: true
                    },
                    {
                      units: 'h',
                      varRef: '053',
                      varDesc: 'Resilient autonomy',
                      varLabel: 'tAutonomyRest'
                    },
                    {
                      units: 'kWh[]',
                      varRef: '053',
                      varDesc: 'Perfil del dia de PV por hora',
                      varLabel: 'pPvProfile',
                      ignoresDefault: true
                    },
                    {
                      units: 'h',
                      varRef: '074',
                      varDesc: 'Standard autonomy given pLoadProfile',
                      varLabel: 'tAutonomyStd'
                    },
                    {
                      units: 'USD',
                      varRef: '073',
                      varDesc: 'Ahorro en USD por kWh de PV',
                      varLabel: 'rKwhCost'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la carga',
                      varLabel: 'iLoad'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la bateria',
                      varLabel: 'iBat'
                    },
                    {
                      units: 'A',
                      varRef: '096',
                      varDesc: 'Corriente de la red',
                      varLabel: 'iGrid'
                    }
                  ],
                  UpdatesTimestamps: {
                    dailyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    weeklyAverageTimestamp: '2021-01-01 00:00:00.000000',
                    firstEventUpdateTimestamp: '2021-01-01 00:00:00.000000'
                  }
                },
                Notificaciones: {
                  vars: [
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería baja',
                      varLabel: 'vBatLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado baja',
                      varLabel: 'vBatVeryLow'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería alta',
                      varLabel: 'vBatHigh'
                    },
                    {
                      varRef: '001',
                      varDesc: 'Tensión de batería demasiado alta',
                      varLabel: 'vBatVeryHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería alta',
                      varLabel: 'TBatHigh'
                    },
                    {
                      varRef: '002',
                      varDesc: 'Temperatura de batería demasiado alta',
                      varLabel: 'TBatVeryHigh'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el primer rango',
                      varLabel: 'xSoCFirstRange'
                    },
                    {
                      varRef: '004',
                      varDesc:
                        'Estado de carga de la bateria en el segundo rango',
                      varLabel: 'xSoCSecondRange'
                    },
                    {
                      varRef: '004',
                      varDesc: 'Estado de carga de las baterías bajo',
                      varLabel: 'xSoCLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Autonomia proyectada minimamente baja',
                      varLabel: 'tAutonomyStdLow'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga',
                      varLabel: 'tAutonomyStdDis'
                    },
                    {
                      varRef: '005',
                      varDesc: 'Sistema en descarga extendida',
                      varLabel: 'tAutonomyStdDisExtended'
                    },
                    {
                      varRef: '007',
                      varDesc: 'Carga del sistema no conectada',
                      varLabel: 'pLoadLow'
                    },
                    {
                      varRef: '008',
                      varDesc: 'Arreglo solar desconectado',
                      varLabel: 'vPvLow'
                    }
                  ],
                  parameters: {
                    vPv: {
                      lowThreshold: 60
                    },
                    TBat: {
                      highThreshold: 40,
                      veryHighThreshold: 45
                    },
                    vBat: {
                      lowThreshold: {
                        lower: 42,
                        upper: 44
                      },
                      highThreshold: 58.5,
                      veryLowThreshold: 42,
                      veryHighThreshold: {
                        lower: 57,
                        upper: 58.5
                      }
                    },
                    xSoC: {
                      lowThreshold: 5,
                      firstRangeThreshold: {
                        lower: 50,
                        upper: 75
                      },
                      secondRangeThreshold: {
                        lower: 25,
                        upper: 50
                      }
                    },
                    pLoad: {
                      lowThreshold: 150
                    },
                    tAutonomyStd: {
                      disThreshold: {
                        lower: 4,
                        upper: 6
                      },
                      lowThreshold: 2,
                      disExtendedThreshold: {
                        lower: 2,
                        upper: 4
                      }
                    }
                  }
                }
              },
              FrontLimits: {
                xMaxBB: 4,
                xMaxBC: 100,
                xMaxGB: 0,
                xMaxGC: 0,
                xMaxGG: 0,
                xMaxLB: 1.2,
                xMaxLC: 30,
                xMaxRB: 1.2,
                xMaxRC: 30,
                xMaxRG: 264,
                xMaxSB: 7,
                xMaxSC: 7,
                xMinGG: 0,
                xMinRG: 216,
                xMaxHC1: 23,
                limitBar: 5,
                xMaxAxe1BG: 100,
                xMaxAxe1GG: 0,
                xMaxAxe1LG: 3.5,
                xMaxAxe1RG: 280,
                xMaxAxe1SG: 8,
                xMaxAxe2BG: 4,
                xMinAxe1BG: 0,
                xMinAxe1GG: 0,
                xMinAxe1LG: -1,
                xMinAxe1RG: 190,
                xMinAxe1SG: -2,
                xMixAxe2BG: -4,
                xMaxAxelIDGA: 8,
                xMaxAxelIDGB: 120,
                xMaxAxexIRPA: 150000,
                xMinAxelIDGA: -2,
                xMinAxelIDGB: -2,
                xMinAxevIRPA: 0
              },
              SutentabilityData: {
                var: 'epvTotal',
                value: 0.2025
              }
            }
          },
          structure: {
            common: {
              ha1: {
                dir: 'records',
                var: 'sBatDisTime',
                unit: 'horas',
                label: 'OPERACIÓN RESPALDADA'
              },
              ha2: {
                dir: 'records',
                var: 'rUsdSaved',
                unit: 'N/A',
                label: 'AHORRO FINANCIERO'
              },
              ha3: {
                dir: 'records',
                var: 'sKgCO2',
                unit: 'N/A',
                label: 'REDUCCION EMISIONES'
              },
              hb1: {
                dir: 'records',
                var: 'sWeatherIc',
                unit: '',
                label: ''
              },
              hb2: {
                dir: 'records',
                var: 'temp',
                unit: '°C',
                label: ''
              },
              hb3: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'date',
                label: ''
              },
              hb4: {
                dir: 'timeZone',
                var: 'timeZone',
                unit: 'hour',
                label: ''
              },
              hb5: {
                dir: 'status',
                var: 'conn',
                unit: '',
                label: ''
              },
              hb6: {
                dir: 'status',
                var: 'lastReport',
                unit: '',
                label: ''
              },
              hc1: {
                dir: 'records',
                var: 'tAutonomyStd',
                unit: 'horas',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxHC1'
                }
              },
              lg1: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg2: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg3: {
                dir: 'records',
                var: 'pInput',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg4: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              lg5: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'limitBar'
                }
              },
              systemTabs: {
                load: false,
                solar: false,
                batteries: true,
                electricNet: true,
                generatorSet: false
              },
              indicatorRoute: false,
              inactiveIndicators: {
                availability: false,
                profitability: false,
                sustainability: false
              }
            },
            system: {
              ba: {
                dir: '',
                var: 'pBat',
                unit: '',
                label: 'Baterías'
              },
              bb: {
                dir: 'records',
                var: 'pBat',
                unit: 'kW',
                label: 'Potencia batería',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBB'
                }
              },
              bc: {
                dir: 'records',
                var: 'xSoC',
                unit: '%',
                label: 'Estado de carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxBC'
                }
              },
              bd: {
                dir: 'records',
                var: 'vBat',
                unit: 'V',
                label: 'Tensión batería',
                subUnit: ''
              },
              be: {
                dir: 'records',
                var: 'iBat',
                unit: 'A',
                label: 'Corriente batería',
                subUnit: ''
              },
              bf: {
                dir: 'records',
                var: 'TBat',
                unit: '°C',
                label: 'Temperatura baterías',
                subUnit: ''
              },
              bg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: ['Estado de carga', 'Potencia (kW)'],
                limits: {
                  dir: 'plantDetails',
                  varMax: ['xMaxAxe1BG', 'xMaxAxe2BG'],
                  varMin: ['xMinAxe1BG', 'xMinAxe2BG']
                }
              },
              ga: {
                dir: 'records',
                var: 'engineState',
                unit: '',
                label: 'Grupo electrógeno'
              },
              gb: {
                dir: 'records',
                var: 'pGenset',
                unit: 'kW',
                label: 'Potencia GE',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGB'
                }
              },
              gc: {
                dir: 'records',
                var: 'xFuelPercent',
                unit: '%',
                label: 'Nivel combustible',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxGC'
                }
              },
              gd: {
                dir: 'records',
                var: 'vGenset',
                unit: 'V',
                label: 'Tensión GE',
                subUnit: ''
              },
              ge: {
                dir: 'records',
                var: 'sGenAvailable',
                unit: '',
                label: 'Último arranque',
                subUnit: ''
              },
              gf: {
                dir: 'records',
                var: 'xFuelLevel',
                unit: 'L',
                label: 'Combustible disponible',
                subUnit: ''
              },
              gg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinGG',
                  upper: 'xMaxGG',
                  varMax: 'xMaxAxe1GG',
                  varMin: 'xMinAxe1GG'
                }
              },
              la: {
                dir: '',
                var: 'pLoad',
                unit: '',
                label: 'Carga'
              },
              lb: {
                dir: 'records',
                var: 'pLoad',
                unit: 'kW',
                label: 'Potencia carga',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLB'
                }
              },
              lc: {
                dir: 'records',
                var: 'eOutputToday',
                unit: 'kWh',
                label: 'Consumo del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxLC'
                }
              },
              ld: {
                dir: 'records',
                var: 'vOutput',
                unit: 'V',
                label: 'Tensión carga',
                subUnit: ''
              },
              le: {
                dir: 'records',
                var: 'iOutput',
                unit: 'A',
                label: 'Corriente de carga',
                subUnit: ''
              },
              lf: {
                dir: 'records',
                var: 'fOutput',
                unit: 'H',
                label: 'Frecuencia',
                subUnit: 'z'
              },
              lg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1LG',
                  varMin: 'xMinAxe1LG'
                }
              },
              ra: {
                dir: '',
                var: 'gridState',
                unit: '',
                label: 'Red eléctrica'
              },
              rb: {
                dir: 'records',
                var: 'pInput',
                unit: 'kW',
                label: 'Potencia red',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRB'
                }
              },
              rc: {
                dir: 'records',
                var: 'eInputToday',
                unit: 'kWh',
                label: 'Consumo del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxRC'
                }
              },
              rd: {
                dir: 'records',
                var: ['vGrid', 'vGridb', 'vGridc'],
                unit: 'V',
                label: 'Tensión de red',
                subUnit: ''
              },
              re: {
                dir: 'records',
                var: ['iGrid', 'iGridb', 'iGridc'],
                unit: 'A',
                label: 'Corriente de red',
                subUnit: ''
              },
              rf: {
                dir: 'records',
                var: 'fGrid',
                unit: 'H',
                label: 'Frecuencia',
                subUnit: 'z'
              },
              rg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Tensión (V)',
                limits: {
                  dir: 'plantDetails',
                  lower: 'xMinRG',
                  upper: 'xMaxRG',
                  varMax: 'xMaxAxe1RG',
                  varMin: 'xMinAxe1RG'
                }
              },
              sa: {
                dir: '',
                var: 'vPv',
                unit: '',
                label: 'Solar'
              },
              sb: {
                dir: 'records',
                var: 'pPv',
                unit: 'kW',
                label: 'Potencia PV',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSB'
                }
              },
              sc: {
                dir: 'records',
                var: 'ePVToday',
                unit: 'kWh',
                label: 'Aporte del día',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxSC'
                }
              },
              sd: {
                dir: 'records',
                var: 'vPv',
                unit: 'V',
                label: 'Tensión PV',
                subUnit: ''
              },
              se: {
                dir: 'records',
                var: 'TWeather',
                unit: '°C',
                label: 'Temperatura ambiente',
                subUnit: ''
              },
              sf: {
                dir: 'records',
                var: 'sAprovPV',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              sg: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxe1SG',
                  varMin: 'xMinAxe1SG'
                }
              }
            },
            summary: {
              cards: {
                fb1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fb2: {
                  dir: 'records',
                  var: 'pBat',
                  unit: '',
                  label: 'charging'
                },
                fb3: {
                  dir: 'records',
                  var: 'pBat',
                  unit: '',
                  label: 'discharging'
                },
                fb4: {
                  dir: 'records',
                  var: 'xSoC',
                  unit: '%',
                  label: 'Estado de carga',
                  subUnit: ''
                },
                fb5: {
                  dir: 'records',
                  var: 'vBat',
                  unit: 'V',
                  label: 'Tensión batería',
                  subUnit: 'DC'
                },
                fg1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fg2: {
                  dir: 'records',
                  var: 'engineState',
                  unit: '',
                  label: 'connected'
                },
                fg3: {
                  dir: 'records',
                  var: 'engineState',
                  unit: '',
                  label: 'disconnected'
                },
                fg4: {
                  dir: 'records',
                  var: 'xFuelPercent',
                  unit: '%',
                  label: 'Nivel combustible',
                  subUnit: ''
                },
                fg5: {
                  dir: 'records',
                  var: 'sGenAvailable',
                  unit: '',
                  label: 'Último arranque',
                  subUnit: ''
                },
                fl1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fl2: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: '',
                  label: 'connected'
                },
                fl3: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: '',
                  label: 'disconnected'
                },
                fl4: {
                  dir: 'records',
                  var: 'pLoad',
                  unit: 'kW',
                  label: 'Potencia carga'
                },
                fl5: {
                  dir: 'records',
                  var: 'eOutputToday',
                  unit: 'kWh',
                  label: 'Consumo del día'
                },
                fr1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fr2: {
                  dir: 'records',
                  var: 'gridState',
                  unit: '',
                  label: 'connected'
                },
                fr3: {
                  dir: 'records',
                  var: 'gridState',
                  unit: '',
                  label: 'disconnected'
                },
                fr4: {
                  dir: 'records',
                  var: 'vGrid',
                  unit: 'V',
                  label: 'Tensión red',
                  subUnit: 'AC'
                },
                fr5: {
                  dir: 'records',
                  var: 'sGridAvailable',
                  unit: '',
                  label: 'Disponible desde'
                },
                fs1: {
                  dir: '',
                  var: '',
                  unit: '',
                  label: ''
                },
                fs2: {
                  dir: 'records',
                  var: 'vPv',
                  unit: '',
                  label: 'connected'
                },
                fs3: {
                  dir: 'records',
                  var: 'vPv',
                  unit: '',
                  label: 'disconnected'
                },
                fs4: {
                  dir: 'records',
                  var: 'sAprovPV',
                  unit: '%',
                  label: 'Aporte solar',
                  subUnit: ''
                },
                fs5: {
                  dir: 'records',
                  var: 'pPv',
                  unit: '%',
                  label: 'Desempeño solar',
                  subUnit: ''
                }
              },
              promises: {
                pa: {
                  dir: 'plantDetails',
                  var: 'promDayLoad',
                  unit: 'kWh',
                  label: 'Energía diaria carga'
                },
                pb: {
                  dir: 'plantDetails',
                  var: 'automEst',
                  unit: 'h',
                  label: 'Autonomía estándar'
                },
                pc: {
                  dir: 'plantDetails',
                  var: 'automRes',
                  unit: 'h',
                  label: 'Autonomía resiliente'
                },
                pd: {
                  dir: 'plantDetails',
                  var: 'autonomyElectrogeno',
                  unit: 'dias',
                  label: 'Autonomía de electrógeno'
                },
                pe: {
                  dir: 'plantDetails',
                  var: 'autonomyElectrogeno',
                  unit: 'dias',
                  label: 'Autonomía de electrógeno'
                },
                t1: {
                  label: 'Premisas'
                },
                t2: {
                  label: 'de operación'
                }
              }
            },
            indicator: {
              idda: {
                dir: 'plantDetails',
                var: 'promDayLoad',
                unit: 'kWh',
                label: 'Energía diaria carga',
                subUnit: ''
              },
              iddb: {
                dir: 'records',
                var: 'weekpromLoad',
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddc: {
                dir: ['plantDetails', 'records'],
                var: ['promDayLoad', 'weekpromLoad'],
                unit: 'kWh',
                label: '',
                subUnit: ''
              },
              iddd: {
                dir: 'plantDetails',
                var: 'automEst',
                unit: 'h',
                label: 'Autonomía estándar',
                subUnit: ''
              },
              idde: {
                dir: 'records',
                var: 'tAutonomyStd',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddf: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyStd', 'automEst'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddg: {
                dir: 'plantDetails',
                var: 'automRes',
                unit: 'h',
                label: 'Autonomía resiliente',
                subUnit: ''
              },
              iddh: {
                dir: 'records',
                var: 'tAutonomyRest',
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddi: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyRest', 'automRes'],
                unit: 'h',
                label: '',
                subUnit: ''
              },
              iddj: {
                dir: 'plantDetails',
                var: 'autonomyElectrogeno',
                unit: 'días',
                label: 'Autonomía de electrógeno',
                subUnit: ''
              },
              iddk: {
                dir: 'records',
                var: 'tAutonomyGen',
                unit: 'días',
                label: '',
                subUnit: ''
              },
              iddl: {
                dir: ['records', 'plantDetails'],
                var: ['tAutonomyGen', 'autonomyElectrogeno'],
                unit: 'días',
                label: '',
                subUnit: ''
              },
              idga: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Potencia (kW)',
                title: 'Energia semana anterior',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGB',
                  varMin: 'xMinAxelIDGB'
                }
              },
              idgb: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'Energía acumulada (kWh)',
                title: '',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxelIDGA',
                  varMin: 'xMinAxelIDGA'
                }
              },
              idpa: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Próximo retanqueo',
                subUnit: ''
              },
              idpb: {
                dir: 'records',
                var: '',
                unit: 'kWh',
                label: 'Consumo mensual',
                subUnit: ''
              },
              idpc: {
                dir: 'records',
                var: '',
                unit: 'H',
                label: 'Promedio de cortes',
                subUnit: ''
              },
              idpd: {
                dir: 'records',
                var: '',
                unit: 'd',
                label: 'Promedio entre cortes',
                subUnit: ''
              },
              idpe: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 85, 25, 15, 10, 0, 0, 0
                      ],
                      label: 'Autonomía Estándar',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    },
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 85, 65, 45, 15, 5, 0
                      ],
                      label: 'Autonomía Resiliente',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(0,70,210,0.40)',
                      backgroundColor: 'rgba(0,205,255,0.5)',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB. SATISFACER DEMANDA', 'DURACIÓN FALLA (horas)'],
                title: 'Autonomía estocástica',
                subUnit: ''
              },
              idpf: {
                dir: '',
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        100, 100, 100, 100, 100, 100, 100, 90, 75, 50, 25, 15, 5
                      ],
                      type: 'line',
                      label: 'Autonomía estándar',
                      yAxisID: 'linear',
                      borderColor: 'rgba(0,205,255,0.5)',
                      lineTension: 0.5,
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,0,0,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(87,35,100,1)',
                      backgroundColor: 'rgba(0,70,210,0.40)',
                      pointBackgroundColor: 'rgba(0,70,210,0.40)'
                    },
                    {
                      borderColor: 'rgba(255,0,0,0.7)',
                      backgroundColor: 'rgba(255,0,0,1)',
                      pointBackgroundColor: 'rgba(255,0,0,0.7)'
                    }
                  ],
                  labels: [
                    '0',
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                    '12'
                  ]
                },
                label: ['PROB.Vida Util BESS', 'Años'],
                title: 'Expectativa de vida estocástica',
                subUnit: ''
              },
              idva: {
                dir: 'records',
                var: 'sLoadDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvb: {
                dir: 'records',
                var: 'sGridDisp',
                unit: '%',
                label: 'Disponibilidad',
                subUnit: ''
              },
              idvc: {
                dir: 'records',
                var: 'eOutputTotal',
                unit: 'load image',
                label: 'kWh Totales',
                subUnit: ''
              },
              idvd: {
                dir: 'records',
                var: 'sLoadEvents',
                unit: '#',
                label: 'Eventos',
                subUnit: ''
              },
              idve: {
                dir: 'records',
                var: 'sGridFail',
                unit: '#',
                label: 'Cortes',
                subUnit: ''
              },
              idvf: {
                dir: 'records',
                var: 'eInputTotal',
                unit: 'electricalnet image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvg: {
                dir: 'records',
                var: 'sLoadupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvh: {
                dir: 'records',
                var: 'sGridupTime',
                unit: 'h',
                label: 'Uptime',
                subUnit: ''
              },
              idvi: {
                dir: 'records',
                var: 'eBatDisToday',
                unit: 'battery image',
                label: 'kWh totales',
                subUnit: ''
              },
              idvj: {
                dir: 'records',
                var: 'sLoadDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvk: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Downtime',
                subUnit: ''
              },
              idvl: {
                dir: 'records',
                var: 'ePVTotal',
                unit: 'solar image',
                label: 'kWh totales',
                subUnit: ''
              },
              irda: {
                dir: 'records',
                var: 'sGridDownTime',
                unit: 'h',
                label: 'Horas de uso Genset',
                subUnit: ''
              },
              irdb: {
                dir: 'records',
                var: 'sGensetupTime',
                unit: '',
                label: 'h',
                subUnit: ''
              },
              irdc: {
                dir: 'records',
                var: ['sGensetDownTime', 'sGensetupTime', 'sGensetDownTime'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdd: {
                dir: 'records',
                var: 'rFuelBaseCase',
                unit: 'l',
                label: 'Consumo combustible',
                subUnit: ''
              },
              irde: {
                dir: 'records',
                var: 'mFuelCount',
                unit: '',
                label: 'l',
                subUnit: ''
              },
              irdf: {
                dir: ['plantDetails', 'records', 'plantDetails'],
                var: ['rFuelBaseCase', 'mFuelCount', 'rFuelBaseCase'],
                unit: '',
                label: '%',
                subUnit: ''
              },
              irdg: {
                dir: 'records',
                var: 'rUsdCaseBasegrid',
                unit: 'USD',
                label: 'Gastos operativos',
                subUnit: ''
              },
              irdh: {
                dir: 'records',
                var: 'rUsdSystem',
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdi: {
                dir: 'records',
                var: ['rUsdCaseBasegrid', 'rUsdSystem'],
                unit: '',
                label: 'USD',
                subUnit: ''
              },
              irdj: {
                dir: 'plantDetails',
                var: 'rPaybackInitial',
                unit: 'yrs',
                label: 'Payback simple',
                subUnit: ''
              },
              irdk: {
                dir: 'records',
                var: 'rPaybackSystem',
                unit: '',
                label: 'yrs',
                subUnit: ''
              },
              irdl: {
                dir: 'records',
                var: 'rDifPayb',
                unit: '',
                label: '%',
                subUnit: ''
              },
              irpa: {
                dir: 'endpoint',
                var: '',
                unit: '',
                label: 'USD ($)',
                title: 'Proyección ahorros USD',
                limits: {
                  dir: 'plantDetails',
                  varMax: 'xMaxAxexIRPA',
                  varMin: 'xMinAxevIRPA'
                }
              },
              irpb: {
                dir: 'records',
                var: 'N/A',
                unit: 'motor image',
                label: 'Días / próximo retanqueo',
                subUnit: ''
              },
              irpc: {
                dir: 'records',
                var: ' N/A',
                unit: 'motor image',
                label: 'Días / próximo mantenimiento',
                subUnit: ''
              },
              isda: {
                dir: 'plantDetails',
                var: 'ePvPromDay',
                unit: 'kWh/día',
                label: 'Energía diaria PV',
                subUnit: ''
              },
              isdb: {
                dir: 'records',
                var: 'weekpromPv',
                unit: '',
                label: 'kWh/día',
                subUnit: ''
              },
              isdc: {
                dir: 'records',
                var: 'uSolarDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdd: {
                dir: 'records',
                var: 'ukgCO2BaseCase',
                unit: 'TON',
                label: 'Emisiones generadas',
                subUnit: ''
              },
              isde: {
                dir: 'records',
                var: 'ukgCO2System',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdf: {
                dir: 'records',
                var: 'sKgCO2',
                unit: '',
                label: 'TON',
                subUnit: ''
              },
              isdg: {
                dir: 'records',
                var: 'aportEnerg',
                unit: '%',
                label: 'Aporte solar',
                subUnit: ''
              },
              isdh: {
                dir: 'records',
                var: 'uPVvsLoadSystem',
                unit: '',
                label: '%',
                subUnit: ''
              },
              isdi: {
                dir: 'records',
                var: 'uPVvsLoadDif',
                unit: '',
                label: '%',
                subUnit: ''
              },
              ispa: {
                dir: 'week table, indicator-sustainability-projection',
                var: '',
                unit: '°C',
                label: '',
                subUnit: ''
              },
              ispb: {
                var: '',
                unit: '',
                chart: {
                  data: [
                    {
                      data: [
                        5.39, 5.75, 6.18, 5.83, 5.51, 5.48, 5.78, 5.89, 5.8,
                        5.38, 5.03, 4.97
                      ],
                      label: 'PV',
                      yAxisID: 'first-y-axis',
                      pointBackgroundColor: 'rgba(0,205,255,0.5)'
                    }
                  ],
                  colors: [
                    {
                      borderColor: 'rgba(26,46,127,0.7)',
                      backgroundColor: 'rgba(26,46,127,0.38)',
                      pointBackgroundColor: 'rgba(26,46,127,0.7)'
                    }
                  ],
                  labels: [
                    'ene.',
                    'feb.',
                    'mar.',
                    'abr.',
                    'may.',
                    'jun.',
                    'jul.',
                    'ago.',
                    'sept.',
                    'oct.',
                    'nov.',
                    'dic.'
                  ]
                },
                label: 'Potencia (W)',
                title: 'Radiación promedio mensual'
              }
            }
          },
          connected: true
        }
      }
    ];

    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
      this.sidenavOpened = false;
    } else {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getSystemName();
      }
    });

    this.sidenavService.changeSidenav(this.sidenavOpened);
    this.getTotalAlarmsAlerts();
  }

  /**
   * Gets system name depending on the page
   *
   */
  async getSystemName() {
    if (
      this.router.url.substring(0, 19) == '/dashboard/summary/' ||
      this.router.url.substring(0, 18) == '/dashboard/system/' ||
      this.router.url.substring(0, 22) == '/dashboard/indicators/'
    ) {
      const routerItems = this.router.url.split('/');
      this.systemId = routerItems.pop();
      this.systemType = routerItems.pop();
      this.systemName = await this.systemsService.getSystemNameById(
        this.systemId // This is the system id
      );

      this.equipmentId = localStorage.getItem('eq');
      this.loadingRoutes = false;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }



  languageChanged(selectedLanguage: string) {
    this.translate.setLanguage(selectedLanguage);
  }

  async logOut() {
    localStorage.clear();
    window.location.href = environment.loginUrl;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
      this.sidenavOpened = false;
    } else {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
    }
  }

  public sidenavChanged(opened) {
    this.sidenavService.changeSidenav(opened);
  }

  public async getTotalAlarmsAlerts() {
    // //Define an array
    // let systemListByUser = [];
    // //get all systems id by user
    // this.systemList.forEach((element) => {
    //   systemListByUser.push(element.id);
    // });
    let type: any = [2, 3];
    // const res = await this.notificationsService.getNotifications(
    //   1,
    //   localStorage.getItem('USER_ID'),
    //   type,
    //   [],
    //   'true',
    //   undefined,
    //   undefined
    // );
    this.totalAlertsAlarms = 0;
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemsService {
  constructor(private router: Router) {}

  async getSystemListPaginated(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    currentPage: string,
    status: string,
    name: string
    // isEnergy = true
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/Pagination' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId +
          '&currentPage=' +
          currentPage +
          `${status !== '' ? '&status=' + status : []}` +
          `${name !== '' ? '&name=' + name : []}` +
          '&isEnergy=false',
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async getMapSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    status: string,
    name: string
    // isEnergy = true
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/resume' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId +
          `${status !== '' ? '&status=' + status : []}` +
          `${name !== '' ? '&name=' + name : []}` +
          '&isEnergy=false',
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  async getCSVSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/csv' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId +
          '&isEnergy=false',
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * DEPRECATED
   * @param companyId
   */
  public async getSystemList(companyId: string, isEnergy: boolean = false) {
    try {
      const response = await axios.get(
        `${environment.apiUrl}systems/summary/list/${companyId}?isEnergy=${isEnergy}`,
        {
          headers: {}
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystemById(id: string) {
    try {
      const response = await axios.get(environment.apiUrl + 'systems/' + id, {
        headers: {}
      });
      const system = response.data.data;
      const weather = await axios.get(
        environment.apiUrl + 'systems/' + id + '/weather',
        {
          headers: {}
        }
      );
      system.system.records[0].temp = weather.data.data.temp;
      system.system.records[0].icon = weather.data.data.icon;
      return system;
    } catch (err) {
      return err;
    }
  }

  /**
   * Returns system's name by the given id
   * @param id
   */
  public async getSystemNameById(id: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'systems/details/sidebar/' + id,
        {
          headers: {}
        }
      );
      return response.data.data.attributes.name;
    } catch (err) {
      return err;
    }
  }

  public async getSystemIndicators(id: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'systems/indicator/' + id,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystemMonthly(id: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/' + id + '/monthly',
        {
          headers: {}
        }
      );
      if (response.data.monthly.length === 0) {
        return {};
      }
      return response.data.monthly[0];
    } catch (err) {
      return err;
    }
  }

  public async getSystemDaily(id: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/' + id + '/daily',
        {
          headers: {}
        }
      );
      if (response.data.daily.length === 0) {
        return {};
      }
      return response.data.daily[0];
    } catch (err) {
      return err;
    }
  }

  public async getSystemProjections(id: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'systems/weather/projection/' + id,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getDynamicChart(
    id: number,
    identifier: string,
    startDate: string,
    endDate: string,
    index: number
  ) {
    try {
      const response = await axios.get(
        environment.apiUrl +
          `systems/dynamic/charts/${id}?identifier=${identifier}&startDate=${startDate}&endDate=${endDate}&index=${index}`,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsChart(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    startDate: string,
    endDate: string,
    timeZone: string
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/charts',
        {
          systemsId: systemId,
          equipmentId,
          tabIndex,
          startDate,
          endDate,
          timeZone
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response.data.chartsData);
      return response.data.chartsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsBars(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    intervals: string
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/bars',
        {
          systemsId: systemId,
          equipmentId,
          tabIndex,
          intervals
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data.barsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsBarsEnergy(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    intervals: string
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/bars/energy',
        {
          systemsId: systemId,
          equipmentId,
          tabIndex,
          intervals
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data.barsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsHistoryEvents(
    systemId: number,
    equipmentId: number,
    startDate: string,
    endDate: string,
    timeZone: string,
    fullData: boolean
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'records/history/events',
        {
          systemsId: systemId,
          equipmentId,
          startDate,
          endDate,
          timeZone,
          fullData
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Returns the system's route depending on the equipment id and the summaryPage
   * If summaryPage
   * @param equipmentId The Equipment id of the system
   * @param sysmteId The system id
   * @param summaryPage It could be hardcoded, it defines if the route should be the system's summaryPage
   * @param fromIndicators If we are in indicator's Page, the route is the system's page
   */
  public getSystemRoute(
    equipmentId: any,
    systemId: any,
    summaryPage: boolean = false,
    // fromHome: boolean = true,
    fromIndicators: boolean = false
    // systemType: any
  ) {
    equipmentId = equipmentId.toString();
    systemId = systemId.toString();

    if (fromIndicators || !false) {
      if (equipmentId === 0) {
        return;
      }
      if (summaryPage) {
        return '/dashboard/summary/t1/' + systemId;
      } else {
        return '/dashboard/system/t1/' + systemId;
      }
    }
  }

  /**
   * Returns the system's status code as a word
   *
   * @param code Code of the system status
   */
  public getSystemStatus(code: string) {
    switch (code) {
      case '00000':
        return 'disconnected';

      case '10000':
        return 'connected';

      case '11000':
        return 'connected';

      case '10100':
        return 'connected';

      case '10010':
        return 'connected';

      case '10001':
        return 'error';

      default:
        return 'error';
    }
  }

  /**
   * Returns the first img url of the system. If there is none returns null
   * @param system System Objt
   */
  public getSystemFirstImg(system: any) {
    if (system.imgs == null) {
      return null;
    }
    return JSON.parse(system.imgs)[0];
  }

  /**
   * Returns the system's status code as a color
   *
   * @param code Code of the system status
   */
  public getSystemStatusColor(code: string) {
    switch (code) {
      case '00000':
        return 'black';

      case '10000':
        return 'green';

      case '11000':
        return 'blue';

      case '10100':
        return 'yellow';

      case '10010':
        return 'orange';

      case '10001':
        return 'red';

      default:
        return 'red';
    }
  }

  /**
   * Returns the system's status code as a color v2
   *
   * @param code Code of the system status
   */
  public getSystemStatusColor2(code: string) {
    switch (code) {
      case '00000':
        return 'black';

      case '10000':
        return 'green';

      case '11000':
        return 'blue';

      case '10100':
        return 'orange';

      case '10010':
        return 'orange';

      case '10001':
        return 'red';

      default:
        return 'red';
    }
  }

  public getSystemSummaryData(systemData: any, stucture: any) {
    let divisor = !stucture.divisor ? 1 : stucture.divisor;
    if (stucture.dir === 'records') {
      return !systemData.records[0][stucture.var]
        ? null
        : systemData.records[0][stucture.var]/ divisor;
    } else if (stucture.dir === 'monthly') {
      return systemData.monthly[stucture.var] / divisor;
    } else if (stucture.dir === 'daily') {
      return systemData.daily[stucture.var] / divisor;
    } else if (stucture.dir === 'plantDetails') {
      return systemData.attributes.plantDetails.system.Premisas[stucture.var] / divisor;
    }
  }

  public getSystemNotification(records, alarmName = null) {
    if ('status' in records[0]) {
      const status = records[0].status.sNotifications;
      switch (status) {
        case 'alarm':
          if (alarmName == null) return 'Alarma';
          return alarmName;

        case 'warning':
          if (alarmName == null) return 'Alerta';
          return alarmName;

        case 'info':
          if (alarmName == null) return 'Notificación';
          return alarmName;

        case 'clear':
          return 'Sin notificaciones';

        default:
          return 'Ok';
      }
    } else {
      return 'Ok';
    }
  }

  public async isEquipmentRight() {
    const routerItems = this.router.url.split('/');
    const systemId = routerItems.pop();
    const systemType = routerItems.pop();
    const system = await this.getSystemById(systemId);

    if (this.checkEquipmentId(systemType, system.equipmentId)) {
      return;
    } else {
      console.log('caigo aqui');
      this.router.navigate(['/dashboard/home']);
    }
  }

  public checkEquipmentId(systemType, equipmentId) {
    switch (systemType) {
      case 'sacolar':
      case 'sacolar-ns':
        const listSacolar = [1];
        return listSacolar.includes(equipmentId);

      case 'studer':
        const listStuder = [2];
        return listStuder.includes(equipmentId);

      case 'studer-inv':
        const listStuderInv = [3];
        return listStuderInv.includes(equipmentId);

      case 'krill-box':
        const listKrillBox = [4];
        return listKrillBox.includes(equipmentId);

      case 'ups':
        const listUps = [5];
        return listUps.includes(equipmentId);

      case 'krill-box-ups':
        const listKrillBoxUps = [6];
        return listKrillBoxUps.includes(equipmentId);

      case 'jas':
        const listJas = [7];
        return listJas.includes(equipmentId);

      case 'porvenir':
        const listPorvenir = [8];
        return listPorvenir.includes(equipmentId);

      case 'tigo':
        const listTigo = [9];
        return listTigo.includes(equipmentId);

      case 'eq10kb':
        const listEq10kb = [10];
        return listEq10kb.includes(equipmentId);

      case 'eq11kb':
        const listEq11kb = [11];
        return listEq11kb.includes(equipmentId);

      case 'eq12kb':
        const listEq12kb = [12];
        return listEq12kb.includes(equipmentId);

      case 'eq13kb':
        const listEq13kb = [13];
        return listEq13kb.includes(equipmentId);

      case 'eq14kb':
        const listEq14kb = [14];
        // let listEq14kb = [8];
        return listEq14kb.includes(equipmentId);

      default:
        break;
    }
  }

  /**
   * returns all filter data by company id ( projects | companies | regions | type )
   * @param companyId user company id
   */
  public async getFilterData(companyId: string) {
    try {
      let response = await axios.get(
        `${environment.apiUrl}users/filter/company/${companyId}?isEnergy=false`,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
}

import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { TranslateConfigService } from '../../../services/translate-config/translate-config.service';
import { SystemsService } from '../../../services/systems/systems.service';
import { ProjectsService } from '../../../services/projects/projects.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { Router } from '@angular/router';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { ChartDataSets, ChartOptions, Chart } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import ChartDatalabels from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('linearChart') public canvas: ElementRef;

  public chartPlugins = [ChartDatalabels];

  loading = true;
  summaryPage = true;

  mapLat: number;
  mapLng: number;
  mapZoom: number;

  sitesValues: any;
  chartData: any;
  projectDaily: any;
  systemList: any[] = [];
  resumen: any;

  projectId: any;
  interval:any;
  isRefresh:any;

  // home chart
  homeBarChartData: ChartDataSets[] = [];
  homeBarChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      display: false,
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 11,
            beginAtZero: true,
            fontSize: 9
          },
          gridLines: {
            drawOnChartArea: false
          },
          stacked: true
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          position: 'left',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Litros (L)'
          }
          // stacked: true
        }
      ]
    },
    plugins: {
      datalabels: {
        // display: true,
        // anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        },
        color: '#333333',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        borderRadius: 5
      }
    }
  };
  homeBarChartColors: Color[] = [];

  // COMMON
  homeChartLabels: Label[] = [];
  homeChartLegend = true;
  homeChartType = 'bar';

  spinnerRemasterizedDiameter = 140;
  // Image to krill spinner v2
  indicatorImageValue = 1;

  // gradients
  blueGradient: CanvasGradient;
  orangeGradient: CanvasGradient;

  constructor(
    private translate: TranslateConfigService,
    private router: Router,
    public systemsService: SystemsService,
    public projectsService: ProjectsService,
    public indicatorsService: IndicatorsService,
    public configsService: ConfigsService
  ) {}

  async ngAfterViewInit() {
    const canv = document.createElement('canvas');
    canv.id = 'linearChart2';
    document.body.appendChild(canv); // adds the canvas to the body element
    const f = <HTMLCanvasElement>document.getElementById('linearChart2');

    // create orange gradient
    this.orangeGradient = f.getContext('2d').createLinearGradient(0, 0, 0, 400);
    this.orangeGradient.addColorStop(0, '#F6D16F');
    this.orangeGradient.addColorStop(1, '#F2987B');
    // create blue gradient
    this.blueGradient = f.getContext('2d').createLinearGradient(0, 0, 0, 400);
    this.blueGradient.addColorStop(0, '#6F9DF6');
    this.blueGradient.addColorStop(1, '#4D6BA6');
    document.body.removeChild(canv);
  }

  async ngOnInit() {
    const projectId = localStorage.getItem('PROJECT_ID');
    const companyId = localStorage.getItem('COMPANY_ID');
    
    if (companyId != '11' && companyId!='0'){
      this.router.navigate(['/dashboard/systems']);
    }


    let data = await this.projectsService.getSystemsResumen(projectId);
    this.resumen = data;
    this.projectDaily = await this.projectsService.getLastProjectDaily(
      projectId
    );
    this.projectDaily = this.projectDaily[0];
    this.projectDaily.hfLFuelTotal = Math.trunc(this.projectDaily.hfLFuelTotal);
    this.projectDaily.hfPctFuelTotal *=100;
    this.projectDaily.hfPctFuelTotal =this.projectDaily.hfPctFuelTotal.toFixed(2);

    this.systemList = data.systems;
    this.sitesValues = data.sites;
    this.chartData = data.chart;

    this.mapLat = 7.074141;
    this.mapLng = -65.646442;
    this.mapZoom = 5;

    this.getHomeBarChartData(0, '1', '2');
    this.loadColors();
    this.loading = false;

    this.spinnerResizes();

    // Inicia la actualización cada 10 minutos (600,000 milisegundos)
    this.isRefresh = localStorage.getItem('refresh');
    if(this.isRefresh == "yes"){
      this.interval = setInterval(() => {
        this.refresh();
        console.log('refresh');
      },240000);
    }
  }

  // Detiene la actualización al salir del componente
  ngOnDestroy() {
    if(this.isRefresh == "yes"){
      clearInterval(this.interval);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
  }

  async getHomeBarChartData(
    loadTab: number,
    startDate: string,
    endDate: string
  ) {
    // set chart type
    this.homeChartType = 'bar';

    let maxChartData = this.chartData.expectedValue;
    let secondChartData = this.chartData.actualValue;
    console.log(secondChartData);

    this.homeBarChartData = [
      {
        data: maxChartData,
        label: 'Capacidad',
        yAxisID: 'first-y-axis',
        type: 'bar',
        datalabels: {
          labels: {
            // we have to set null to not show datalabels
            title: null
          }
        },
        order: 2,
        backgroundColor:
          'linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))',
        pointBackgroundColor: '#001F9D',
        hoverBackgroundColor: '#001F9D',
        pointHoverBorderColor: '#001F9D'
      },
      {
        data: secondChartData,
        label: 'Litros',
        yAxisID: 'first-y-axis',
        type: 'bar',
        datalabels: {
          labels: {
            // we have to set null to not show datalabels
            title: null
          }
        },
        order: 1,
        barThickness: 12,
        backgroundColor: '#7c7c7c',
        pointBackgroundColor: '#7c7c7c',
        hoverBackgroundColor: '#7c7c7c',
        pointHoverBorderColor: '#7c7c7c'
      }
    ];

    this.homeChartLabels = this.chartData.labels;
  }

  async refresh(){
    const projectId = localStorage.getItem('PROJECT_ID');

    let auxdata = await this.projectsService.getSystemsResumen(projectId);
    this.resumen = auxdata;
    this.projectDaily = await this.projectsService.getLastProjectDaily(
      projectId
    );
    this.projectDaily = this.projectDaily[0];
    this.projectDaily.hfLFuelTotal = Math.trunc(this.projectDaily.hfLFuelTotal);

    this.systemList = auxdata.systems;
    this.sitesValues = auxdata.sites;
    this.chartData = auxdata.chart;
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
      this.homeBarChartData[1].barThickness = 12;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) {
      this.spinnerRemasterizedDiameter = 110;
      this.homeBarChartData[1].barThickness = 6;
    }
    // if (window.innerWidth < 1100) this.spinnerRemasterizedDiameter = 115;
    // if (window.innerWidth < 1000) this.spinnerRemasterizedDiameter = 115;
    // if (window.innerWidth < 800) this.spinnerRemasterizedDiameter = 115;
    if (window.innerWidth < 580) this.homeBarChartData[1].barThickness = 6;
  }

  loadColors() {
    // set gradient color
    this.homeBarChartData[1].backgroundColor = this.orangeGradient;
    this.homeBarChartData[1].borderColor = '#D9D9D9';
    this.homeBarChartData[1].pointBackgroundColor = '#D9D9D9';
    this.homeBarChartData[1].hoverBackgroundColor = this.orangeGradient;

    this.homeBarChartData[0].backgroundColor = this.blueGradient;
    this.homeBarChartData[0].borderColor = '#001F9D';
    this.homeBarChartData[0].pointBackgroundColor = '#001F9D';
    this.homeBarChartData[0].hoverBackgroundColor = this.blueGradient;
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
@Component({
  selector: 'krill-spinner-v2',
  templateUrl: './krill-spinner-v2.component.html',
  styleUrls: ['./krill-spinner-v2.component.scss']
})
export class KrillSpinnerV2Component implements OnInit {
  // Represent 90% of spinner
  @Input() maxSpinnerValue: number;
  @Input() imgValue: string;

  // value to show in the spinner
  @Input() textValue: number;
  // magnitude to show in the spinner
  @Input() magnitude: string;
  // title to show in the spinner
  @Input() descriptionTitle: string;
  // description to show in the spinner
  // spinner diameter
  @Input() spinnerDiameter: number;
  // type ( horizontal - vertical )
  @Input() format: string = 'horizontal';

  @Input() subtitle: string = 'Máxima Referencia';

  @Input() description: boolean = true;
  @Input() descriptionValue: number = 123456789;

  constructor(private translate: TranslateConfigService) {}

  ngOnInit() {
    //this.textValue = Math.round(this.textValue);
    if(!this.textValue){
      this.textValue=0;
    }
    if (this.descriptionValue == 123456789) {
      this.descriptionValue = this.maxSpinnerValue;
    }
  }
}

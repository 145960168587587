import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as moment from 'moment-timezone';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

import { SystemsService } from 'src/app/services/systems/systems.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'indicators-tabs',
  templateUrl: './indicators-tabs.component.html',
  styleUrls: ['./indicators-tabs.component.scss'],
})
export class IndicatorsTabsComponent implements OnInit {

  @Input() systemData: any;
  @Input() systemIndicatorsData: any;
  @Input() indicatorsDeviation: any;
  @Input() indicatorsProjections: any;
  @Input() indicators: any;

  // @Input() indicatorToggle: any;
  // @Input() subIndicatorToggle: any;

  // indicators
  systemId: string;

  sidenavOpened: boolean;
  pageLoading = true;
  barsLoading = false;
  tabLoading = false;
  tabLoading2 = false;
  tabLoading3 = false;
  histLoading = false;

  actualTab = 'availability';
  actualSubTabDeviations = 'deviations';
  indicatorToggle = 'availability';
  subIndicatorToggle = 'deviations';

  toggleTimeValue = '';

  availabilityHistoricsTable: any;
  availabilityHistoricalValues: any;

  promEnergiaDiariaCarga = 0;

  sustHistTable: any;

  // dates
  defaultDate = '';
  startDate = '';
  endDate = '';


  lineChartDataHist: ChartDataSets[] = [];
  lineChartLabelsHist: Label[] = [];
  lineChartOptionsLoad: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 7
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (kW)'
          }
        }
      ]
    }
  };

  lineChartDataSust: any;
  lineChartLabelsSust: any;
  lineChartOptionsSust = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (W)'
          }
        }
      ]
    }
  };

  lineChartLegend = true;
  lineChartType = 'line';

  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(0,205,255,0.5)',
      backgroundColor: 'rgba(0,205,255,0.28)',
      pointBackgroundColor: 'rgba(0,205,255,0.5)'
    },
    {
      borderColor: 'rgba(0,89,255,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,89,255,0.7)'
    },
    {
      borderColor: 'rgba(26,46,127,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(26,46,127,0.7)'
    },
    {
      borderColor: 'rgba(62,36,130,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(62,36,130,0.7)'
    }
  ];

  batChartColorSust: Color[] = [];

  // ========= Autonomia Estocastica ===========
  lineChartData1: ChartDataSets[] = [];

  lineChartLabels1: Label[] = [];

  lineChartColors1: Color[] = [];

  lineChartOptionsLoad1 = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            // userCallback: function (tick) {
            //   return tick.toString() + ' h';
            // },
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'DURACIÓN FALLA (horas)'
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            userCallback(tick) {
              return tick.toString() + '%';
            },
            beginAtZero: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'PROB. SATISFACER DEMANDA'
          }
        }
      ]
    }
  };

  // ========= Expectativa de Vida Estocastica ===========
  lineChartData2: ChartDataSets[] = [];

  lineChartLabels2: Label[] = [];

  lineChartColors2: Color[] = [];

  lineChartOptionsLoad2 = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Años'
          }
        }
      ],
      yAxes: [
        {
          id: 'linear',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            userCallback(tick) {
              return tick.toString() + '%';
            },
            beginAtZero: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'PROB. Vida Util BESS'
          }
        },
        {
          id: 'bar',
          type: 'linear',
          display: false,
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            display: false,
            autoSkip: true,
            maxTicksLimit: 6,
            min: 0,
            max: 1
          },
          scaleLabel: {
            display: false
          }
        }
      ]
    }
  };

  // ========= Disponibilidad - Historical (Left)===========
  lineChartDataLeft: ChartDataSets[] = [];

  lineChartLabelsLeft: Label[] = [];

  lineChartOptionsLeft: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (kW)'
          }
        }
      ]
    }
  };

  lineChartColorsLeft: Color[] = [
    {
      // load
      borderColor: '#f4b434 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#f4b434 '
    },
    {
      // battery
      borderColor: ' #fc8c64 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #fc8c64 '
    },
    {
      // solar
      borderColor: '#7caccc ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#7caccc '
    },
    {
      // electrical net
      borderColor: ' #8484a4',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #8484a4'
    },
    {
      // motor
      borderColor: '#b788bf ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#b788bf '
    }
  ];

  // ========= Disponibilidad - Historical (Right) ===========
  barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Energía acumulada (kWh)'
          }
        }
      ]
    }
  };
  barChartDataRight: ChartDataSets[] = [];
  barChartLabelsRight: any;
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartColors: Color[] = [
    {
      borderColor: 'rgba(55,55,55,0.28)',
      backgroundColor: 'rgba(0,205,255,0.5)'
    },
    {
      borderColor: 'rgba(55,55,55,0.28)',
      backgroundColor: 'rgba(0,89,255,0.7)'
    },
    {
      borderColor: 'rgba(55,55,55,0.28)',
      backgroundColor: 'rgba(26,46,127,0.7)'
    },
    {
      borderColor: 'rgba(55,55,55,0.28)',
      backgroundColor: 'rgba(62,36,130,0.7)'
    },
    {
      borderColor: 'rgba(55,55,55,0.28)',
      backgroundColor: 'rgba(153,0,76,0.7)'
    }
  ];

  // ======= Profitability - Projections =======
  profitabilityProjChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 4
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'linear',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'USD ($)'
          }
        },
        {
          id: 'bar',
          type: 'linear',
          display: false,
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            display: false,
            autoSkip: true,
            maxTicksLimit: 6,
            min: 0,
            max: 1
          },
          scaleLabel: {
            display: false
          }
        }
      ]
    }
  };
  profitabilityProjChartData: ChartDataSets[] = [];
  profitabilityProjChartLabels: Label[] = [];
  profitabilityProjChartColors: Color[] = [
    {
      borderColor: 'rgba(0,205,255,0.5)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,205,255,0.5)'
    },
    {
      borderColor: 'rgba(0,89,255,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,89,255,0.7)'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      pointBackgroundColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'rgba(255,0,0,1)'
    }
  ];

  constructor(
    public sidenavService: SidenavService,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    private translate: TranslateConfigService,
    private config: NgbTooltipConfig,
    public sweetAlertService: SweetAlertService,
    public mathTools: MathToolsService,
    private utilService: UtilsService,
  ) {
    config.disableTooltip = true;
    config.container = 'body';
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    // Set spinner Diameter and charts Ticks
    this.maxLimitChartsChange();

    this.systemDataSetup();

    await this.actualIndicator();

    await this.getAllMonitoringChartsData();


    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemData.id
    );
    this.pageLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.maxLimitChartsChange();
  }

  async systemDataSetup() {
    if (this.indicators[0].off === 1){
      if (this.indicators[1].off === 1){
        if (this.indicators[2].off === 1){
          this.actualTab = '';
          this.indicatorToggle = '';
          this.tabLoading3 = true;
        }else{
          this.actualTab = 'sustainability';
          this.indicatorToggle = 'sustainability';
        }
      }else{
        this.actualTab = 'profitability';
        this.indicatorToggle = 'profitability';
      }
    }else{
      this.actualTab = 'availability';
      this.indicatorToggle = 'availability';
    }

    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.timeZone)
      .format('yyyy-MM-DD');

    this.systemId = this.systemData.id;

    // ==== Chart Availability - projections - Autonomia Estocastica ====
    // data
    this.lineChartData1 = this.systemData.structure.indicator.idpe.chart.data;
    // labels
    this.lineChartLabels1 = this.systemData.structure.indicator.idpe.chart.labels;
    // colors
    this.lineChartColors1 = this.systemData.structure.indicator.idpe.chart.colors;

    // ==== Chart Availability - projections - Expectativa de Vida Estocastica ====
    // data
    this.lineChartData2 = this.systemData.structure.indicator.idpf.chart.data;
    // labels
    this.lineChartLabels2 = this.systemData.structure.indicator.idpf.chart.labels;
    // colors
    this.lineChartColors2 = this.systemData.structure.indicator.idpf.chart.colors;
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  async tabChanged(tabChangeEvent: MatButtonToggleChange): Promise<void> {
    this.tabLoading = true;
    this.tabLoading3 = true;

    this.actualTab = tabChangeEvent.value;

    const yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    // subIndicatorToggle will always be in deviations
    this.subIndicatorToggle = 'deviations';

    // console.log("actualTab: ", this.actualTab);

    switch (this.actualTab) {
      case 'availability':
        localStorage.setItem('indicator', this.actualTab);
        await this.getAvailabilityHistBars('week');
        await this.getAvailabilityHistoricalValues(true);
        await this.getAvailabilityHistChartData(
          1,
          yesterdayDate,
          this.defaultDate
        );
        break;

      case 'profitability':
        localStorage.setItem('indicator', this.actualTab);
        await this.getProjectionsData();
        break;

      case 'sustainability':
        localStorage.setItem('indicator', this.actualTab);
        await this.getSustData(this.defaultDate, this.defaultDate);
        await this.getSustHistBars('week');
        break;
    }
    this.tabLoading = false;
    this.tabLoading3 = false;
  }

  /**
   * Get all projections data
   */
  async getProjectionsData() {
    //  PROFITABILITY
    const lineChartData = await this.indicatorsService.getProjectionsProfChart();

    console.log('lineChartData: ', lineChartData);

    // min and max
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.plantDetails.frontLimits.xMinAxevIRPA;
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.plantDetails.frontLimits.xMaxAxexIRPA;

    this.profitabilityProjChartLabels = lineChartData.lineChartLables.map(
      (date) => {
        return moment(date)
          .utcOffset(this.systemData.timeZone)
          .format('MMM yyyy');
      }
    );
  }

  async getAvailabilityHistBars(interval: string) {
    this.barsLoading = true;

    const barsDataRight = await this.systemsService.getRecordsBarsEnergy(
      this.systemData.id,
      this.systemData.equipmentId,
      0,
      interval
    );

    let sumData = 0;
    for (const x in barsDataRight.lineChartData[2].data) {
      sumData += parseInt(barsDataRight.lineChartData[2].data[x]);
    }
    this.promEnergiaDiariaCarga =
      sumData / barsDataRight.lineChartData[2].data.length;

    this.barChartDataRight = barsDataRight.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.bars.' + data.label
      );
      return data;
    });

    this.barChartLabelsRight = barsDataRight.lineChartLables.map((date) => {
      return moment(date).utcOffset(this.systemData.timeZone).format('DD MMM');
    });

    this.barsLoading = false;
  }

  async getAvailabilityHistoricalValues(isDefault: boolean = false) {
    if (isDefault) {
      this.availabilityHistoricalValues =
        await this.systemsService.getRecordsHistoryEvents(
          this.systemData.id,
          this.systemData.equipmentId,
          this.defaultDate,
          this.defaultDate,
          this.systemData.timeZone,
          true
        );
    } else {
      if (this.startDate === '' && this.endDate !== '') {
        // Si la fecha final es mayor que la actual, error
        if (
          moment(this.endDate)
            .utcOffset(this.systemData.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha final no puede ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.equipmentId,
            this.endDate,
            this.endDate,
            this.systemData.timeZone,
            true
          );
      } else if (this.startDate !== '' && this.endDate === '') {
        // Si la fecha inicial es mayor que la actual, error
        if (
          moment(this.startDate)
            .utcOffset(this.systemData.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha inicial no puede ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.equipmentId,
            this.startDate,
            this.startDate,
            this.systemData.timeZone,
            true
          );
      } else if (this.startDate !== '' && this.endDate !== '') {
        // Si la fecha inicial es mayor que la final, error
        if (this.startDate > this.endDate) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha final tiene que ser mayor a la fecha inicial',
            'warning',
            'OK'
          );
          return;
        }
        // Si ambas fechas son mayores a la fecha actual, error
        if (
          moment(this.startDate)
            .utcOffset(this.systemData.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate &&
          moment(this.endDate)
            .utcOffset(this.systemData.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'Ambas fechas no pueden ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.equipmentId,
            this.startDate,
            this.endDate,
            this.systemData.timeZone,
            false
          );
      }
    }

    // console.log("availabilityHistoricalValues: ", this.availabilityHistoricalValues)
  }

  async getSustHistBars(interval: string) {
    this.barsLoading = true;

    const barsDataLeft = await this.systemsService.getRecordsBarsEnergy(
      this.systemData.id,
      this.systemData.equipmentId,
      3,
      interval
    );
  }

  async getSustData(startDate: string, endDate: string) {
    this.sustHistTable = await this.indicatorsService.sustHistorics(
      this.systemId,
      startDate,
      endDate
    );

    const solarChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.equipmentId,
      3,
      startDate,
      endDate,
      this.systemData.timeZone
    );

    this.lineChartDataHist = solarChartsData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    this.lineChartLabelsHist = solarChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.timeZone)
        .format('DD-MM hh:mm');
    });
  }

  /**
   * Get all configurations for all monitoring charts (First chart of each tab)
   */
  async getAllMonitoringChartsData() {
    // ==== AVAILABILITY ====
    const chartDataDisp = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.equipmentId,
      0,
      this.defaultDate,
      this.defaultDate,
      this.systemData.timeZone
    );

    // ==== AVAILABILITY - PROJECTIONS ====
    const dateToday = moment().toISOString();
    const dateSystem = this.systemData.createdAt;
    const diffYears = moment(dateToday).diff(moment(dateSystem), 'years');
    this.lineChartData2.push({
      data: [
        {
          x: diffYears.toString(),
          y: 1
        }
      ],
      yAxisID: 'bar',
      label: 'Año actual',
      barThickness: 2,
      borderColor: 'rgba(255,0,0,1)',
      pointBackgroundColor: 'rgba(255,0,0,1)'
    });

    // ==== PROFITABILITY ====
    // Min and max limit
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.irpa.limits.varMin];
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.irpa.limits.varMax];

    // ==== SUSTAINABILITY ====
    const chartDataSust = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.equipmentId,
      3,
      this.defaultDate,
      this.defaultDate,
      this.systemData.timeZone
    );

    // data
    this.lineChartDataSust = this.systemData.structure.indicator.ispb.chart.data;
    // label
    this.lineChartLabelsSust = this.systemData.structure.indicator.ispb.chart.labels;
    // colors
    this.batChartColorSust = this.systemData.structure.indicator.ispb.chart.colors;
  }

  changeHistoricsInterval(intervalTime: string) {
    const today = moment(new Date());
    switch (intervalTime) {
      case 'week':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(7, 'd').format('yyyy-MM-DD');

        break;
      case 'month':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(1, 'M').format('yyyy-MM-DD');
        break;
      case 'year':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(1, 'y').format('yyyy-MM-DD');
        break;

      default:
        break;
    }
  }

  /**
   * Change the indicator tab from toggle value
   * @param indicator
   */
  onIndicatorToggleChange(indicator) {
    this.indicatorToggle = indicator;
  }

  /**
   * Change the indicator tab from sub toggle value
   * @param indicator
   */
  onSubIndicatorToggleChange(indicator) {
    // console.log("indicatorToggle and subIndicatorToggle: ", this.indicatorToggle, indicator)
    this.subIndicatorToggle = indicator;
  }

  public resetToggleTime() {
    this.toggleTimeValue = null;
  }

  validateDates(startDate: any, endDate: any) {
    const notPass = false;
    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.timeZone)
      .format('yyyy-MM-DD');

    if (startDate === '' && endDate === '') {
      this.sweetAlertService.message(
        'Fecha inválida',
        'Ingrese una fecha inicial y final',
        'warning',
        'OK'
      );
      return notPass;
    } else if (startDate === '' && endDate !== '') {
      if (
        moment(endDate)
          .utcOffset(this.systemData.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      return true;
    } else if (startDate !== '' && endDate === '') {
      if (
        moment(startDate)
          .utcOffset(this.systemData.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha inicial no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      return true;
    } else if (startDate !== '' && endDate !== '') {
      if (
        moment(endDate)
          .utcOffset(this.systemData.timeZone)
          .diff(moment(startDate).utcOffset(this.systemData.timeZone), 'days') >
        32
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La diferencia de fechas no puede ser mayor a 32 días',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (startDate > endDate) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final tiene que ser mayor a la fecha inicial',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (
        moment(startDate)
          .utcOffset(this.systemData.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate &&
        moment(endDate)
          .utcOffset(this.systemData.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'Ambas fechas no pueden ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (
        moment(endDate)
          .utcOffset(this.systemData.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
    }
  }

  async changeChartsInterval() {
    if (this.validateDates(this.startDate, this.endDate) != false) {
      const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
      const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

      if (localStorage.getItem('indicator') == 'availability') {
        this.tabLoading2 = true;
        await this.getAvailabilityHistChartData(1, startDate, endDate);
        this.tabLoading2 = false;
      }
    }
  }

  async getAvailabilityHistChartData(
    loadTab: number,
    startDate: string,
    endDate: string
  ) {
    // RecordsChart Left
    const loadChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.equipmentId,
      loadTab - 1,
      startDate,
      endDate,
      this.systemData.timeZone
    );

    this.lineChartDataLeft = loadChartsData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    // max and min
    this.lineChartOptionsLeft.scales.yAxes[0].ticks.min =
      this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.idga.limits.varMin];
    this.lineChartOptionsLeft.scales.yAxes[0].ticks.max =
      this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.idga.limits.varMax];

    this.lineChartLabelsLeft = loadChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.timeZone)
        .format('DD MMM hh:mm a');
    });

    // RecordsChart Left
    const rightChartData = await this.systemsService.getRecordsBars(
      this.systemData.id,
      this.systemData.equipmentId,
      0,
      'week'
    );

    this.barChartDataRight = rightChartData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.bars.' + data.label
      );
      return data;
    });

    // max and min
    this.barChartOptions.scales.yAxes[0].ticks.min =
    this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.idgb.limits.varMin];
    this.barChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.plantDetails.frontLimits[this.systemData.structure.indicator.idgb.limits.varMax];

    this.barChartLabelsRight = rightChartData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  public maxLimitChartsChange() {
    if (window.innerWidth > 800) {
      this.lineChartOptionsLeft.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsLeft.scales.yAxes[0].ticks.maxTicksLimit = 5;
    } else if (window.innerWidth <= 800) {
      this.lineChartOptionsLeft.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsLeft.scales.yAxes[0].ticks.maxTicksLimit = 5;
    }
  }

  async actualIndicator() {
    const yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    if (localStorage.getItem('indicator') !== null) {
      if (
        localStorage.getItem('indicator') === 'availability' &&
        this.indicators[0].off === 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        await this.getAvailabilityHistBars('week');
        await this.getAvailabilityHistoricalValues(true);
        await this.getAvailabilityHistChartData(
          1,
          yesterdayDate,
          this.defaultDate
        );
      } else if (
        localStorage.getItem('indicator') === 'profitability' &&
        this.indicators[1].off === 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        await this.getProjectionsData();
      } else if (
        localStorage.getItem('indicator') === 'sustainability' &&
        this.indicators[2].off === 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        await this.getSustData(this.defaultDate, this.defaultDate);
        await this.getSustHistBars('week');
      }
    } else {
      this.indicatorToggle = 'availability';
    }
  }

  setTogglesValues(indicator) {
    this.indicatorToggle = indicator;
    this.subIndicatorToggle = 'deviations';
    localStorage.setItem('indicator', indicator);
  }

  async downloadRecord() {
    if (this.validateDates(this.startDate, this.endDate) != false) {
      const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
      const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

      if (localStorage.getItem('indicator') == 'availability') {
        this.tabLoading2 = true;
        const download = await this.indicatorsService.downloadRecords(
          this.systemData.id,
          this.systemData.equipmentId,
          startDate,
          endDate,
          this.systemData.timeZone
        );
        this.tabLoading2 = false;
        this.utilService.downloadCSVFile(this.systemData.name, download, startDate, endDate);
      }
    }
  }

}

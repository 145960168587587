import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSelectionList } from "@angular/material/list";
import * as _moment from "moment";
import { ChartDataSets, ChartOptions } from "chart.js";
import { Color, Label } from "ng2-charts";

import { SweetAlertService } from "src/app/services/alert/sweet-alert.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { TranslateConfigService } from "src/app/services/translate-config/translate-config.service";
import { DashboardComponent } from "../dashboard.component";
import { SystemsService } from "src/app/services/systems/systems.service";
import { ConfigsService } from "src/app/services/configs/configs.service";
import { BalanceService } from "src/app/services/balance/balances.service";
import { AnalysisService } from "src/app/services/analysis/analysis.service";
@Component({
  selector: "app-balance",
  templateUrl: "./balance.component.html",
  styleUrls: ["./balance.component.scss"],
})
export class BalanceComponent implements OnInit {
  loading = true;

  summaryPage = true;

  clickPanel = false;
  systems: any[] = [];
  systems2: any[] = [];

  @ViewChild(MatSelectionList) visualization4: MatSelectionList;

  // range time
  startDate;
  endDate;
  startDate2;
  endDate2;
  system: any;
  project:any;
  originSystem: any;
  destinationSystem: any;
  interval: any;
  errorDate = false;
  errorDate2 = false;
  reff = "";
  comment = "";
  type = "";
  searchType = "";
  liters = 0;
  error = "";
  datasources = [];
  displayedColumns = ["Tipo", "Fecha", "Referencia", "Litros", "Comentario"];
  bar1 = [];
  bar2 = [];
  bar3 = [];
  bar4 = [];
  linear_1 = [];
  linear_2 = [];
  trasegado = 0;

  // languague
  languague = "ES";

  // linear chart
  lineChartData: ChartDataSets[] = [];
  lineChartOptions: ChartOptions = {
    // resumen Banco de baterias
    elements: {
      point: {
        radius: 0,
      },
    },
    title: {
      display: true,
      text: "Suministro Combustible",
    },
    responsive: true,
    tooltips: {
      mode: "index",
      intersect: false,
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 13,
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          id: "first-y-axis",
          type: "linear",
          gridLines: {
            drawOnChartArea: true,
          },
          ticks: {
            autoSkip: true,
            // maxTicksLimit: 8,
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: Math.round,
        font: {
          weight: "bold",
        },
      },
    },
  };
  lineChartColors: Color[] = [
    {
      // load
      borderColor: "#0045FF",
      backgroundColor: "transparent",
      pointBackgroundColor: "#0045FF",
    },
    {
      // load
      borderColor: "#FF0000",
      backgroundColor: "transparent",
      pointBackgroundColor: "#FF0000",
    },
    {
      // solar
      borderColor: "#52CEFF",
      backgroundColor: "transparent",
      pointBackgroundColor: "#52CEFF",
    },
    {
      // solar
      borderColor: "#8604AD",
      backgroundColor: "#8604AD",
      pointBackgroundColor: "#8604AD",
    },
    {
      // solar
      borderColor: "#0CB806",
      backgroundColor: "#0CB806",
      pointBackgroundColor: "#0CB806",
    },
    {
      // solar
      borderColor: "#F36A6A",
      backgroundColor: "#F36A6A",
      pointBackgroundColor: "#F36A6A",
    },
  ];
  // COMMON
  lineChartLabels: Label[] = [];
  lineChartLegend = true;
  lineChartType = "line";

  constructor(
    public notificationsService: NotificationsService,
    public sweetAlertService: SweetAlertService,
    private translate: TranslateConfigService,
    public dashboardComponent: DashboardComponent,
    public systemsService: SystemsService,
    public configsService: ConfigsService,
    public balanceService: BalanceService,
    private analysisSerice: AnalysisService
  ) {}

  async ngOnInit() {
    await this.getSystems();
    this.loading = false;
  }

  async getSystems() {
    let data = await this.analysisSerice.getSystemAndCompanies(
      localStorage.getItem("COMPANY_ID"),
      
    );
    if (data.filter) {
      data.filter.shift();
      this.systems = data.filter;
      this.systems2 = Array.from(this.systems);
      this.systems2 = this.systems.filter((sys) => sys.isProject ==false);
      this.systems2.unshift({ id: 300, name: "Radio Bases" });
    }
  }

  async typeChange(){
    await this.getSystems();
    //filter systems by object value
    let comparation= false;
    if(this.searchType == "project"){
      comparation = true;
    }
  
    this.systems = this.systems.filter((sys) => sys.isProject ==comparation);
    
  }

  ngAfterViewInit() {}

  check() {
    if (this.type == "fill") {
      return false;
    } else {
      return true;
    }
  }
  checkSearch() {
    if (this.searchType == "individual") {
      return false;
    } else {
      return true;
    }
  }

  async add() {
    if (this.type == "fill") {
      this.originSystem = 300;
    }

    if (
      this.reff != undefined &&
      this.comment != undefined &&
      this.liters != undefined &&
      this.originSystem != undefined &&
      this.destinationSystem != undefined &&
      this.type != undefined &&
      this.startDate2 != undefined
    ) {
      if (this.type == "fill") {
        await this.balanceService.fill(
          this.destinationSystem,
          this.startDate2,
          this.reff,
          this.comment,
          this.liters
        );
        this.sweetAlertService.message(
          "Exito",
          "El suministro se ha registrado correctamente.",
          "success",
          "OK"
        );
        this.destinationSystem = undefined;
        this.reff = "";
        this.comment = "";
        this.liters = 0;
        this.startDate2 = undefined;
        this.type = "";
        this.originSystem = undefined;
      }
      if (this.type == "transfer") {
        await this.balanceService.transfer(
          this.originSystem,
          this.destinationSystem,
          this.startDate2,
          this.reff,
          this.comment,
          this.liters
        );
        this.sweetAlertService.message(
          "Exito",
          "El trasegado se ha registrado correctamente.",
          "success",
          "OK"
        );
        this.originSystem = undefined;
        this.destinationSystem = undefined;
        this.reff = "";
        this.comment = "";
        this.liters = 0;
        this.startDate2 = undefined;
        this.type = "";
      }
    }
  }

  async search() {
    if(
      this.searchType == "individual"
    ){
      await this.searchIndividual();
    }else{
      await this.searchProject();
    }

    this.startDate = undefined;
    this.endDate = undefined;
    this.system = undefined;
    this.interval = undefined;
  }

  async searchProject() {
    let requestData;
    if (
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.system != undefined &&
      this.interval != undefined
    ) {
      console.log(this.endDate);
      //add one day to the end date
      let date = new Date(this.endDate);
      date.setDate(date.getDate() );
      this.endDate = date.toISOString().split("T")[0];
      requestData = await this.balanceService.getBalanceProject(
        this.system,
        this.startDate,
        this.endDate,
        this.interval
      );
      console.log(requestData);
      if (!requestData) {
        this.sweetAlertService.message(
          "Error",
          "No se encontraron registros para la busqueda realizada.",
          "error",
          "OK"
        );
      }

      this.datasources = [];
      this.bar1 = [];
      this.bar2 = [];
      this.bar3 = [];
      this.bar4 = [];
      this.linear_1 = [];
      this.linear_2 = [];
      this.lineChartLabels = [];
      this.trasegado = 0;
      if (requestData["graphData"].length > 0) {
        requestData["graphData"].pop();
      }
      if (this.interval == "monthly") {
        this.lineChartLabels.push("");
        this.bar1.push(null);
        this.bar2.push(null);
        this.bar3.push(null);
        this.bar4.push(null);
        this.linear_1.push(null);
        this.linear_2.push(null);
      }

      requestData["operations"].forEach((element) => {
        let date = element.attributes.transferDate.split("T")[0];
        let dateArray = date.split("-");
        let newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        if (date == this.endDate) {
          return;
        }
        this.datasources.push({
          type: !element.attributes.originSystemId ? "Suministro" : "Trasegado",
          date: newDate,
          reference: element.attributes.ref,
          liters: element.attributes.liters,
          comment: element.attributes.comment,
        });
      });

      requestData["graphData"].forEach((element) => {
        let date = element.date.split("T")[0];
        let dateArray = date.split("-");
        let newDate = `${dateArray[2]}/${dateArray[1]}`;
        this.lineChartLabels.push(newDate);
        this.bar1.push(element.diff);
        this.linear_1.push(element.supply);
        if (this.interval == "daily") {
          this.trasegado += element.clientLitersToday - element.supplyToday;
          this.bar2.push(element.harveyInToday);
          this.bar3.push(element.harveyOutToday * -1);
          this.bar4.push(element.clientLitersToday);
        }
        if (this.interval == "monthly") {
          this.trasegado +=
            element.clientLitersThisMonth - element.supplyThisMonth;
          this.bar2.push(element.harveyInMonth);
          this.bar3.push(element.harveyOutMonth * -1);
          this.bar4.push(element.clientLitersThisMonth);
        }

        this.linear_2.push(this.trasegado);
      });
      if (this.interval == "monthly") {
        this.lineChartLabels.push("");
        this.bar1.push(null);
        this.bar2.push(null);
        this.bar3.push(null);
        this.bar4.push(null);
        this.linear_1.push(null);
        this.linear_2.push(null);
      }
      this.lineChartData = [
        {
          type: "line",
          label: "Suministro",
          data: this.linear_1,
          // yAxisID: 'first-y-axis',
          order: 1,
          pointStyle: "circle",
          pointRadius: 5,
        },
        {
          type: "line",
          label: "Trasegado",
          data: this.linear_2,
          // yAxisID: 'first-y-axis',
          order: 1,
          pointStyle: "circle",
          pointRadius: 5,
        },

        {
          type: "line",
          label: "Diff medido-reportado",
          data: this.bar1,
          //yAxisID: 'second-y-axis',
          order: 5,
          pointStyle: "circle",
          pointRadius: 5,
        },
        {
          type: "bar",
          label: "Movimiento reportado",
          data: this.bar4,
          //yAxisID: 'second-y-axis',
          order: 6,
        },
        {
          type: "bar",
          label: "Entrada medida",
          data: this.bar2,
          //yAxisID: 'second-y-axis',
          order: 7,
        },
        {
          type: "bar",
          label: "Salida medida",
          data: this.bar3,
          //yAxisID: 'second-y-axis',
          order: 8,
        },
      ];
    } else {
      this.sweetAlertService.message(
        "Campos inclompletos",
        "Asegurese de seleccionar todos los campos para realizar la busqueda correctamente.",
        "warning",
        "OK"
      );
    }
  }


  async searchIndividual() {
    let requestData;
    if (
      this.startDate != undefined &&
      this.endDate != undefined &&
      this.system != undefined &&
      this.interval != undefined
    ) {
      console.log(this.endDate);
      //add one day to the end date
      let date = new Date(this.endDate);
      date.setDate(date.getDate() );
      this.endDate = date.toISOString().split("T")[0];
      requestData = await this.balanceService.getBalanceData(
        this.system,
        this.startDate,
        this.endDate,
        this.interval
      );
      console.log(requestData);
      if (!requestData) {
        this.sweetAlertService.message(
          "Error",
          "No se encontraron registros para la busqueda realizada.",
          "error",
          "OK"
        );
      }

      this.datasources = [];
      this.bar1 = [];
      this.bar2 = [];
      this.bar3 = [];
      this.bar4 = [];
      this.linear_1 = [];
      this.linear_2 = [];
      this.lineChartLabels = [];
      this.trasegado = 0;
      if (requestData["graphData"].length > 0) {
        requestData["graphData"].pop();
      }
      if (this.interval == "monthly") {
        this.lineChartLabels.push("");
        this.bar1.push(null);
        this.bar2.push(null);
        this.bar3.push(null);
        this.bar4.push(null);
        this.linear_1.push(null);
        this.linear_2.push(null);
      }

      requestData["operations"].forEach((element) => {
        let date = element.attributes.transferDate.split("T")[0];
        let dateArray = date.split("-");
        let newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        if (date == this.endDate) {
          return;
        }
        this.datasources.push({
          type: !element.attributes.originSystemId ? "Suministro" : "Trasegado",
          date: newDate,
          reference: element.attributes.ref,
          liters: element.attributes.liters,
          comment: element.attributes.comment,
        });
      });

      requestData["graphData"].forEach((element) => {
        let date = element.date.split("T")[0];
        let dateArray = date.split("-");
        let newDate = `${dateArray[2]}/${dateArray[1]}`;
        this.lineChartLabels.push(newDate);
        this.bar1.push(element.diff);
        this.linear_1.push(element.supply);
        if (this.interval == "daily") {
          this.trasegado += element.clientLitersToday - element.supplyToday;
          this.bar2.push(element.harveyInToday);
          this.bar3.push(element.harveyOutToday * -1);
          this.bar4.push(element.clientLitersToday);
        }
        if (this.interval == "monthly") {
          this.trasegado +=
            element.clientLitersThisMonth - element.supplyThisMonth;
          this.bar2.push(element.harveyInMonth);
          this.bar3.push(element.harveyOutMonth * -1);
          this.bar4.push(element.clientLitersThisMonth);
        }

        this.linear_2.push(this.trasegado);
      });
      if (this.interval == "monthly") {
        this.lineChartLabels.push("");
        this.bar1.push(null);
        this.bar2.push(null);
        this.bar3.push(null);
        this.bar4.push(null);
        this.linear_1.push(null);
        this.linear_2.push(null);
      }
      this.lineChartData = [
        {
          type: "line",
          label: "Suministro",
          data: this.linear_1,
          // yAxisID: 'first-y-axis',
          order: 1,
          pointStyle: "circle",
          pointRadius: 5,
        },
        {
          type: "line",
          label: "Trasegado",
          data: this.linear_2,
          // yAxisID: 'first-y-axis',
          order: 1,
          pointStyle: "circle",
          pointRadius: 5,
        },

        {
          type: "line",
          label: "Diff medido-reportado",
          data: this.bar1,
          //yAxisID: 'second-y-axis',
          order: 5,
          pointStyle: "circle",
          pointRadius: 5,
        },
        {
          type: "bar",
          label: "Movimiento reportado",
          data: this.bar4,
          //yAxisID: 'second-y-axis',
          order: 6,
        },
        {
          type: "bar",
          label: "Entrada medida",
          data: this.bar2,
          //yAxisID: 'second-y-axis',
          order: 7,
        },
        {
          type: "bar",
          label: "Salida medida",
          data: this.bar3,
          //yAxisID: 'second-y-axis',
          order: 8,
        },
      ];
    } else {
      this.sweetAlertService.message(
        "Campos inclompletos",
        "Asegurese de seleccionar todos los campos para realizar la busqueda correctamente.",
        "warning",
        "OK"
      );
    }
  }

  download(): void {}

  async clean() {}

  addEvent(date: string, event: MatDatepickerInputEvent<Date>) {
    if (this.startDate != undefined && this.endDate != undefined) {
      if (this.startDate > this.endDate) {
        this.errorDate = true;
        this.error = `${this.translate.getTranslation(
          "HOME.filters.date.errorDate.2"
        )}`;
      } else {
        this.errorDate = false;
      }
    }
  }
  addEvent2(date: string, event: MatDatepickerInputEvent<Date>) {
    if (this.startDate2 != undefined && this.endDate2 != undefined) {
      if (this.startDate2 > this.endDate2) {
        this.errorDate2 = true;
        this.error = `${this.translate.getTranslation(
          "HOME.filters.date.errorDate.2"
        )}`;
      } else {
        this.errorDate2 = false;
      }
    }
  }

  public String(value) {
    if (value == undefined) {
      return 0;
    }
    const String = value.toString();
    return String.length;
  }

  async getChartData(loadTab: number, startDate: string, endDate: string) {
    // chart records
    // let loadChartsData = await this.systemsService.getRecordsChart(
    //   this.systemData.id,
    //   this.systemData.attributes.equipmentId,
    //   loadTab - 1,
    //   startDate,
    //   endDate,
    //   this.systemData.attributes.timeZone
    // );

    // console.log('loadChartsData: ', loadChartsData);

    this.lineChartData = [
      {
        type: "line",
        label: "Combustible proyectado",
        data: [, 25000, 50000],
        // yAxisID: 'first-y-axis',
        order: 1,
        pointStyle: "circle",
        pointRadius: 5,
      },
      {
        type: "line",
        label: "Combustible reportado",
        data: [, 2948, 0],
        // yAxisID: 'first-y-axis',
        order: 1,
        pointStyle: "circle",
        pointRadius: 5,
      },
      {
        type: "line",
        label: "Combustible monitoreado",
        data: [, 24276, 37916],
        // yAxisID: 'first-y-axis',
        order: 1,
        pointStyle: "circle",
        pointRadius: 5,
        fill: false,
      },
      {
        type: "bar",
        label: "Diff proyectado-reportado",
        data: [, 0, 0],
        //yAxisID: 'second-y-axis',
        order: 5,
      },
      {
        type: "bar",
        label: "Diff monitoreado-reportado",
        data: [, 1, 2],
        //yAxisID: 'second-y-axis',
        order: 5,
      },
    ];

    this.lineChartLabels = [" ", "Enero", "Febrero", " "];
  }
}

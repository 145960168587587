import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AddEditDialogComponent } from 'src/app/components/add-edit-dialog/add-edit-dialog.component';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ModalProjectsComponent } from './modal-projects/modal-projects.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  companies: any;


  constructor(
    private dialog: MatDialog,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private translate: TranslateConfigService
  ) { }

  async ngOnInit() {
    this.companies = await this.companiesService.getCompaniesList();
    console.log(this.companies);
  }

  openProjects(company: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.id = 'test';
    dialogConfig.backdropClass = 'backdropBlack';

    dialogConfig.data = {
      action: 'open',
      title: 'projects',
      companyId: company.id,
      companyName: company.name
    };

    const dialogRef = this.dialog.open(ModalProjectsComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(async (data) => {
    //   if (data) {
    //     let response = await this.companiesService.addCompany(
    //       data.name
    //     );
    //     if (!response) {
    //       this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
    //       return;
    //     }
    //     if (response.status >= 200 && response.status < 400) {
    //       this.companies = await this.companiesService.getCompaniesList();
    //       this.snackBar.open(
    //         this.translate.getTranslation('DIALOG.companyCreated')
    //       );
    //     } else {
    //       this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
    //     }
    //   }
    // });
  }

}

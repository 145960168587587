<div class="page-content" *ngIf="!pageLoading; else matSpinner">
  <section [ngClass]="sidenavOpened ? 'fixed' : 'fixed-full-width'">
    <!-- INDICATORS -->
    <div class="indicators-section">
      <div
        [ngClass]="
          systemData.structure.common.indicatorRoute == true
            ? 'indicator indicator-1'
            : 'indicator indicator-1 p-events'
        "
        (click)="saveIndicator('availability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.equipmentId,
            systemData.id
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[0].off == 1 ? 'none' : 'initial'
        }"
      >
        <img src="../../../assets/icon/disponibilidad2.svg" alt="" />
        <div class="indicator-text-container">
          <h1>
            {{
              systemData.records[0].data[systemData.structure.common.ha1.var]
                | number : '1.0-2'
            }}
            {{ systemData.structure.common.ha1.unit }}
          </h1>
          <p>
            <!-- {{ 'HOME.indicators.autonomy' | translate }} -->
            {{ systemData.structure.common.ha1.label }}
          </p>
        </div>
      </div>
      <div
        [ngClass]="
          systemData.structure.common.indicatorRoute == true
            ? 'indicator indicator-2'
            : 'indicator indicator-2 p-events'
        "
        (click)="saveIndicator('profitability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.equipmentId,
            systemData.id
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[1].off == 1 ? 'none' : 'initial'
        }"
      >
        <img src="../../../assets/icon/rentabilidad2.svg" alt="" />
        <div class="indicator-text-container">
          <h1>
            {{
              systemData.records[0].data[systemData.structure.common.ha2.var]
                | number : '1.0-2'
            }}
            {{ systemData.structure.common.ha2.unit }}
          </h1>
          <p>
            <!-- {{ 'HOME.indicators.profitability' | translate }} -->
            {{ systemData.structure.common.ha2.label }}
          </p>
        </div>
      </div>
      <div
        [ngClass]="
          systemData.structure.common.indicatorRoute == true
            ? 'indicator indicator-3'
            : 'indicator indicator-3 p-events'
        "
        (click)="saveIndicator('sustainability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.equipmentId,
            systemData.id
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[2].off == 1 ? 'none' : 'initial'
        }"
      >
        <img src="../../../assets/icon/sustentabilidad2.svg" alt="" />
        <div class="indicator-text-container">
          <h1>
            {{
              systemData.records[0].data[systemData.structure.common.ha3.var]
                | number : '1.0-2'
            }}
            <span class="sub-index">
              {{ systemData.structure.common.ha3.unit }}
            </span>
          </h1>
          <p>
            <!-- {{ 'HOME.indicators.sustainability' | translate }} -->
            {{ systemData.structure.common.ha3.label }}
          </p>
        </div>
      </div>
    </div>
    <!-- SYSTEM DESCRIPTION -->
    <mat-card class="no-padding summary-card">
      <div class="card-header">
        <h1>{{ systemData.name }}</h1>
      </div>
      <mat-card-content>
        <div class="summary-container">
          <img class="weather-icon" [src]="imageIndicator" alt="" />
          <h3>
            {{
              systemData.records[0].data[systemData.structure.common.hb2.var]
                | number
            }}
            {{ systemData.structure.common.hb2.unit }}
          </h3>
        </div>
        <div class="summary-container right-align">
          <ion-icon class="summary-icon" name="calendar-outline"></ion-icon>
          <div>
            <h3>
              {{ systemCurrentDate }}
            </h3>
          </div>
        </div>
        <div class="summary-container">
          <ion-icon class="summary-icon" name="time-outline"></ion-icon>
          <div>
            <h3>{{ systemCurrentHour }}</h3>
          </div>
        </div>
        <div class="summary-container right-align">
          <img
            class="connect-icon"
            src="../../assets/icon/connection.svg"
            alt=""
          />
          <h3>
            {{
              'SYSTEM.status.' +
                systemData.status[0].data[0][
                  this.systemData.structure.common.hb5.var
                ] | translate
            }}
            <br />
            <span style="font-size: 0.7em">
              {{ lastConnection }}
            </span>
          </h3>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- SPINNER -->
    <mat-card class="no-padding electrical-diagram">
      <div class="card-header">
        <h1 *ngIf="indicatorValue === 1">
          {{ 'HOME.indicators.autonomy' | translate }}
        </h1>
        <h1 *ngIf="indicatorValue === 2">
          {{ 'HOME.indicators.profitability' | translate }}
        </h1>
        <h1 *ngIf="indicatorValue === 3">
          {{ 'HOME.indicators.sustainability' | translate }}
        </h1>
        <div
          class="tooltip-icon-white diagram-tooltip"
          [ngbTooltip]="tipContentDiagram"
          tooltipClass="bottom"
          placement="bottom"
        >
          <ion-icon name="help-circle"></ion-icon>
        </div>
      </div>
      <mat-card-content class="card-spinner-v2">
        <krill-spinner-remasterized
          [magnitude]="systemData.structure.common.hc1.unit"
          [maxSpinnerValue]="
            systemData.plantDetails.frontLimits[
              systemData.structure.common.hc1.limits.varMax
            ]
          "
          [textValue]="
            systemData.records[0].data[systemData.structure.common.hc1.var] * 24
              | number : '1.0-2'
          "
          [spinnerValue]="
            (systemData.records[0].data[systemData.structure.common.hc1.var] *
              24 *
              90) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.hc1.limits.varMax
              ] | number : '1.0-2'
          "
          [imgValue]="indicatorValue"
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          description="Energía carga del día"
          tooltipClass="top"
          placement="top"
        >
        </krill-spinner-remasterized>
      </mat-card-content>
    </mat-card>
    <!-- SYSTEM INDICATORS -->
    <mat-card class="no-padding balance">
      <mat-card-content>
        <div class="balance-element balance-element-title">
          <h3 class="rounded-left text-right">
            {{ 'SYSTEM.energyConsumption' | translate }}
          </h3>
          <h3 class="rounded-right text-left">
            {{ 'SYSTEM.energyContribution' | translate }}
          </h3>
        </div>
        <!-- BATTERY -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent13"
          tooltipClass="top"
          placement="top"
          *ngIf="batteriesTab > -1"
        >
          <ion-progress-bar
            class="rounded-left round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg1.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg1.var] <
              0
            "
          ></ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg1.var] <
              0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg1.var]
              ) | number : '1.0-2'
            }}
            {{ systemData.structure.common.lg1.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg1.var] >=
              0
            "
          ></span>
          <ion-icon class="icon-load" name="batt"></ion-icon>
          <span
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg1.var] <
              0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg1.var] >=
              0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg1.var]
              ) | number : '1.0-2'
            }}
            {{ systemData.structure.common.lg1.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg1.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="systemData.records[0].data.pBat >= 0"
          ></ion-progress-bar>
        </div>
        <!-- LOAD -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent14"
          tooltipClass="top"
          placement="top"
          *ngIf="loadTab > -1"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg2.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] >=
              0
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] >=
              0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0].data[systemData.structure.common.lg2.var]
              )
            }}
            {{ systemData.structure.common.lg2.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] <
              0
            "
          ></span>
          <ion-icon class="icon-load" name="newbulb"></ion-icon>
          <span
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] >=
              0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] <
              0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0].data[systemData.structure.common.lg2.var]
              )
            }}
            {{ systemData.structure.common.lg2.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg2.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg2.var] <
              0
            "
          >
          </ion-progress-bar>
        </div>
        <!--ELECTRICAL NET-->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent11"
          tooltipClass="top"
          placement="top"
          *ngIf="electricalNetTab > -1"
        >
          <span></span>
          <img
            class="balance-img"
            src="../../../assets/icon/electrical-tower-gray.svg"
            alt=""
          />
          <h4 class="value-progress-bar-right">
            {{
              mathTools.roundToNumber2(
                mathTools.abs(
                  systemData.records[0].data[
                    systemData.structure.common.lg3.var
                  ]
                )
              )
            }}
            {{ systemData.structure.common.lg3.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right blue-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg3.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg3.limits.varMax
              ]
            "
          ></ion-progress-bar>
        </div>
        <!-- SOLAR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
          animation="true"
          *ngIf="solarTab > -1"
        >
          <span></span>
          <img
            class="balance-img"
            src="../../../assets/icon/solar.svg"
            alt=""
          />
          <h4 class="value-progress-bar-right">
            {{
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg4.var]
              ) | number : '1.0-2'
            }}
            {{ systemData.structure.common.lg4.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right blue-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg4.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg4.limits.varMax
              ]
            "
          ></ion-progress-bar>
        </div>
        <!-- MOTOR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
          *ngIf="generatorSetTab > -1"
        >
          <span
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg5.var] >=
              0
            "
          ></span>
          <img
            class="balance-img"
            src="../../../assets/icon/motor.svg"
            alt=""
          />
          <h4 class="value-progress-bar-right">
            {{
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg5.var]
              ) | number : '1.0-2'
            }}
            {{ systemData.structure.common.lg5.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right blue-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0].data[systemData.structure.common.lg5.var]
              ) /
              systemData.plantDetails.frontLimits[
                systemData.structure.common.lg5.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0].data[systemData.structure.common.lg5.var] >=
              0
            "
          ></ion-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- SYSTEM IMAGES -->
    <mat-card class="no-padding slides">
      <system-images
        [systemImgs]="systemImgs"
        style="height: 80%"
      ></system-images>
    </mat-card>
  </section>

  <section class="second-section">
    <indicators-tabs
      [systemData]="systemData"
      [systemIndicatorsData]="systemIndicatorsData"
      [indicatorsDeviation]="indicatorsDeviation"
      [indicatorsProjections]="indicatorsProjections"
      [indicators]="indicators"
      style="width: 100%"
    >
    </indicators-tabs>
  </section>

  <div *ngIf="tabLoading" class="bars-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

<!-- Tooltips -->

<!-- indicators -->
<ng-template #tipContent11>
  {{ 'SYSTEM.tooltips.11' | translate }}
</ng-template>
<ng-template #tipContent12>
  {{ 'SYSTEM.tooltips.12' | translate }}
</ng-template>
<ng-template #tipContent13>
  {{ 'SYSTEM.tooltips.13' | translate }}
</ng-template>
<ng-template #tipContent14>
  {{ 'SYSTEM.tooltips.14' | translate }}
</ng-template>

<ng-template #tipContent53>
  {{ 'SYSTEM.tooltips.53' | translate }}
</ng-template>
<ng-template #tipContent54>
  {{ 'SYSTEM.tooltips.54' | translate }}
</ng-template>
<!-- Availability tips -->
<!-- monitoreo -->
<ng-template #tipContent55>
  {{ 'SYSTEM.tooltips.55' | translate }}
</ng-template>
<ng-template #tipContent56>
  {{ 'SYSTEM.tooltips.56' | translate }}
</ng-template>
<ng-template #tipContent57>
  {{ 'SYSTEM.tooltips.57' | translate }}
</ng-template>
<ng-template #tipContent58>
  {{ 'SYSTEM.tooltips.58' | translate }}
</ng-template>
<ng-template #tipContent59>
  {{ 'SYSTEM.tooltips.59' | translate }}
</ng-template>
<ng-template #availableMonitChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.63' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.62' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.61' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.60' | translate }}
</ng-template>
<!-- Desviaciones -->
<ng-template #tipContent64>
  {{ 'SYSTEM.tooltips.64' | translate }}
</ng-template>
<ng-template #tipContent65>
  {{ 'SYSTEM.tooltips.65' | translate }}
</ng-template>
<ng-template #tipContent66>
  {{ 'SYSTEM.tooltips.66' | translate }}
</ng-template>
<ng-template #tipContent67>
  {{ 'SYSTEM.tooltips.67' | translate }}
</ng-template>
<ng-template #tipContent68>
  {{ 'SYSTEM.tooltips.68' | translate }}
</ng-template>
<ng-template #tipContent69>
  {{ 'SYSTEM.tooltips.69' | translate }}
</ng-template>
<ng-template #tipContent70>
  {{ 'SYSTEM.tooltips.70' | translate }}
</ng-template>
<!-- Proyecciones -->
<ng-template #tipContent71>
  {{ 'SYSTEM.tooltips.71' | translate }}
</ng-template>
<ng-template #tipContent72>
  {{ 'SYSTEM.tooltips.72' | translate }}
</ng-template>
<ng-template #tipContent104>
  {{ 'SYSTEM.tooltips.104' | translate }}
</ng-template>
<!-- Historicos -->
<ng-template #tipContent73>
  {{ 'SYSTEM.tooltips.73' | translate }}
</ng-template>
<ng-template #tipContent74>
  {{ 'SYSTEM.tooltips.74' | translate }}
</ng-template>
<ng-template #tipContent75>
  {{ 'SYSTEM.tooltips.75' | translate }}
</ng-template>
<ng-template #tipContent76>
  {{ 'SYSTEM.tooltips.76' | translate }}
</ng-template>
<ng-template #tipContent77>
  {{ 'SYSTEM.tooltips.77' | translate }}
</ng-template>
<ng-template #tipContent78>
  {{ 'SYSTEM.tooltips.78' | translate }}
</ng-template>
<ng-template #tipContent79>
  {{ 'SYSTEM.tooltips.79' | translate }}
</ng-template>
<ng-template #tipContent80>
  {{ 'SYSTEM.tooltips.80' | translate }}
</ng-template>
<ng-template #tipContent81>
  {{ 'SYSTEM.tooltips.81' | translate }}
</ng-template>
<ng-template #tipContent82>
  {{ 'SYSTEM.tooltips.82' | translate }}
</ng-template>
<ng-template #tipContent83>
  {{ 'SYSTEM.tooltips.83' | translate }}
</ng-template>
<ng-template #tipContent84>
  {{ 'SYSTEM.tooltips.84' | translate }}
</ng-template>
<ng-template #tipContent85>
  {{ 'SYSTEM.tooltips.85' | translate }}
</ng-template>
<ng-template #tipContent86>
  {{ 'SYSTEM.tooltips.86' | translate }}
</ng-template>
<ng-template #tipContent87>
  {{ 'SYSTEM.tooltips.87' | translate }}
</ng-template>
<ng-template #tipContent88>
  {{ 'SYSTEM.tooltips.88' | translate }}
</ng-template>
<!-- Sust tips -->
<!-- monitoreo -->
<ng-template #tipContent89>
  {{ 'SYSTEM.tooltips.89' | translate }}
</ng-template>
<ng-template #tipContent90>
  {{ 'SYSTEM.tooltips.90' | translate }}
</ng-template>
<ng-template #tipContent91>
  {{ 'SYSTEM.tooltips.91' | translate }}
</ng-template>
<ng-template #tipContent92>
  {{ 'SYSTEM.tooltips.92' | translate }}
</ng-template>
<ng-template #tipContent93>
  {{ 'SYSTEM.tooltips.93' | translate }}
</ng-template>
<ng-template #sustMonitChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.94' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.95' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.96' | translate }}
</ng-template>
<!-- desviaciones -->
<ng-template #tipContent97>
  {{ 'SYSTEM.tooltips.97' | translate }}
</ng-template>
<ng-template #tipContent98>
  {{ 'SYSTEM.tooltips.98' | translate }}
</ng-template>
<ng-template #tipContent99>
  {{ 'SYSTEM.tooltips.99' | translate }}
</ng-template>
<ng-template #tipContent100>
  {{ 'SYSTEM.tooltips.100' | translate }}
</ng-template>
<ng-template #tipContent101>
  {{ 'SYSTEM.tooltips.101' | translate }}
</ng-template>
<ng-template #tipContent102>
  {{ 'SYSTEM.tooltips.102' | translate }}
</ng-template>
<ng-template #tipContent103>
  {{ 'SYSTEM.tooltips.103' | translate }}
</ng-template>
<ng-template #tipContent105>
  {{ 'SYSTEM.tooltips.105' | translate }}
</ng-template>
<ng-template #tipContent106>
  {{ 'SYSTEM.tooltips.106' | translate }}
</ng-template>
<ng-template #tipContent107>
  {{ 'SYSTEM.tooltips.107' | translate }}
</ng-template>
<ng-template #tipContent108>
  {{ 'SYSTEM.tooltips.108' | translate }}
</ng-template>

<ng-template #tipContentLoadBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.9' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eacChargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.5' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatDischargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.7' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.8' | translate }}
  <br />
  <span style="color: rgba(153, 0, 76)">
    {{ 'SYSTEM.charts.bars.epvTodayzone' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.6' | translate }}
</ng-template>
<ng-template #battBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.38' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eBatDischargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.37' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.39' | translate }}
</ng-template>
<ng-template #solarBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.49' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.epvToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.48' | translate }}
</ng-template>
<ng-template #solarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.50' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.51' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.52' | translate }}
</ng-template>
<ng-template #tipContentDiagram>
  {{ 'SYSTEM.tooltips.15.1' | translate }}
  {{ 'SYSTEM.tooltips.15.2' | translate }}
  {{ 'SYSTEM.tooltips.15.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.16.1' | translate }}
  {{ 'SYSTEM.tooltips.16.2' | translate }}
  {{ 'SYSTEM.tooltips.16.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.17.1' | translate }}
  {{ 'SYSTEM.tooltips.17.2' | translate }}
  {{ 'SYSTEM.tooltips.17.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.18.1' | translate }}
  {{ 'SYSTEM.tooltips.18.2' | translate }}
  {{ 'SYSTEM.tooltips.18.3' | translate }}
</ng-template>

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectionList } from '@angular/material/list';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';

import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { DashboardComponent } from '../dashboard.component';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { AnalysisService } from 'src/app/services/analysis/analysis.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-analysis-fuel',
  templateUrl: './analysis-fuel.component.html',
  styleUrls: ['./analysis-fuel.component.scss']
})
export class AnalysisFuelComponent implements OnInit {
  // Image to krill spinner from indicator value
  indicatorImageValue = 1;

  spinnerRemasterizedDiameter = 135;

  loading = true;

  summaryPage = true;

  clickPanel = false;

  @ViewChild(MatSelectionList) visualization4: MatSelectionList;

  // range time
  startDate = '';
  endDate = '';
  today;
  interval = '';

  // linear chart
  lineChartData: ChartDataSets[] = [];
  lineChartOptions: ChartOptions = {
    // resumen Banco de baterias
    title: {
      display: true,
      text: 'Resumen de combustible'
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            // autoSkip: true,
            // minRotation: 0,
            // maxRotation: 0,
            // maxTicksLimit: 13
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Litros',
            fontColor: '#4169FF',
            fontFamily: "'Poppins', 'sans-serif'",
            fontSize: 14
          },
          ticks: {
            fontColor: '#4169FF',
            fontSize: 13
          }
        },
        {
          id: 'second-y-axis',
          type: 'linear',
          position: 'right',
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Litros',
            fontColor: '#FF0000',
            fontFamily: "'Poppins', 'sans-serif'",
            fontSize: 14
          },
          ticks: {
            fontColor: '#FF0000',
            fontSize: 13,
            min: 0
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        }
      },
      legend: {
        labels: {
          font: {
            size: 20
          }
        }
      }
    }
  };
  lineChartColors: Color[] = [
    {
      // bar left
      borderColor: '#4169FF',
      backgroundColor: '#4169FF',
      pointBackgroundColor: '#4169FF'
    },
    {
      // bar right
      borderColor: '#87CEEB',
      backgroundColor: '#87CEEB',
      pointBackgroundColor: '#87CEEB'
    },
    {
      // line
      borderColor: '#FF0000',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#FF0000'
    }
  ];
  // COMMON
  lineChartLabels: Label[] = [];
  lineChartLegend = true;
  lineChartType = 'bar';

  // filter
  selectedSystem = [];
  systems = [];

  // error date
  errorDate = false;
  error: string;

  // data table

  displayedColumns: string[] = [
    'locality',
    'date',
    'type',
    'litres'
  ];
  ELEMENT_DATA: any[] = [];
  dataSource=new MatTableDataSource<String>();

  // languague
  languague = 'ES';

  // spinners
  spinnerLeft = 0;
  spinnerRight = 0;

  constructor(
    public notificationsService: NotificationsService,
    public sweetAlertService: SweetAlertService,
    private translate: TranslateConfigService,
    public dashboardComponent: DashboardComponent,
    public systemsService: SystemsService,
    public configsService: ConfigsService,
    private analysisSerice: AnalysisService
  ) {}

  async ngOnInit() {
    this.loading = false;

    const myDate = new Date();
    this.today = new Date(
      myDate.getFullYear(),
      myDate.getMonth(),
      myDate.getDate()
    );

    // this.getChartData(0, '1', '2');

    this.summaryPage = await this.configsService.getSummaryPage(
      localStorage.getItem('USER_ID')
    );

    const companyId = localStorage.getItem('COMPANY_ID');
    const data = await this.analysisSerice.getSystemAndCompanies(companyId);
    if (data.filter) {
      data.filter.shift();
      this.systems = data.filter;
    }
  }

  ngAfterViewInit() {}

  async search() {
    const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
    const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

    moment(startDate).isAfter(endDate);
    if (moment(startDate).isAfter(endDate)) {
      this.startDate = '';
      this.endDate = '';
      return;
    }

    await this.getChartData(
      startDate,
      endDate,
      parseInt(this.selectedSystem[0].id),
      this.interval,
      this.selectedSystem[0].isProject
    );
  }

  public String(value) {
    if (value == undefined) {
      return 0;
    }
    const String = value.toString();
    return String.length;
  }

  async getChartData(
    startDate: string,
    endDate: string,
    systemId: number,
    interval: string,
    isProject: boolean
  ) {
    // chart records
    let loadChartsData = await this.analysisSerice.fuelRecords({
      startDate: startDate,
      endDate: endDate,
      id: systemId,
      interval,
      isProject
    });

    // console.log('loadChartsData: ', loadChartsData);
    var i = 0;
    this.ELEMENT_DATA = [];
    // loadChartsData.chart.outputs.forEach(element => {
    //   if(element>0){
    //     this.ELEMENT_DATA.push({
    //       locality: this.selectedSystem[0].name,
    //       date: loadChartsData.chart.labels[i],
    //       type: 'Salida',
    //       litres: element,
    //       index: i
    //     });
    //   }
    //   i++;
    // });
    i = 0;
    loadChartsData.chart.inputs.forEach(element => {
      if(element>0){
        this.ELEMENT_DATA.push({
          locality: this.selectedSystem[0].name,
          date: loadChartsData.chart.labels[i],
          type: 'Entrada',
          litres: element,
          index: i
        });
      }
      i++;
    });
    this.ELEMENT_DATA.sort((a, b) => {
      return a.index - b.index;
    });
    this.dataSource = new MatTableDataSource<String>(this.ELEMENT_DATA);
    this.lineChartData = [
      {
        type: 'bar',
        // label: 'Input',
        label: 'Litros Ingresados',
        data: loadChartsData.chart.inputs,
        yAxisID: 'first-y-axis',
        order: 2
      },
      {
        type: 'bar',
        // label: 'Ouput',
        label: 'Litros Consumidos',
        data: loadChartsData.chart.outputs,
        yAxisID: 'first-y-axis',
        order: 3
      },
      {
        type: 'line',
        label: 'Litros Disponibles',
        data: loadChartsData.chart.hfFuelTotal,
        order: 1,
        pointStyle: 'circle',
        pointRadius: 5,
        backgroundColor: '#000000',
        yAxisID: 'second-y-axis'
      }
    ];

    this.lineChartLabels = loadChartsData.chart.labels;

    this.spinnerLeft = parseInt(loadChartsData.sumOutputs);
    this.spinnerRight = parseInt(loadChartsData.sumInputs);

    this.lineChartOptions.scales.yAxes[1].ticks.max = Math.round(
      Math.max(...loadChartsData.chart.hfFuelTotal)
    );
  }
}

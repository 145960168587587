import { Component, Inject, OnInit } from '@angular/core';
import { TranslateConfigService } from '../../services/translate-config/translate-config.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SystemsService } from 'src/app/services/systems/systems.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit { 
  systemList:any[] = [];
  systemListBackup: any[] = [];

  selectedCompanies;
  selectedProjects;
  selectedRegions;
  selectedTypes;

  // filter data
  filterData = {
    companies: [],
    projects: [],
    regions: [],
    types: []
  }

  constructor(
    private translate: TranslateConfigService,
    private bottomsheet: MatBottomSheetRef<FilterModalComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) data,
    private systemsService: SystemsService,
  ) {
    console.log("received data: ", data);
    this.filterData = data.filterList;
  }

  ngOnInit() {}

  async sendFilters() {
    const dataMapSites = await this.systemsService.getMapSites(
      this.selectedCompanies.toString(),
      '['+ (this.selectedProjects ? this.selectedProjects.toString() : '' ) +']',
      '['+ (this.selectedTypes ? this.selectedTypes.toString() : '' ) +']',
      '['+ (this.selectedRegions ? this.selectedRegions.toString() : '' ) +']',
      '',
      ''
    );

    const dataSystem = await this.systemsService.getSystemListPaginated(
      this.selectedCompanies.toString(),
      '['+ (this.selectedProjects ? this.selectedProjects.toString() : '' ) +']',
      '['+ (this.selectedTypes ? this.selectedTypes.toString() : '' ) +']',
      '['+ (this.selectedRegions ? this.selectedRegions.toString() : '' ) +']',
      '1',
      '',
      ''
    );

    // console.log('dataMapSites: ', dataMapSites);
    // console.log('dataSystem: ', dataSystem);

    this.bottomsheet.dismiss({
      mapSites: dataMapSites,
      systems: dataSystem,
      currentPage: '1',
      company: this.selectedCompanies,
      projectId: this.selectedProjects,
      type: this.selectedTypes,
      regionId: this.selectedRegions
    });
  }
}
<mat-card-title> {{ 'HOME.filters.title' | translate }} </mat-card-title>
<mat-accordion multi>

  <!-- companies -->
  <mat-expansion-panel *ngIf="filterData?.companies.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'HOME.filters.companies' | translate }} ({{filterData?.companies.length}})
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list #companies [(ngModel)]="selectedCompanies" [multiple]="false">
      <mat-list-option color="primary" *ngFor="let company of filterData?.companies" [value]="company.id">
        {{ company.name }}
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>

  <!-- projects -->
  <mat-expansion-panel *ngIf="filterData?.projects.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'HOME.filters.projects' | translate }} ({{filterData?.projects.length}})
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list #projects [(ngModel)]="selectedProjects">
      <mat-list-option color="primary" *ngFor="let project of filterData?.projects" [value]="project.id">
        {{ project.name }}
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>

  <!-- regions -->
  <mat-expansion-panel *ngIf="filterData?.regions.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'HOME.filters.regions' | translate }} ({{filterData?.regions.length}})
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list #regions [(ngModel)]="selectedRegions">
      <mat-list-option color="primary" *ngFor="let region of filterData?.regions" [value]="region.id">
        {{ region.name }}
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>

  <!-- types -->
  <mat-expansion-panel *ngIf="filterData?.types.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ 'HOME.filters.types' | translate }} ({{filterData?.types.length}})
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list #types [(ngModel)]="selectedTypes">
      <mat-list-option color="primary" *ngFor="let type of filterData?.types" [value]="type.id">
        {{ type.name }}
      </mat-list-option>
    </mat-selection-list>
  </mat-expansion-panel>

  <mat-action-row>
    <button
      mat-button color="primary"
      (click)="sendFilters()"
    >
      Filtrar
    </button>
  </mat-action-row>
  
</mat-accordion>
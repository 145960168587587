<div class="page-content" *ngIf="!loading; else matSpinner">
  <div class="main-container">
    <div class="time-range">
      <mat-card class="filter-card">
        <mat-card-title> Rango de tiempo </mat-card-title>
        <mat-card-content>
          <mat-form-field appearance="fill">
            <mat-label>Desde</mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [(ngModel)]="startDate"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Hasta</mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [(ngModel)]="endDate"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Intérvalo</mat-label>
            <mat-select [(value)]="interval">
              <mat-option value="daily"> Diario </mat-option>
              <!-- <mat-option value="weekly"> Semanal </mat-option> -->
              <mat-option value="monthly"> Mensual </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-error
            *ngIf="errorDate"
            style="font-size: 12px; margin-top: -13px; margin-bottom: 10px"
            >{{ error }}
          </mat-error>

          <mat-accordion>
            <!-- By locality -->
            <mat-expansion-panel [expanded]="true" class="filters">
              <mat-expansion-panel-header>
                <mat-panel-title> Por localidad </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list
                id="system"
                #system
                [(ngModel)]="selectedSystem"
                [multiple]="false"
              >
                <mat-list-option
                  *ngFor="let system of systems"
                  color="primary"
                  [value]="system"
                >
                  {{ system.name }}
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>

        <div style="text-align: center">
          <ion-button
            (click)="search()"
            [disabled]="
              this.startDate === '' ||
              this.endDate === '' ||
              this.interval === '' ||
              this.selectedSystem.length === 0
            "
          >
            Buscar
          </ion-button>
        </div>
      </mat-card>
    </div>

    <div class="chart">
      <mat-card class="column-card column-card-2">
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartData"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
          >
          </canvas>
        </div>
      </mat-card>
    </div>

    <div class="bar-charts">
      <!-- card -->
      <mat-card class="column-card">
        <!-- icon card -->
        <div class="icon-card-container">
          <img src="./assets/icon/new/electrical-tower-gray.svg" alt="" />
        </div>

        <!-- title card -->
        <mat-card-title class="title">
          <!-- {{ systemData.attributes.structure.summary.cards.ft0.label }} -->
          Red eléctrica
        </mat-card-title>

        <!-- spinner 1 -->
        <krill-spinner-v2
          format="vertical"
          magnitude=""
          [maxSpinnerValue]="spinnerLeft1"
          [textValue]="spinnerLeft1"
          imgValue=""
          description
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Energia del período (MWh)"
          tooltipClass="top"
          placement="top"
        >
        </krill-spinner-v2>

        <!-- spinner 2 -->
        <krill-spinner-v2
          format="vertical"
          magnitude=""
          [maxSpinnerValue]="spinnerLeft2"
          [textValue]="spinnerLeft2"
          imgValue=""
          description
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Potencia máxima (kW)"
          tooltipClass="top"
          placement="top"
        >
        </krill-spinner-v2>
      </mat-card>

      <!-- card -->
      <mat-card class="column-card">
        <!-- icon card -->
        <div class="icon-card-container">
          <img src="./assets/icon/summary/Icons_Energia GRAY.svg" alt="" />
        </div>

        <!-- title card -->
        <mat-card-title class="title">
          <!-- {{ systemData.attributes.structure.summary.cards.ft0.label }} -->
          Electrógeno
        </mat-card-title>

        <!-- spinner 1 -->
        <krill-spinner-v2
          format="vertical"
          magnitude=""
          [maxSpinnerValue]="spinnerRight1"
          [textValue]="spinnerRight1"
          imgValue=""
          description
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Energia del período (MWh)"
          tooltipClass="top"
          placement="top"
        >
        </krill-spinner-v2>

        <!-- spinner 2 -->
        <krill-spinner-v2
          format="vertical"
          magnitude=""
          description
          [maxSpinnerValue]="spinnerRight2"
          [textValue]="spinnerRight2"
          imgValue=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Cantidad de arranques (#)"
          tooltipClass="top"
          placement="top"
        >
        </krill-spinner-v2>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

import { Injectable } from "@angular/core";
import axios from "axios";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          "Bearer " + localStorage.getItem("TOKEN");
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
        return Promise.reject(error);
      }
    );
  }

  public async getNotifications(
    page: number,
    userId: string,
    flagType: [] = [],
    systemId,
    visibility: string = "true",
    startDate,
    endDate,
    notificationsVisible = "true"
  ) {
    let flagTypes = "";
    let systemIds = "";
    let route;

    if (flagType.length === 0) {
      flagTypes = "[]";
    } else {
      for (let i = 0; i < flagType.length; i++) {
        if (flagType.length === 1) {
          flagTypes = `[${flagTypes + flagType[i]}]`;
        } else {
          if (flagTypes === "") {
            flagTypes = flagType[i];
          } else {
            flagTypes = flagTypes + "," + flagType[i];
          }
        }
      }

      if (flagType.length > 1) {
        flagTypes = `[${flagTypes}]`;
      }
    }

    if (systemId.length === 0) {
      systemIds = "[]";
    } else {
      for (let i = 0; i < systemId.length; i++) {
        if (systemId.length === 1) {
          systemIds = `[${systemIds + systemId[i]}]`;
        } else {
          if (systemIds === "") {
            systemIds = systemId[i];
          } else {
            systemIds = systemIds + "," + systemId[i];
          }
        }
      }

      if (systemId.length > 1) {
        systemIds = `[${systemIds}]`;
      }
    }

    try {
      const response = await axios.get(
        `
        ${
          environment.apiUrl
        }notifications/?userId=${userId}&flagType=${flagTypes}&systemId=${systemIds}${
          startDate === undefined ? "" : "&startDate=" + startDate
        }${endDate === undefined ? "" : "&endDate=" + endDate}&isEnergy=false`,
        {
          headers: {},
        }
      );
      /*
      let response = await axios.get(`
      ${environment.apiUrl}notifications/${page}`,
        {
          params: {
            flagType : flagType.length == 0 ? '[]' : flagType,
            systemId : systemId.length == 0 ? '[]' : systemId,
            visibility : visibility == 'all' ? '' : `&visibility=${visibility}`
          },
          headers: {}
        }
      );*/
      console.log("response ", response);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystemsForReport(companyId: string, projectId: string) {
    console.log(projectId);
    try {
      let response = await axios.get(
        `
        ${environment.apiUrl}systems/report/list/${companyId}?projectId=${projectId}&isEnergy=false`,
        {
          headers: {},
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  public async readNotification(id: string) {
    try {
      const response = await axios.put(
        `
        ${environment.apiUrl}notifications/read/${id}`,
        {
          headers: {},
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async readUserNotification(userId: string) {
    try {
      let response = await axios.post(
        `
        ${environment.apiUrl}notifications/read/user/${userId}`,
        {
          headers: {},
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystems(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          "systems/list/resume" +
          "?companyId=" +
          companyId +
          "&projectId=" +
          projectId +
          "&type=" +
          type +
          "&regionId=" +
          regionId+"&isEnergy=false",
        {
          headers: {},
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getLastNotificationBySystem(systemId: number) {
    try {
      const response = await axios.get(
        `
        ${environment.apiUrl}notifications/last/${systemId}`,
        {
          headers: {},
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }
}

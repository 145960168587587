<div class="container">
  <div>
    <mat-toolbar>
      <ion-searchbar
        placeholder="{{ 'HOME.filters.search' | translate }}"
        (ionInput)="searchList($event)"
      ></ion-searchbar>
      <button class="mat-raised-button" (click)="close()">Cerrar</button>
    </mat-toolbar>
  </div>
  <mat-paginator
    style="width: 100%; border-radius: 10px"
    #paginator
    [length]="listLength"
    [pageSize]="pageSize"
    (page)="pageEvent = getSystems($event)"
    showFirstLastButtons
  >
  </mat-paginator>
  <table mat-table [dataSource]="list100" style="width: 100%">
    <!-- System Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef style="text-align: left">
        {{ 'HOME.views.id' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: left">
        {{ element.id }}
      </td>
    </ng-container>

    <!-- System Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef style="text-align: left">
        {{ 'HOME.views.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: left">
        {{ element.name }}
      </td>
    </ng-container>
    <!-- System region Column -->
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef style="text-align: left">Región</th>
      <td mat-cell *matCellDef="let element" style="text-align: left">
        {{ checkRegionData(element) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="send(row)"
    ></tr>
  </table>
</div>

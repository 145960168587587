import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

import { SystemsService } from 'src/app/services/systems/systems.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';

@Component({
  selector: 'app-t1-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
  indicatorValue = 1;

  imageIndicator: string;

  sidenavOpened: boolean;

  pageLoading = true;
  barsLoading = false;
  tabLoading = false;
  tabLoading2 = false;
  histLoading = false;

  spinnerRemasterizedDiameter = 135;

  systemIndicatorsData: any;
  systemData: any;
  systemId: string;

  systemCurrentDate: string;
  systemCurrentHour: string;

  lastConnection: string;

  indicatorsDeviation: any;

  availabilityHistoricsTable: any;
  availabilityHistoricalValues: any;

  sustHistTable: any;

  indicatorsProjections: any;

  systemImgs: [];

  indicators: any[] = [
    {
      name: 'availability',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  // tabs
  loadTab: any;
  electricalNetTab: any;
  batteriesTab: any;
  solarTab: any;
  generatorSetTab: any;

  indicatorToggle: any;
  subIndicatorToggle: any;

  constructor(
    public systemsService: SystemsService,
    private activatedRoute: ActivatedRoute,
    public indicatorsService: IndicatorsService,
    private config: NgbTooltipConfig,
    public sweetAlertService: SweetAlertService,
    public sidenavService: SidenavService,
    public mathTools: MathToolsService
  ) {
    config.disableTooltip = true;
    config.container = 'body';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
    // this.maxLimitChartsChange();
  }

  async ngOnInit() {
    // await this.systemsService.isEquipmentRight();
    this.systemId = this.activatedRoute.snapshot.paramMap.get('id');

    // Set spinner Diameter and charts Ticks
    this.spinnerResizes();
    // this.maxLimitChartsChange();

    await this.systemDataSetup();
    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemId
    );
    // console.log("systemIndicatorsData: ", this.systemIndicatorsData);

    this.indicatorsDeviation =
      await this.indicatorsService.getIndicatorsDeviation(this.systemId);
    this.indicatorsProjections = await this.systemsService.getSystemProjections(
      this.systemId
    );

    this.pageLoading = false;

    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  setTogglesValues(indicator) {
    this.indicatorToggle = indicator;
    this.subIndicatorToggle = 'deviations';
    localStorage.setItem('indicator', indicator);
  }

  async systemDataSetup() {
    this.systemData = await this.systemsService.getSystemById(this.systemId);

    // tabs
    this.loadTab = this.systemData.structure.common.systemTabs.load ? 1 : -1;
    this.electricalNetTab = this.systemData.structure.common.systemTabs.electricNet ? 1 : -1;
    this.batteriesTab = this.systemData.structure.common.systemTabs.batteries ? 1 : -1;
    this.solarTab = this.systemData.structure.common.systemTabs.solar ? 1 : -1;
    this.generatorSetTab = this.systemData.structure.common.systemTabs.generatorSet ? 1 : -1;

    // add image
    this.imageIndicator = `../../assets/weather/1x/${this.systemData.records[0].data.sWeatherIc}.png`;

    // console.log(this.systemData);
    // this.systemData.structure.common.indicatorRoute = false;

    // Para deshabilitar cualquier indicador recibido del backend
    this.indicators.forEach( (indicator, i) => {
      if (this.systemData.structure.common.inactiveIndicators[this.indicators[i].name]){
        indicator.off = 1;
      }
    });

    this.systemImgs = JSON.parse(this.systemData.imgs);

    const today = new Date();
    const date = moment(today).utcOffset(this.systemData.timeZone);
    this.systemCurrentDate = date.format('DD, MMM YYYY');
    this.systemCurrentHour = date.format('hh:mm:ss a');

    this.lastConnection = moment
      .unix(this.systemData.status[0].data[0].lastReport)
      .format('DD/MM/YYYY hh:mm:ss a');
  }



  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1200) {
      this.spinnerRemasterizedDiameter = 115;
    } else if (window.innerWidth <= 1200) {
      this.spinnerRemasterizedDiameter = 115;
    }
    if (window.innerWidth < 1100) {
      this.spinnerRemasterizedDiameter = 115;
    }
    if (window.innerWidth < 1000) {
      this.spinnerRemasterizedDiameter = 115;
    }
    if (window.innerWidth < 800) {
      this.spinnerRemasterizedDiameter = 115;
    }
    if (window.innerWidth < 580) {
      this.spinnerRemasterizedDiameter = 150;
    }
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string){
    if (indicator) { localStorage.setItem('indicator', indicator); }
  }

}

<div class="page-content" *ngIf="!pageLoading; else matSpinner">
  <mat-card [ngClass]="sidenavOpened ? 'fixed' : 'fixed-full-width'">
    <!-- indicator section -->
    <mat-card class="indicators-section">
      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('availability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[0].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/up-barrel-green.svg"
          ></ion-icon>
          <mat-card-subtitle> Entradas acumuladas </mat-card-subtitle>
          <mat-card-title class="connected-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha1
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha1.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('profitability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[1].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/down-barrel-red.svg"
          ></ion-icon>
          <mat-card-subtitle> Salidas acumuladas </mat-card-subtitle>
          <mat-card-title class="alarm-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha2
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha2.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('sustainability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[2].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/gasoline-can-orange.svg"
          ></ion-icon>
          <mat-card-subtitle> Promedio entre recargas </mat-card-subtitle>
          <mat-card-title class="alert-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha3
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha3.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>
    <!-- data section -->
    <mat-card class="data-section">
      <mat-card-header class="bg-gradient-blue">
        <mat-card-subtitle class="site-name">
          {{ systemData.attributes.name }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="system-description-container">
        <div class="system-description-weather">
          <p>
            <!-- {{ 'SYSTEM.weather.' + systemData.records[0].icon | translate }} -->
            {{ utils.getWeather(systemData.records[0].icon) }}
          </p>
          <p>
            {{
              systemData.records[0][
                systemData.attributes.structure.common.hb2.var
              ] | number
            }}
            {{ systemData.attributes.structure.common.hb2.unit }}
          </p>
        </div>
        <div class="system-description-dates">
          <p>{{ systemCurrentDate }}</p>
          <p>{{ systemCurrentYear }}</p>
          <p>{{ systemCurrentHour }}</p>
        </div>
        <div class="system-description-logo">
          <ion-icon
            class="weather-icon"
            src="assets/icon/weather/rainy-day.svg"
          ></ion-icon>
          <!-- <img class="weather-icon" [src]="imageIndicator" alt="" /> -->
        </div>
        <div class="system-description-icon">
          <p style="font-size:smaller;">
            <!-- {{ systemsService.getSystemNotification(systemData.records) }} -->
            <ion-icon
              class="icon"
              name="notifications"
              [ngClass]="
                systemData.records[0].status == null
                  ? 'icon green-icon'
                  : systemData.records[0].status.sNotifications == 'alarm'
                  ? 'icon red-icon'
                  : systemData.records[0].status.sNotifications == 'warning'
                  ? 'icon yellow-icon'
                  : systemData.records[0].status.sNotifications == 'info'
                  ? 'icon blue-icon'
                  : 'icon green-icon'
              "
            >
            </ion-icon>
            {{
              systemsService.getSystemNotification(
                systemData.records,
                lastNotification.notification.title
              )
            }}
          </p>
        </div>
        <div
          [ngClass]="
            systemData.attributes.status == 00000
              ? 'system-description-status bg-black'
              : 'system-description-status bg-green'
          "
        >
          <p>
            {{
              (
                'SYSTEM.status.' +
                  systemsService.getSystemStatus(systemData.attributes.status)
                | translate
              ).toUpperCase()
            }}
          </p>
          <p>{{ lastConnection }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- spinner section -->
    <mat-card class="spinner-section">
      <mat-card-header>
        <mat-card-subtitle> Combustible </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="spinner-v2">
        <krill-spinner-v2
          format="vertical"
          magnitude="Litros"
          [maxSpinnerValue]="
            systemData.attributes.plantDetails.system.FrontLimits[
              systemData.attributes.structure.common.hc1.limits.varMax
            ]
          "
          [textValue]="
            systemData.records[0][
              systemData.attributes.structure.common.hc1.var
            ] | number : '1.0-0'
          "
          imgValue="assets/icon/home/spinner-gasoline-can.svg"
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle=""
          tooltipClass="top"
          placement="top"
          [description]="false"
        >
        </krill-spinner-v2>
      </mat-card-content>
    </mat-card>
    <!-- map section -->
    <mat-card class="map-section">
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <agm-map [latitude]="mapLat" [longitude]="mapLng" [zoom]="mapZoom">
          <agm-marker
            [latitude]="systemData.attributes.latitude"
            [longitude]="systemData.attributes.longitude"
            [iconUrl]="
              './assets/marks/mark-' +
              systemsService.getSystemStatusColor(
                systemData.attributes.status
              ) +
              '.png'
            "
          >
            <agm-info-window>
              <button>
                <ion-img
                  *ngIf="
                    systemData.attributes.imgs != null;
                    else krillThumbnail
                  "
                  [src]="
                    systemsService.getSystemFirstImg(systemData.attributes)
                  "
                  class="thumbnail"
                ></ion-img>
                <ng-template #krillThumbnail>
                  <ion-img
                    src="../../../../assets/logos/favicon.png"
                    class="thumbnail krill-thumbnail"
                  >
                  </ion-img>
                </ng-template>
                <strong style="color: black">{{
                  systemData.attributes.name
                }}</strong>
              </button>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </mat-card-content>
    </mat-card>
    <!-- images section -->
    <mat-card class="images-section">
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <system-images [systemImgs]="systemImgs"></system-images>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <mat-card class="tab-container no-padding">
    <system-tabs
      dynamicHeight
      mat-align-tabs="center"
      [tabs]="tabs"
      [systemData]="systemData"
      [systemIndicatorsData]="systemIndicatorsData"
    >
    </system-tabs>
  </mat-card>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

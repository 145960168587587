import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor() {}

  public isLoggedIn() {
    return localStorage.getItem('TOKEN') != null;
  }

  public async contact(subject, message, email) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'auths/contact',
        {
          subject,
          message,
          email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response;
    } catch (response) {
      return response.response.data.result;
    }
  }

  public async checkToken() {
    try {
      const response = await axios.get(environment.apiUrl + 'auths/token', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
        }
      });

      return response.data.result;
    } catch (response) {
      return response.response.data.result;
    }
  }

  public async login(email, password) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'users/login',
        {
          email,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response;
    } catch (err) {
      console.log('Aaaaaa');
      return err;
    }
  }

  public async contactForm(username, email, subject, description) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'dashboard/contactUs',
        {
          username,
          email,
          subject,
          description
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
          }
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  async forgotPassword(email: string) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'forgotpasswords/request',
        {
          email
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response.data);
      return response;
    } catch (err) {
      return err;
    }
  }
}

<div class="page-content" *ngIf="!loading; else matSpinner">
  <!-- map section -->
  <mat-card class="map-card">
    <div class="map">
      <agm-map [latitude]="mapLat" [longitude]="mapLng" [zoom]="mapZoom">
        <div *ngFor="let system of systemList">
          <agm-marker
            [latitude]="system.latitude"
            [longitude]="system.longitude"
            [iconUrl]="
              './assets/icon/home/' +
              systemsService.getSystemStatusColor2(system.status) +
              '-marker.svg'
            "
          >
            <agm-info-window>
              <!-- status -->
              <button
                [routerLink]="
                  systemsService.getSystemRoute(
                    system.equipmentId,
                    system.id,
                    summaryPage
                  )
                "
              >
                <ion-icon
                  [src]="
                    system.status !== '00000'
                      ? 'assets/icon/common/ellipse-on.svg'
                      : 'assets/icon/common/ellipse-off.svg'
                  "
                  class="thumbnail"
                ></ion-icon>
                <label style="color: black">
                  {{
                    'SYSTEM.status.' +
                      systemsService.getSystemStatus(system.status) | translate
                  }}
                </label>
              </button>

              <!-- notification -->
              <button
                [routerLink]="['/dashboard/notifications']"
                [state]="{ id: system.id }"
              >
                <ion-icon
                  class="thumbnail"
                  name="notifications"
                  [ngClass]="
                    system?.status === '10000'
                      ? 'green-icon'
                      : system?.status === '10010'
                      ? 'red-icon'
                      : system?.status === '10100'
                      ? 'yellow-icon'
                      : system?.status === '11000'
                      ? 'blue-icon'
                      : 'green-icon'
                  "
                >
                </ion-icon>
                <label style="color: black">
                  {{
                    system?.status == null
                      ? 'Ok'
                      : system?.status === '10010'
                      ? 'Alarma'
                      : system?.status === '10100'
                      ? 'Alerta'
                      : system?.status === '11000'
                      ? 'Notificación'
                      : 'Sin Notificaciones'
                  }}
                </label>
              </button>
              <strong
                style="color: black; font-weight: bold"
                [routerLink]="
                  systemsService.getSystemRoute(
                    system.equipmentId,
                    system.id,
                    summaryPage
                  )
                "
                >{{ system.name }}</strong
              >
            </agm-info-window>
          </agm-marker>
        </div>
      </agm-map>
    </div>
  </mat-card>
  <!-- total section -->
  <mat-card class="total-section">
    <mat-card class="total-section-card">
      <mat-card-header>
        <ion-icon mat-card-avatar src="assets/icon/home/map.svg"></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.totalSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="total-sites-color">
          <p>{{ sitesValues.total }}</p>
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- connected sites -->
    <mat-card class="total-section-card">
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/green-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.connectedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="connected-sites-color">
          {{ sitesValues.connect }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- disconnected sites -->
    <mat-card class="total-section-card">
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/black-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.disconnectedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="disconnected-sites-color">
          {{ sitesValues.total - sitesValues.connect }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- sites with notifications -->
    <mat-card class="total-section-card">
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/blue-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.notificatedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="notification-sites-color">
          {{ sitesValues.notification }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </mat-card>

  <!-- home section -->
  <div class="system-section">
    <div class="systems-subsection">
      <div class="subsection-scroll">
        <!-- first card -->
        <mat-card class="first-column-card">
          <mat-card class="indicators-section">
            <mat-card class="indicator-card p-events">
              <mat-card-header>
                <ion-icon
                  mat-card-avatar
                  src="assets/icon/home/up-barrel.svg"
                ></ion-icon>
                <mat-card-subtitle>
                  Entradas acumuladas anual
                  <!-- {{ systemData.attributes.structure.common.ha1.label }} -->
                </mat-card-subtitle>
                <mat-card-title class="connected-sites-color">
                  <!-- {{
                                        systemData.records[0][
                                        systemData.attributes.structure.common.ha1.var
                                        ] | number : '1.0-2'
                                    }} -->
                  {{ resumen.ProjectHfInLFuelTotal }}
                  <!-- {{ systemData.attributes.structure.common.ha1.unit }} -->
                  litros
                </mat-card-title>
              </mat-card-header>
            </mat-card>
            <mat-card class="indicator-card p-events">
              <mat-card-header>
                <ion-icon
                  mat-card-avatar
                  src="assets/icon/home/down-barrel.svg"
                ></ion-icon>
                <mat-card-subtitle>
                  Salidas acumuladas anual
                  <!-- {{ systemData.attributes.structure.common.ha2.label }} -->
                </mat-card-subtitle>
                <mat-card-title class="alarm-sites-color">
                  <!-- {{
                                        systemData.records[0][
                                        systemData.attributes.structure.common.ha2.var
                                        ] | number : '1.0-2'
                                    }} -->
                  {{ resumen.ProjectHfOutLFuelTotal }}
                  <!-- {{ systemData.attributes.structure.common.ha2.unit }} -->
                  litros
                </mat-card-title>
              </mat-card-header>
            </mat-card>
            <mat-card class="indicator-card p-events">
              <mat-card-header>
                <ion-icon
                  mat-card-avatar
                  src="assets/icon/home/gasoline-can.svg"
                ></ion-icon>
                <mat-card-subtitle>
                  Promedio entre recargas
                  <!-- {{ systemData.attributes.structure.common.ha3.label }} -->
                </mat-card-subtitle>
                <mat-card-title class="alert-sites-color">
                  <!-- {{
                                        systemData.records[0][
                                        systemData.attributes.structure.common.ha3.var
                                        ] | number : '1.0-2'
                                    }} -->
                  {{ resumen.ProjectHfReFuelMean }}
                  <!-- {{ systemData.attributes.structure.common.ha3.unit }} -->
                  días
                </mat-card-title>
              </mat-card-header>
            </mat-card>
          </mat-card>

          <mat-card>
            <mat-card-content>
              <krill-spinner-v2
                magnitude="%"
                [maxSpinnerValue]="100"
                [textValue]="projectDaily.hfPctFuelTotal"
                imgValue=""
                [spinnerDiameter]="spinnerRemasterizedDiameter"
                descriptionTitle="Combustible total"
                tooltipClass="top"
                placement="top"
                subtitle="Litros disponibles"
                [descriptionValue]="projectDaily.hfLFuelTotal"
              >
              </krill-spinner-v2>
            </mat-card-content>
          </mat-card>
        </mat-card>
        <!-- second card -->
        <mat-card class="column-card">
          <mat-card class="item-card-circle">
            <ion-icon
              mat-card-avatar
              src="assets/icon/sustentabilidad.svg"
            ></ion-icon>
          </mat-card>
          <mat-card class="item-card">
            <mat-card-header>
              <mat-card-subtitle> Emisiones CO2 combustible </mat-card-subtitle>
              <mat-card-title> {{ resumen.sAporteKgCo2L/1000| number: '1.0-2' }} Ton </mat-card-title>
            </mat-card-header>
          </mat-card>
          <mat-card class="item-card">
            <mat-card-header>
              <mat-card-subtitle> Emisiones CO2 energía </mat-card-subtitle>
              <mat-card-title>{{ resumen.sAporteKgCo2kWh/1000| number: '1.0-2' }} Ton </mat-card-title>
            </mat-card-header>
          </mat-card>
          <mat-card class="item-card">
            <mat-card-header>
              <mat-card-subtitle> Emisiones CO2 anual</mat-card-subtitle>
              <mat-card-title> {{ resumen.sAporteKgCo2/1000| number: '1.0-2' }} Ton</mat-card-title>
            </mat-card-header>
          </mat-card>
        </mat-card>
        <!-- third card -->
        <mat-card class="column-card column-card-2">
          <h4>Litros disponibles</h4>
          <div class="linear-chart">
            <canvas
              baseChart
              #linearChart
              id="linearChart"
              [labels]="homeChartLabels"
              [legend]="homeChartLegend"
              [chartType]="homeChartType"
              [datasets]="homeBarChartData"
              [options]="homeBarChartOptions"
              [colors]="homeBarChartColors"
              [plugins]="chartPlugins"
            >
            </canvas>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

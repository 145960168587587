import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import axios from "axios";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BalanceService {
  constructor(private router: Router) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          "Bearer " + localStorage.getItem("TOKEN");
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
        return Promise.reject(error);
      }
    );
  }

  public async getBalanceData(systemId, startDate, endDate, interval) {
    startDate = new Date(startDate).toISOString().split("T")[0];
    endDate = new Date(endDate).toISOString().split("T")[0];
    startDate = startDate + " 00:00:00+00:00";
    endDate = endDate + " 00:00:00+00:00";
    try {
      const response = await axios.post(
        environment.apiUrl + "balances",
        {
          systemId: systemId,
          fromDate: startDate,
          toDate: endDate,
          interval: interval,
        },
        {
          headers: {},
        }
      );

      return response.data;
    } catch (err) {
      return;
    }
  }

  public async getBalanceProject(projectId, startDate, endDate, interval) {
    startDate = new Date(startDate).toISOString().split("T")[0];
    endDate = new Date(endDate).toISOString().split("T")[0];
    startDate = startDate + " 00:00:00+00:00";
    endDate = endDate + " 00:00:00+00:00";
    try {
      const response = await axios.post(
        environment.apiUrl + "balances/project",
        {
          projectId: projectId,
          fromDate: startDate,
          toDate: endDate,
          interval: interval,
        },
        {
          headers: {},
        }
      );

      return response.data;
    } catch (err) {
      return;
    }
  }

  public async fill(systemId, transferDate, ref, comment, liters) {
    transferDate = new Date(transferDate).toISOString().split("T")[0];
    transferDate = transferDate + " 00:00:00+00:00";
    try {
      const response = await axios.post(
        environment.apiUrl + "clientFuel/fill",
        {
          systemId: systemId,
          transferDate: transferDate,
          ref: ref,
          comment: comment,
          liters: liters,
        },
        {
          headers: {},
        }
      );

      return response.data;
    } catch (err) {
      return;
    }
  }

  public async transfer(
    originSystemId,
    destinationSystemId,
    transferDate,
    ref,
    comment,
    liters
  ) {
    transferDate = new Date(transferDate).toISOString().split("T")[0];
    transferDate = transferDate + " 00:00:00+00:00";
    console.log(
      originSystemId,
      destinationSystemId,
      transferDate,
      ref,
      comment,
      liters
    );
    try {
      const response = await axios.post(
        environment.apiUrl + "clientFuel/transfer",
        {
          originSystemId: originSystemId,
          destinationSystemId: destinationSystemId,
          transferDate: transferDate,
          ref: ref,
          comment: comment,
          liters: liters,
        },
        {
          headers: {},
        }
      );

      return response.data;
    } catch (err) {
      return;
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectionList } from '@angular/material/list';
import * as moment from 'moment';
import { Color, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';

import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { DashboardComponent } from '../dashboard.component';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { AnalysisService } from 'src/app/services/analysis/analysis.service';

@Component({
  selector: 'app-analysis-energy',
  templateUrl: './analysis-energy.component.html',
  styleUrls: ['./analysis-energy.component.scss']
})
export class AnalysisEnergyComponent implements OnInit {
  // Image to krill spinner from indicator value
  indicatorImageValue = 1;

  spinnerRemasterizedDiameter = 135;

  loading = true;

  summaryPage = true;

  clickPanel = false;

  @ViewChild(MatSelectionList) visualization4: MatSelectionList;

  // range time
  startDate = '';
  endDate = '';
  today;
  interval = '';

  // linear chart
  lineChartData: ChartDataSets[] = [];
  lineChartOptions: ChartOptions = {
    // resumen Banco de baterias
    elements: {
      point: {
        radius: 0
      }
    },
    title: {
      display: true,
      text: 'Resumen de energía'
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 13
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Energía kWh',
            fontColor: '#4169FF',
            fontFamily: "'Poppins', 'sans-serif'",
            fontSize: 14
          },
          ticks: {
            fontColor: '#4169FF',
            fontSize: 13
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        }
      }
    }
  };
  lineChartColors: Color[] = [
    {
      // battery
      borderColor: '#0033ff',
      backgroundColor: '#0033ff',
      pointBackgroundColor: '#0033ff'
    },
    {
      // solar
      borderColor: '#0080FF',
      backgroundColor: '#0080FF',
      pointBackgroundColor: '#0080FF'
    },
    {
      // battery
      borderColor: '#D4D7D8',
      backgroundColor: '#D4D7D8',
      pointBackgroundColor: '#D4D7D8'
    },
    {
      // solar
      borderColor: '#a4e3ff',
      backgroundColor: '#a4e3ff',
      pointBackgroundColor: '#a4e3ff'
    },
    {
      // load
      borderColor: '#FF0000',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#FF0000'
    }
  ];

  // COMMON
  lineChartLabels: Label[] = [];
  lineChartLegend = true;
  lineChartType = 'bar';

  // filter
  selectedSystem = [];
  systems = [];

  // error date
  errorDate = false;
  error: string;

  // data table
  ELEMENT_DATA: any[] = [
    {
      type: 'Entrada',
      locality: 'MTSO1',
      date: '12/09',
      hour: '3:32 pm',
      reference: 'ROF2468',
      litres: '+250'
    },
    {
      type: 'Entrada',
      locality: 'MTSO1',
      date: '12/09',
      hour: '7:00 pm',
      reference: '',
      litres: '+230'
    },
    {
      type: 'Salida',
      locality: 'RBs2',
      date: '23/09',
      hour: '2:34 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      locality: 'MTSO2',
      date: '12/09',
      hour: '3:32 pm',
      reference: '',
      litres: '-65'
    },
    {
      type: 'Salida',
      locality: 'MTSO3',
      date: '15/09',
      hour: '10:10 am',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      locality: 'RBs1',
      date: '23/09',
      hour: '2:34 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      locality: 'RBs3',
      date: '12/09',
      hour: '3:32 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      locality: 'RBs2',
      date: '15/09',
      hour: '10:10 pm',
      reference: '',
      litres: '-65'
    }
  ];

  // languague
  languague = 'ES';

  // spinners
  spinnerLeft1 = 0;
  spinnerLeft2 = 0;
  spinnerRight1 = 0;
  spinnerRight2 = 0;

  constructor(
    public notificationsService: NotificationsService,
    public sweetAlertService: SweetAlertService,
    private translate: TranslateConfigService,
    public dashboardComponent: DashboardComponent,
    public systemsService: SystemsService,
    public configsService: ConfigsService,
    private analysisSerice: AnalysisService
  ) {}

  async ngOnInit() {
    this.loading = false;

    const myDate = new Date();
    this.today = new Date(
      myDate.getFullYear(),
      myDate.getMonth(),
      myDate.getDate()
    );

    // this.getChartData(0, '1', '2');

    this.summaryPage = await this.configsService.getSummaryPage(
      localStorage.getItem('USER_ID')
    );

    const companyId = localStorage.getItem('COMPANY_ID');
    const data = await this.analysisSerice.getSystemAndCompanies(companyId);
    if (data.filter) {
      data.filter.shift();
      this.systems = data.filter;
    }
  }

  ngAfterViewInit() {}

  async search() {
    const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
    const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

    moment(startDate).isAfter(endDate);
    if (moment(startDate).isAfter(endDate)) {
      this.startDate = '';
      this.endDate = '';
      return;
    }

    await this.getChartData(
      startDate,
      endDate,
      parseInt(this.selectedSystem[0].id),
      this.interval,
      this.selectedSystem[0].isProject
    );
  }

  public String(value) {
    if (value == undefined) {
      return 0;
    }
    const String = value.toString();
    return String.length;
  }

  async getChartData(
    startDate: string,
    endDate: string,
    systemId: number,
    interval: string,
    isProject: boolean
  ) {
    // chart records
    let loadChartsData = await this.analysisSerice.energyRecords({
      startDate: startDate,
      endDate: endDate,
      id: parseInt(this.selectedSystem[0].id),
      interval: this.interval,
      isProject: this.selectedSystem[0].isProject
    });

    // console.log('loadChartsData: ', loadChartsData);

    this.lineChartData = [
      {
        type: 'bar',
        label: 'eGridUsed1',
        data: loadChartsData.chart.eGridUsed1,
        yAxisID: 'first-y-axis',
        order: 1
      },
      {
        type: 'bar',
        label: 'eGridUsed2',
        data: loadChartsData.chart.eGridUsed2,
        yAxisID: 'first-y-axis',
        order: 1
      },
      {
        type: 'bar',
        label: 'eGridUsed3',
        data: loadChartsData.chart.eGridUsed3,
        yAxisID: 'first-y-axis',
        order: 1
      },
      {
        type: 'bar',
        label: 'eGridUsed4',
        data: loadChartsData.chart.eGridUsed4,
        yAxisID: 'first-y-axis',
        order: 1
      },
      {
        type: 'line',
        label: 'eGrid Total',
        data: loadChartsData.chart.eGridTotal,
        order: 4,
        pointStyle: 'circle',
        pointRadius: 5,
        yAxisID: 'first-y-axis'
      }
    ];

    this.lineChartLabels = loadChartsData.chart.labels;

    this.spinnerLeft1 = Math.round((parseInt(loadChartsData.sumEGridTotal)/1000)*10)/10;
    this.spinnerLeft2 = parseInt(loadChartsData.maxpGrid);
    this.spinnerRight1 = Math.round((loadChartsData.sumeGensetTotal/1000)*100)/100;
    this.spinnerRight2 = parseInt(loadChartsData.sumsGensetStarts);
  }
}

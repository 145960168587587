<div class="page-content" *ngIf="!loading; else matSpinner">
  <div class="main-container">
    <!-- <div class="time-range">
      <mat-card class="filter-card">
        <mat-card-title>
          Rango de tiempo
        </mat-card-title>
        <mat-card-content>
          <mat-form-field appearance="fill">
            <mat-label>Desde</mat-label>
            <input matInput [matDatepicker]="dp2" [(ngModel)]="startDate" (dateInput)="addEvent('start', $event)">
            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Hasta</mat-label>
            <input matInput [matDatepicker]="dp3" [(ngModel)]="endDate" (dateInput)="addEvent('end', $event)">
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-error *ngIf="errorDate" style="font-size: 12px; margin-top: -13px; margin-bottom: 10px;">{{error}}</mat-error>

          <mat-form-field appearance="fill">
            <mat-label>Localidad</mat-label>
            <mat-select>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>

        </mat-card-content>
        
        <div style="text-align: center;">
          <ion-button (click)="search()">
            Buscar
          </ion-button>
          <ion-button (click)="download()">
            Descargar
          </ion-button>
        </div>
      </mat-card>
    </div> -->

    <!-- budgeted liters -->

    <div class="fuel-supply-chart">
      <mat-card>
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartData"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
          >
          </canvas>
        </div>
      </mat-card>
    </div>

    <div class="data-list">
      <mat-card class="table-container">
        <table
          mat-table
          #table
          [dataSource]="datasources"
          class="mat-elevation-z8"
          *ngIf="datasources.length>0"
        >
          <!-- Type Column -->
          <ng-container matColumnDef="Tipo">
            <th mat-header-cell *matHeaderCellDef>{{ displayedColumns[0] }}</th>
            <td mat-cell *matCellDef="let element">{{ element.type }}</td>
          </ng-container>


          <!-- Date Column -->
          <ng-container matColumnDef="Fecha">
            <th mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[1] }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
          </ng-container>

          <!-- Reference Column -->
          <ng-container matColumnDef="Referencia">
            <th mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[2] }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.reference }}</td>
          </ng-container>

          <!-- Litres Column -->
          <ng-container matColumnDef="Litros">
            <th mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[3] }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.liters }}</td>
          </ng-container>

          <ng-container matColumnDef="Comentario">
            <th mat-header-cell *matHeaderCellDef>
              {{ displayedColumns[4] }}
            </th>
            <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
          </ng-container>


          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </mat-card>
    </div>

    <div class="manual-transaction">
      <mat-card class="filter-card">
        <mat-card-title> Busqueda </mat-card-title>
        <mat-card-content>
          <mat-form-field appearance="fill" class="date">
            <mat-label>Desde</mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [(ngModel)]="startDate"
              (dateInput)="addEvent('date', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from disabled="false"></mat-datepicker>
          </mat-form-field>
    
          <mat-form-field appearance="fill" class="date">
            <mat-label>Hasta</mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [(ngModel)]="endDate"
              (dateInput)="addEvent('date', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="to"
            ></mat-datepicker-toggle>
            <mat-datepicker #to disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" >
            <mat-label>Tipo</mat-label>
            <mat-select [(value)]="searchType" (selectionChange)="typeChange()">
              <mat-option value="individual">Individual</mat-option>
              <mat-option value="project">Consolidado</mat-option>
            </mat-select>
          </mat-form-field>
    
          <mat-form-field appearance="fill" >
            <mat-label>Nombre</mat-label>
            <mat-select [(value)]="system">
              <mat-option *ngFor="let sys of systems" [value]="sys.id">
                {{ sys.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field appearance="fill">
            <mat-label>Intervalo</mat-label>
            <mat-select [(value)]="interval">
              <mat-option value="daily">Diario</mat-option>
              <mat-option value="monthly">Mensual</mat-option>
            </mat-select>
          </mat-form-field>

        </mat-card-content>
    
        <div style="text-align: center">
          <ion-button (click)="search()"> Buscar </ion-button>
        </div> 
        <br>
        <mat-card-title> Agregar Registro </mat-card-title>
        <mat-form-field appearance="fill" >
          <mat-label>Fecha</mat-label>
          <input
            matInput
            [matDatepicker]="from2"
            [(ngModel)]="startDate2"
            (dateInput)="addEvent2('date2', $event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="from2"
          ></mat-datepicker-toggle>
          <mat-datepicker #from2 disabled="false"></mat-datepicker>
        </mat-form-field>
  
        

        <mat-form-field appearance="fill">
          <mat-label>Tipo</mat-label>
          <mat-select [(value)]="type">
            <mat-option value="fill">Suministro</mat-option>
            <mat-option value="transfer">Trasegado</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="check()">
          <mat-label>Origen</mat-label>
          <mat-select [(value)]="originSystem">
            <mat-option *ngFor="let sys of systems2" [value]="sys.id">
              {{ sys.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Destino</mat-label>
          <mat-select [(value)]="destinationSystem">
            <mat-option *ngFor="let sys of systems2" [value]="sys.id">
              {{ sys.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Cantidad litros</mat-label>
          <input type="number" matInput [(ngModel)]="liters" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Referencia</mat-label>
          <input matInput [(ngModel)]="reff" />
        </mat-form-field>

        <mat-form-field appearance="fill" >
          <mat-label>Comentarios</mat-label>
          <input matInput [(ngModel)]="comment" />
        </mat-form-field>

        <div style="text-align: center">
          <ion-button (click)="add()"> Agregar </ion-button>
        </div> 
  
      </mat-card>
    </div>

    <!--
    <div class="manual-transaction">
      <mat-card class="filter-card">
        <mat-card-title> Transacción manual </mat-card-title>
        <mat-card-content>
          <mat-form-field appearance="fill" class="date">
            <mat-label>Fecha</mat-label>
            <input
              matInput
              [matDatepicker]="dp4"
              [(ngModel)]="startDate"
              (dateInput)="addEvent('date', $event)"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp4"
            ></mat-datepicker-toggle>
            <mat-datepicker #dp4 disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill" class="reference">
            <mat-label>Referencia</mat-label>
            <input matInput [(ngModel)]="reff" />
          </mat-form-field>

          <mat-form-field appearance="fill" class="origin">
            <mat-label>Origen</mat-label>
            <mat-select>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field> 
          <mat-form-field appearance="fill">
            <mat-label>Horario</mat-label>
            <mat-select [(value)]="interval">
              <mat-option value="Diurno">Diurno</mat-option>
              <mat-option value="Nocturno">Nocturno</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Tipo de transacción</mat-label>
            <mat-select [(value)]="operationType">
              <mat-option value="Suministro">Suministro</mat-option>
              <mat-option value="Trasegado">Trasegado</mat-option>
              <mat-option value="Acompañamiento">Acompañamiento</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Región</mat-label>
            <mat-select>
              <mat-option value="option1">Option 1</mat-option>
              <mat-option value="option2">Option 2</mat-option>
              <mat-option value="option3">Option 3</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Destino</mat-label>
            <mat-select [(value)]="system">
              <mat-option value="MTSO Canaima">MTSO Canaima</mat-option>
              <mat-option value="MTSO Colgate">MTSO Colgate</mat-option>
              <mat-option value="MTSO Maturín">MTSO Maturín </mat-option>
              <mat-option value="MTSO Maracay">MTSO Maracay</mat-option>
              <mat-option value="MTSO Valencia">MTSO Valencia</mat-option>
              <mat-option value="MTSO Barquisimeto"
                >MTSO Barquisimeto</mat-option
              >
              <mat-option value="MTSO Barinas">MTSO Barinas</mat-option>
              <mat-option value="MTSO San Cristóbal"
                >MTSO San Cristóbal</mat-option
              >
              <mat-option value="MTSO Maracaibo">MTSO Maracaibo</mat-option>
              <mat-option value="MTSO Puerto Ordaz"
                >MTSO Puerto Ordaz</mat-option
              >
              <mat-option value="MTSO Puerto la Cruz"
                >MTSO Puerto la Cruz</mat-option
              >
              <mat-option value="RadioBase">RadioBase</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Origen</mat-label>
            <mat-select [(value)]="provider">
              <mat-option value="MTSO Canaima">MTSO Canaima</mat-option>
              <mat-option value="MTSO Colgate">MTSO Colgate</mat-option>
              <mat-option value="MTSO Maturín">MTSO Maturín </mat-option>
              <mat-option value="MTSO Maracay">MTSO Maracay</mat-option>
              <mat-option value="MTSO Valencia">MTSO Valencia</mat-option>
              <mat-option value="MTSO Barquisimeto"
                >MTSO Barquisimeto</mat-option
              >
              <mat-option value="MTSO Barinas">MTSO Barinas</mat-option>
              <mat-option value="MTSO San Cristóbal"
                >MTSO San Cristóbal</mat-option
              >
              <mat-option value="MTSO Maracaibo">MTSO Maracaibo</mat-option>
              <mat-option value="MTSO Puerto Ordaz"
                >MTSO Puerto Ordaz</mat-option
              >
              <mat-option value="MTSO Puerto la Cruz"
                >MTSO Puerto la Cruz</mat-option
              >
              <mat-option value="RadioBase">RadioBase</mat-option>
              <mat-option value="AGROINDUSTRIAL DIESEL BARINAS"
                >AGROINDUSTRIAL DIESEL BARINAS</mat-option
              >
              <mat-option value="COMBUSTIBLES VALERA, C.A."
                >COMBUSTIBLES VALERA, C.A.</mat-option
              >
              <mat-option value="DEPOSITO BERMUDEZ, C.A."
                >DEPOSITO BERMUDEZ, C.A.</mat-option
              >
              <mat-option value="DEPÓSITO SAN JUAN DE LOS CAYOS"
                >DEPÓSITO SAN JUAN DE LOS CAYOS</mat-option
              >
              <mat-option value="DIST.DE COMBUSTIBLES FORTES Y HERNANDEZ"
                >DIST.DE COMBUSTIBLES FORTES Y HERNANDEZ</mat-option
              >
              <mat-option value="DISTRIBUCION CEDEÑO, C.A."
                >DISTRIBUCION CEDEÑO, C.A.</mat-option
              >
              <mat-option value="DIST. OASIS C.A.">DIST. OASIS C.A.</mat-option>
              <mat-option value="DIST. ALBA">DIST. ALBA</mat-option>
              <mat-option value="DIST. A-M C.A.">DIST. A-M C.A.</mat-option>
              <mat-option value="DIST. DE COMBUSTIBLE EL CARMEN, S.R.L."
                >DIST. DE COMBUSTIBLE EL CARMEN, S.R.L.</mat-option
              >
              <mat-option value="DIST. DE COMBUSTIBLE EL VALLE"
                >DIST. DE COMBUSTIBLE EL VALLE</mat-option
              >
              <mat-option value="DIST. DE COMBUSTIBLE PLAYA GRANDE"
                >DIST. DE COMBUSTIBLE PLAYA GRANDE</mat-option
              >
              <mat-option value="DIST. DE PRODUCTOS"
                >DIST. DE PRODUCTOS</mat-option
              >
              <mat-option value="DIST. MELPAS C.A."
                >DIST. MELPAS C.A.</mat-option
              >
              <mat-option value="DIST. MERIDIANO C.A."
                >DIST. MERIDIANO C.A.</mat-option
              >
              <mat-option value="DIST. TRADICOM">DIST. TRADICOM</mat-option>
              <mat-option value="DIST. WANA, C.A.">DIST. WANA, C.A.</mat-option>
              <mat-option value="ENERGY LIDER, C.A."
                >ENERGY LIDER, C.A.</mat-option
              >
              <mat-option value="LA FUENTE DEL KEROSENE, C.A."
                >LA FUENTE DEL KEROSENE, C.A.</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Cantidad litros</mat-label>
            <input type="number" matInput [(ngModel)]="liters" />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Comentario</mat-label>
            <input type="text" matInput />
          </mat-form-field>
        </mat-card-content>

        <div style="text-align: center">
          <ion-button (click)="search()"> Buscar </ion-button>
          <ion-button (click)="download()"> Descargar </ion-button>
          <ion-button (click)="demo()"> Agregar </ion-button>
        </div>
      </mat-card>
    </div>-->
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

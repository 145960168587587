<mat-toolbar style="background-color: transparent">
  <span class="list-title">Lista de Usuarios</span>
  <span class="spacer"></span>
  <button mat-mini-fab class="add-button" (click)="addUser()">
    <ion-icon name="add-outline" class="add-icon"></ion-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-list style="height: 78vh; overflow: auto">
    <mat-list-item *ngFor="let user of users">
      <div mat-list-icon class="avatar-container">
        <ngx-avatar
          name="{{ user.username }}"
          size="50"
          textSizeRatio="3"
        ></ngx-avatar>
      </div>
      <div class="item-content">
        <div>
          <h5 mat-line>{{ user.username }}</h5>
          <h5 mat-line>{{ user.email }}</h5>
        </div>
        <div>
          <h5>{{ user.company.name }}</h5>
        </div>
        <div>
          <h5>{{ user.role }}</h5>
        </div>
      </div>
      <!-- <span class="spacer"></span> -->
      <button (click)="updateUser(user)" mat-button>
        <ion-icon name="create-outline"></ion-icon>
      </button>
      <button (click)="deleteUser(user)" mat-button>
        <ion-icon name="trash-outline"></ion-icon>
      </button>
      <mat-divider style="width: 100%"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-card>

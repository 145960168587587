import { Component, OnInit } from '@angular/core';
import {
  FormGroupDirective,
  FormBuilder,
  FormGroup,
  FormControl,
  NgForm,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateConfigService } from '../../services/translate-config/translate-config.service';
import { AuthService } from '../../services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { AlertController } from '@ionic/angular';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });

  forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  isSubmitted = false;
  submitError = false;

  // These must have custom validations for response of API...
  matcher = new MyErrorStateMatcher();

  // login var
  login = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private translate: TranslateConfigService,
    private snackBar: MatSnackBar,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.onChanges();

    // if (this.authService.isLoggedIn()) {
    //   this.router.navigate(['/dashboard/home']);
    // }
  }

  onChanges(): void {
    // Error server message dissappear when an input changes
    this.loginForm.valueChanges.subscribe((val) => {
      this.submitError = false;
    });
  }

  resetLoginForm(): void {
    this.login = !this.login;
    this.loginForm.reset();
  }

  resetForgotPasswordForm(): void {
    this.login = !this.login;
    this.forgotPasswordForm.reset();
  }

  async onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    localStorage.clear();
    const { email, password } = this.loginForm.value;

    let response = await this.authService.login(email.toLowerCase(), password);

    if (response.status >= 200 && response.status < 400) {
      if (environment.production) {
        window.location.href =
          //response.data.url + '/loading/' + response.data.token;
          window.location.href = environment.loadingUrl + response.data.token;
      } else {
        window.location.href = environment.loadingUrl + response.data.token;
      }
    } else {
      this.snackBar.open(
        this.translate.getTranslation('LOGIN.loginError'),
        'Error',
        {
          duration: 2000
        }
      );
      this.submitError = true;
    }
  }

  async onSubmitForgotPassword() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const { email } = this.forgotPasswordForm.value;

    let response = await this.authService.forgotPassword(email.toLowerCase());
    console.log('forgot password: ', response);

    if (response.status >= 200 && response.status < 400) {
      this.snackBar.open(
        'Hemos enviado las instrucciones para el cambio de contraseña al correo electrónico suministrado',
        '✅',
        {
          duration: 10000,
          horizontalPosition: 'right',
          verticalPosition: 'top'
        }
      );
    }else{
      this.snackBar.open(
        'El correo electrónico suministrado no existe',
        '❌',
        {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'top'
        }
      );
    }
  }
}

<div class="page-content" *ngIf="!loading; else matSpinner">
  <!-- map section -->
  <mat-card class="map-card">
    <div class="map">
      <agm-map [latitude]="mapLat" [longitude]="mapLng" [zoom]="mapZoom">
        <div *ngFor="let system of mapSystems">
          <agm-marker
            [latitude]="system.attributes.latitude"
            [longitude]="system.attributes.longitude"
            [iconUrl]="
              './assets/icon/home/' +
              systemsService.getSystemStatusColor2(system.attributes.status) +
              '-marker.svg'
            "
          >
            <agm-info-window>
              <!-- status -->
              <button
                [routerLink]="
                  systemsService.getSystemRoute(
                    system.attributes.equipmentId,
                    system.id,
                    summaryPage
                  )
                "
              >
                <ion-icon
                  [src]="
                    system?.attributes?.status == null
                      ? 'assets/icon/common/ellipse-off.svg'
                      : system?.attributes?.status === '00000'
                      ? 'assets/icon/common/ellipse-off.svg'
                      : 'assets/icon/common/ellipse-on.svg'
                  "
                  class="thumbnail"
                ></ion-icon>
                <label style="color: black">
                  {{
                    system?.attributes?.status == null
                      ? 'Desconectado'
                      : system?.attributes?.status === '00000'
                      ? 'Desconectado'
                      : 'Conectado'
                  }}
                </label>
              </button>

              <!-- notification -->
              <button
                [routerLink]="['/dashboard/notifications']"
                [state]="{ id: system.id }"
              >
                <ion-icon
                  class="thumbnail"
                  name="notifications"
                  [ngClass]="
                    system?.attributes?.status === '10000'
                      ? 'green-icon'
                      : system?.attributes?.status === '10010'
                      ? 'red-icon'
                      : system?.attributes?.status === '10100'
                      ? 'yellow-icon'
                      : system?.attributes?.status === '11000'
                      ? 'blue-icon'
                      : 'green-icon'
                  "
                >
                </ion-icon>
                <label style="color: black">
                  {{
                    system?.attributes?.status == null
                      ? 'Ok'
                      : system?.attributes?.status === '10010'
                      ? 'Alarma'
                      : system?.attributes?.status === '10100'
                      ? 'Alerta'
                      : system?.attributes?.status === '11000'
                      ? 'Notificación'
                      : 'Sin Notificaciones'
                  }}
                </label>
              </button>
              <strong
                style="color: black; font-weight: bold"
                [routerLink]="
                  systemsService.getSystemRoute(
                    system.attributes.equipmentId,
                    system.id,
                    summaryPage
                  )
                "
                >{{ system.attributes.name }}</strong
              >
            </agm-info-window>
          </agm-marker>
        </div>
      </agm-map>
    </div>
  </mat-card>
  <!-- total section -->
  <mat-card class="total-section">
    <mat-card
      class="total-section-card cursor-pointer"
      (click)="filterSites('')"
    >
      <mat-card-header>
        <ion-icon mat-card-avatar src="assets/icon/home/map.svg"></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.totalSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="total-sites-color">
          <p>{{ sitesValues.total }}</p>
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- connected sites -->
    <mat-card
      class="total-section-card cursor-pointer"
      (click)="filterSites('connected')"
    >
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/green-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.connectedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="connected-sites-color">
          {{ sitesValues.connected }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- disconnected sites -->
    <mat-card
      class="total-section-card cursor-pointer"
      (click)="filterSites('disconnected')"
    >
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/black-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.disconnectedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="disconnected-sites-color">
          {{ sitesValues.total - sitesValues.connected }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- sites with notifications -->
    <mat-card
      class="total-section-card cursor-pointer"
      (click)="filterSites('notification')"
    >
      <mat-card-header>
        <ion-icon
          mat-card-avatar
          src="assets/icon/home/blue-marker.svg"
        ></ion-icon>
        <mat-card-subtitle>
          {{ 'HOME.map.notificatedSites' | translate }}
        </mat-card-subtitle>
        <mat-card-title class="notification-sites-color">
          {{ sitesValues.notification }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </mat-card>

  <div class="system-section">
    <div class="systems-subsection" *ngIf="!loadingData; else matSpinner2">
      <mat-toolbar>
        <span class="spacer mobile"></span>
        <button
          mat-icon-button
          (click)="openFilterSheet()"
          class="filter-button mobile"
        >
          <ion-icon class="toolbar-icon" name="funnel-outline"></ion-icon>
        </button>
        <ion-searchbar
          placeholder="{{ 'HOME.filters.search' | translate }}"
          (ionInput)="this.searchTerm = $event.srcElement.value"
        ></ion-searchbar>
        <button
          mat-raised-button
          (click)="searchSystemSites()"
          class="filter-button-2"
          [disabled]="this.searchTerm === ''"
        >
          Buscar
        </button>
        <button
          mat-raised-button
          class="filter-button-2"
          style="width: 120px; margin-right: 24px"
          (click)="downloadCSVSites()"
        >
          Descargar
        </button>
        <button
          mat-raised-button
          (click)="watchList()"
          class="filter-button-2"
          style="text-transform: capitalize"
        >
          {{ 'HOME.views.' + watch | translate }}
        </button>
        <button
          mat-raised-button
          (click)="openFilterSheet()"
          class="filter-button-2"
        >
          Filtro
        </button>
      </mat-toolbar>
      <br />
      <mat-paginator
        style="width: 100%; border-radius: 10px"
        #paginator
        [length]="systemListLength"
        [pageSize]="pageSize"
        (page)="getSystemsByPaginator($event)"
        showFirstLastButtons
      >
      </mat-paginator>

      <div class="subsection-scroll">
        <ng-container *ngIf="watch === 'mosaic'">
          <mat-card
            mat-button
            *ngFor="let system of systemList"
            [routerLink]="
              systemsService.getSystemRoute(
                system.equipmentId,
                system.id,
                summaryPage
              )
            "
            class="system-card"
          >
            <div class="system-primary">
              <img
                *ngIf="
                  systemsService.getSystemFirstImg(system) != null;
                  else krillImage
                "
                [src]="systemsService.getSystemFirstImg(system)"
                alt=""
              />
              <ng-template #krillImage>
                <img
                  src="../../../../assets/logos/LOGOTIPO_KRILL_system.png"
                  alt=""
                  style="padding: 10px"
                />
              </ng-template>
              <h2>
                {{ system.name }}
              </h2>
            </div>
            <div class="system-content">
              <div>
                <ion-icon
                  name="ellipse"
                  [class]="
                    systemsService.getSystemStatusColor(system.status) +
                    '-icon status-icon'
                  "
                ></ion-icon>
                <h4>
                  {{
                    'SYSTEM.status.' +
                      systemsService.getSystemStatus(system.status) | translate
                  }}
                </h4>
              </div>
              <div>
                <img src="../assets/icon/summary/tank.svg" alt="" />
                <p style="width: fit-content">
                  {{ system.lastRecord.hfLFuelTotal | number : '1.0-0' }}
                  litros
                </p>
              </div>
              <div>
                <img src="../assets/icon/summary/tank.svg" alt="" />
                <p>
                  {{ system.lastRecord.hfPctFuelTotal | number : '1.0-1' }}
                  %
                </p>
              </div>
            </div>
          </mat-card>
        </ng-container>

        <table
          mat-table
          [dataSource]="systemList"
          style="width: 100%"
          *ngIf="watch === 'list'"
        >
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.id' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              {{ element.id }}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.name' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.location' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              {{ element.state ? element.state : '-' }}
            </td>
          </ng-container>

          <!-- System Column -->
          <ng-container matColumnDef="system">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.system' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              {{ element.type ? element.type.name : '-' }}
            </td>
          </ng-container>

          <!-- Connected Column -->
          <ng-container matColumnDef="connected">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.connected' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              <span *ngIf="element?.status !== '00000'"> Conectado </span>
              <span *ngIf="element?.status === '00000'"> Desconectado </span>
            </td>
          </ng-container>

          <!-- Last Connection Column -->
          <ng-container matColumnDef="lastConnection">
            <th mat-header-cell *matHeaderCellDef style="text-align: left">
              {{ 'HOME.views.lastConnection' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left">
              <span *ngIf="element?.lastRecord?.time">
                {{
                  element.lastRecord.time
                    | date : 'dd-LL hh:mm a' : element.timeZone
                }}
              </span>
              <span *ngIf="!element.lastRecord?.time"> - </span>
            </td>
          </ng-container>

          <!-- Notifications Column -->
          <ng-container matColumnDef="notifications">
            <th mat-header-cell *matHeaderCellDef>
              <ion-icon class="icon-load" name="notifications"></ion-icon>
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element?.status === '00000'"> - </span>
              <span *ngIf="element?.status === '10000'"> OK </span>
              <span
                *ngIf="
                  element?.status !== '00000' && element?.status !== '10000'
                "
              >
                {{
                  'SYSTEM.status.' +
                    systemsService.getSystemStatus(element?.status) | translate
                }}
              </span>
            </td>
          </ng-container>

          <!-- Tank Column 1 -->
          <ng-container matColumnDef="tank_1">
            <th mat-header-cell *matHeaderCellDef>
              <img
                width="36"
                height="36"
                src="../assets/icon/summary/tank.svg"
                alt=""
              />
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.lastRecord.hfLFuelTotal
                  ? (element.lastRecord.hfLFuelTotal | number : '1.0-0') +
                    ' litros'
                  : ' - '
              }}
            </td>
          </ng-container>

          <!-- Tank Column 2 -->
          <ng-container matColumnDef="tank_2">
            <th mat-header-cell *matHeaderCellDef>
              <img
                width="36"
                height="36"
                src="../assets/icon/summary/tank.svg"
                alt=""
              />
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.lastRecord.hfPctFuelTotal
                  ? (element.lastRecord.hfPctFuelTotal | number : '1.0-1') +
                    ' %'
                  : ' - '
              }}
            </td>
          </ng-container>

          <!-- Grid Column -->
          <ng-container matColumnDef="grid">
            <th mat-header-cell *matHeaderCellDef>% Int</th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastRecord.hfPctFuelInt | number : '1.0-1' }} %
            </td>
          </ng-container>

          <!-- Net Column -->
          <ng-container matColumnDef="net">
            <th mat-header-cell *matHeaderCellDef>% Ext</th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastRecord.hfPctFuelExt | number : '1.0-1' }} %
            </td>
          </ng-container>

          <!-- krill Column -->
          <ng-container matColumnDef="krill">
            <th mat-header-cell *matHeaderCellDef>
              <img
                width="36"
                height="36"
                src="../../assets/logos/favicon.png"
                alt=""
              />
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.lastRecord.tAutonomyGen
                  ? (element.lastRecord.tAutonomyGen | number : '1.0-2') +
                    ' horas'
                  : ' - '
              }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [routerLink]="
              systemsService.getSystemRoute(
                row.equipmentId,
                row.id,
                summaryPage
              )
            "
          ></tr>
        </table>
        <mat-paginator
          style="width: 100%; border-radius: 10px"
          #paginator
          [length]="systemListLength"
          [pageSize]="pageSize"
          (page)="getSystemsByPaginator($event)"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
    <ng-template #matSpinner2>
      <mat-spinner></mat-spinner>
    </ng-template>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

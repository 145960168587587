import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as moment from 'moment-timezone';
import ChartDatalabels from 'chartjs-plugin-datalabels';
import { ActivatedRoute, Router } from '@angular/router';

import { SystemsService } from 'src/app/services/systems/systems.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
@Component({
  selector: 'system-tabs',
  templateUrl: './system-tabs.component.html',
  styleUrls: ['./system-tabs.component.scss']
})
export class SystemTabsComponent implements OnInit {
  @ViewChild('linearChart') canvas: ElementRef;

  public chartPlugins = [ChartDatalabels];

  @Input() tabs: string[];
  @Input() systemData: any;
  @Input() systemIndicatorsData: any;

  // system
  systemId: string;
  systemCurrentDate: string;
  systemCurrentHour: string;
  lastConnection: string;
  status = false;
  spinnerRemasterizedDiameter = 135;
  sidenavOpened: boolean;
  pageLoading = true;
  systemImgs: [];
  imageIndicator: string;

  clickPanel = false;

  indicators: any[] = [
    {
      name: 'autonomy',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  // dates
  defaultDate = '';
  startDate = '';
  endDate = '';

  // tabs
  tabLoading = false;
  actualTabIndex = 1;

  // === Charts ===
  barChartLoading = false;

  // TANK
  tab1ChartData: ChartDataSets[] = [];
  tab1ChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      display: false,
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 8,
            beginAtZero: true
          },
          gridLines: {
            drawOnChartArea: false
          },
          stacked: true
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          position: 'left',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Litros (L)'
          }
          // stacked: true
        }
      ]
    },
    plugins: {
      datalabels: {
        // display: true,
        // anchor: 'end',
        align: 'top',
        formatter: Math.round,
        font: {
          weight: 'bold'
        },
        color: '#333333',
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        borderRadius: 5
      }
    }
  };
  tab1ChartColors: Color[] = [];

  // HISTORICAL
  ELEMENT_DATA: any[] = [
    {
      type: 'Entrada',
      date: '12/09',
      hour: '3:32 pm',
      reference: 'ROF2468',
      litres: '+250'
    },
    {
      type: 'Entrada',
      date: '12/09',
      hour: '7:00 pm',
      reference: '',
      litres: '+230'
    },
    {
      type: 'Salida',
      date: '23/09',
      hour: '2:34 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      date: '12/09',
      hour: '3:32 pm',
      reference: '',
      litres: '-65'
    },
    {
      type: 'Salida',
      date: '15/09',
      hour: '10:10 am',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      date: '23/09',
      hour: '2:34 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      date: '12/09',
      hour: '3:32 pm',
      reference: '',
      litres: '-15'
    },
    {
      type: 'Salida',
      date: '15/09',
      hour: '10:10 pm',
      reference: '',
      litres: '-65'
    }
  ];
  tab2ChartData: ChartDataSets[] = [];
  tab2ChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: {
        usePointStyle: true,
        filter(legendItem, chartData) {
          // if (legendItem.datasetIndex !== 0) {
          //   return true;
          // }
          return true;
        }
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 3
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Litros'
          }
        }
      ]
    }
  };
  tab2ChartColors: Color[] = [];

  // PROJECTION
  tab3ChartData: ChartDataSets[] = [];
  tab3ChartOptions: ChartOptions = {
    
  };
  tab3ChartColors: Color[] = [
    {
      borderColor: '#8484a4',
      backgroundColor: 'transparent'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'transparent'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'transparent'
    }
  ];

  // ALARM
  notificationsBySystem: any[] = [];

  // ENERGY
  tab5ChartData: ChartDataSets[] = [];
  tab5ChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: {
        usePointStyle: true,
        filter(legendItem, chartData) {
          // if (legendItem.datasetIndex !== 0) {
          //   return true;
          // }
          return true;
        }
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 3
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Tensión (V)'
          }
        }
      ]
    }
  };
  tab5ChartColors: Color[] = [
    // {
    //   borderColor: '#8484a4',
    //   backgroundColor: 'transparent'
    // },
    // {
    //   borderColor: 'rgba(255,0,0,0.7)',
    //   backgroundColor: 'transparent'
    // },
    // {
    //   borderColor: 'rgba(255,0,0,0.7)',
    //   backgroundColor: 'transparent'
    // }
  ];

  // COMMON
  tabsChartLabels: Label[] = [];
  tab2ChartLabels: Label[] = [];
  tab5ChartLabels: Label[] = [];
  

  tabsChartLegend = true;
  chartType: ChartType = 'bar';

  summaryPercentageCardElectricalNet1 = 1;
  summaryPercentageCardElectricalNet2 = 1;

  // gradients
  blueGradient: CanvasGradient;
  orangeGradient: CanvasGradient;

  // notifications
  notifications;
  notificationList10;
  systemNotifications = 0;
  systemAlerts = 0;
  systemAlarms = 0;
  notificationListLength = 0;

  // selectedVisualizations = ['true'];
  selectedVisualization = ['true'];

  errorDate = false;

  // visualization filters
  visualizationTypes = [
    {
      value: 'true',
      name: 'notRead'
    },
    {
      value: 'false',
      name: 'read'
    }
  ];

  constructor(
    public systemsService: SystemsService,
    private translate: TranslateConfigService,
    public sidenavService: SidenavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService
  ) {}

  async ngOnInit() {
    // console.log("systemIndicatorsData: ", this.systemIndicatorsData)
    // console.log("systemData: ", this.systemData)
    // console.log("tabs: ", this.tabs)
    // this.tabs = [...this.tabs, 'energy']
    // console.log("tabs: ", this.tabs)

    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    this.systemDataSetup();

    // Set spinner Diameter
    this.spinnerResizes();
    

    // load actual tab
    await this.actualTab();

    // create orange gradient
    this.orangeGradient = this.canvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    this.orangeGradient.addColorStop(0, '#F6D16F');
    this.orangeGradient.addColorStop(1, '#F2987B');
    // create blue gradient
    this.blueGradient = this.canvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    this.blueGradient.addColorStop(0, '#6F9DF6');
    this.blueGradient.addColorStop(1, '#4D6BA6');

    // load chart gradientss
    this.loadColors();

    // this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
    //   this.systemData.id
    // );

    this.pageLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
    this.maxLimitChartsChange();
  }

  async tabChanged(tabChangeEvent: MatTabChangeEvent): Promise<void> {
    this.tabLoading = true;
    this.startDate = '';
    this.endDate = '';
    this.actualTabIndex = tabChangeEvent.index;
    const yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    switch (tabChangeEvent.tab.textLabel) {
      case 'tank':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getTab1Data(1, yesterdayDate, this.defaultDate);
        break;
      case 'historical':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getTab2Data(2, yesterdayDate, this.defaultDate);
        break;
      case 'projection':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getTab3Data(3, yesterdayDate, this.defaultDate);
        break;
      case 'alarm':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getTab4Data(4, yesterdayDate, this.defaultDate);
        break;
      case 'energy':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getTab5Data(5, yesterdayDate, this.defaultDate);
        break;
    }
    this.tabLoading = false;
  }

  async getTab1Data(loadTab: number, startDate: string, endDate: string) {
    let dynamicChartData = await this.systemsService.getDynamicChart(
      this.systemData.id,
      'tank',
      '2023-10-10',
      '2023-10-10',
      0
    );
    this.chartType = 'bar';
    console.log('dynamicChartData: ', dynamicChartData);
    let varMaxlTab1 =
      Math.max(...dynamicChartData.actualData.expectedValue) * 1.05;

    this.tab1ChartData = [
      {
        data: dynamicChartData.actualData.actualValue,
        label: 'Litros',
        yAxisID: 'first-y-axis',
        type: 'bar',
        borderColor: '#D9D9D9',
        backgroundColor: this.orangeGradient,
        pointBackgroundColor: '#D9D9D9',
        hoverBackgroundColor: this.orangeGradient,
        pointHoverBorderColor: this.orangeGradient,
        order: 1,
        barThickness: 12,
        datalabels: {
          formatter(value, context) {
            var index = context.dataIndex;
            return `${Math.round(
              dynamicChartData.actualData.porcentValue[index]
            )} %`;
          },
          align: 'end'
        }
      },
      {
        data: dynamicChartData.actualData.expectedValue,
        label: 'Capacidad',
        yAxisID: 'first-y-axis',
        type: 'bar',
        borderColor: '#001F9D',
        backgroundColor: this.blueGradient,
        pointBackgroundColor: '#001F9D',
        hoverBackgroundColor: this.blueGradient,
        pointHoverBorderColor: this.blueGradient,
        order: 2,
        datalabels: {
          labels: {
            // we have to set null to not show datalabels
            title: null
          }
        }
      }
    ];

    this.tabsChartLabels = dynamicChartData.actualData.labels;
  }

  async getTab2Data(
    generatorSetTab: number,
    startDate: string,
    endDate: string
  ) {
    let dynamicChartData = await this.systemsService.getDynamicChart(
      this.systemData.id,
      'hist',
      startDate,
      endDate,
      0
    );

    
    // console.log("generatorSetChartsData: ", generatorSetChartsData)

    // set chart type
    this.chartType = 'line';

    const upperLimit =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.upper
      ];
    const lowerLimit =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.lower
      ];

    // console.log('dynamicChartData: ', dynamicChartData.actualData);
    let chartLine = dynamicChartData.actualData;
    let colorsRgba = [
      '#447b54',
      '#acb5dc',
      '#fbac26',
      '#f4742c',
      '#acbca4'
    ];

    let colorsCount = 0;
    this.tab2ChartData = [];
    for (let key in chartLine) {
      if (key !== 'labels') {
        this.tab2ChartData.push({
          data: chartLine[key]['data'],
          label: key,
          fill: false,
          type: 'line',
          yAxisID: 'first-y-axis',
          pointBackgroundColor: colorsRgba[colorsCount],
          backgroundColor: 'transparent',
          borderColor: colorsRgba[colorsCount]
        });
        colorsCount++;
      }
    }

    this.tab2ChartLabels = dynamicChartData.actualData.labels;

    this.tabsChartLabels = dynamicChartData.actualData.labels.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });

    // min&max yAxe
    this.tab2ChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.varMax
      ];
    this.tab2ChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.varMin
      ];

    // title yAxe
    this.tab2ChartOptions.scales.yAxes[0].scaleLabel.labelString =
      this.systemData.attributes.structure.system.eg.label;
  }

  async getTab3Data(batteriesTab: number, startDate: string, endDate: string) {
    let electricalChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    this.tab3ChartOptions = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          usePointStyle: true,
          filter: function (legendItem, chartData) {
            if (legendItem.datasetIndex != 0) {
              return false;
            } else {
              return true;
            }
          }
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 5
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString: this.systemData.attributes.structure.system.pg.label
            }
          }
        ]
      }
    };

    let upperLimit = new Array(
      electricalChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.pg.limits.upper
      ]
    );
    let lowerLimit = new Array(
      electricalChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.pg.limits.lower
      ]
    );

    // set chart type
    this.chartType = 'line';
    

    this.tab3ChartData = [
      {
        data: electricalChartsData.lineChartData[0].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + electricalChartsData.lineChartData[0].label
        ),
        pointBackgroundColor: 'rgba(26,46,127,0.7)'
      },
      {
        data: upperLimit,
        borderDash:[10,10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      },
      {
        data: lowerLimit,
        borderDash:[10,10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      }
    ];

    //Min and max limit
    this.tab3ChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.pg.limits.varMin
      ];
    this.tab3ChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.pg.limits.varMax
      ];

    this.tabsChartLabels = electricalChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
    
  }

  async getTab4Data(solarTab: number, startDate: string, endDate: string) {
    this.reloadNotification(1);
  }

  async getTab5Data(
    generatorSetTab: number,
    startDate: string,
    endDate: string
  ) {
    let dynamicChartData = await this.systemsService.getDynamicChart(
      this.systemData.id,
      'energy',
      startDate,
      endDate,
      0
    );

    // console.log("generatorSetChartsData: ", generatorSetChartsData)

    // set chart type
    this.chartType = 'line';

    const upperLimit =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.upper
      ];
    const lowerLimit =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.lower
      ];

    // console.log('dynamicChartData: ', dynamicChartData.actualData);
    let chartLine = dynamicChartData.actualData;
    let colorsRgba = [
      '#447b54',
      '#acb5dc',
      '#fbac26',
      '#f4742c',
      '#acbca4'
    ];

    let colorsCount = 0;
    this.tab5ChartData = [];
    for (let key in chartLine) {
      if (key !== 'labels') {
        this.tab5ChartData.push({
          data: chartLine[key]['data'],
          label: key,
          fill: false,
          type: 'line',
          yAxisID: 'first-y-axis',
          pointBackgroundColor: colorsRgba[colorsCount],
          backgroundColor: 'transparent',
          borderColor: colorsRgba[colorsCount]
        });
        colorsCount++;
      }
    }
    console.log('tab5ChartData: ', this.tab5ChartData)

    this.tab5ChartLabels = dynamicChartData.actualData.labels;

    // min&max yAxe
    this.tab5ChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.varMax
      ];
    this.tab5ChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.eg.limits.varMin
      ];

    // title yAxe
    this.tab5ChartOptions.scales.yAxes[0].scaleLabel.labelString =
      this.systemData.attributes.structure.system.eg.label;
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  public maxLimitChartsChange() {
    if (window.innerWidth > 800) {
      this.tab1ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.tab1ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.tab2ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 6;
      this.tab2ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 7;

      this.tab3ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.tab3ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 6;

      this.tab5ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 6;
      this.tab5ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 7;
    } else if (window.innerWidth <= 800) {
      this.tab1ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.tab1ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.tab2ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.tab2ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.tab3ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.tab3ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.tab5ChartOptions.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.tab5ChartOptions.scales.yAxes[0].ticks.maxTicksLimit = 5;
    }
  }

  async changeChartsInterval() {
    const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
    const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

    moment(startDate).isAfter(endDate);
    if (moment(startDate).isAfter(endDate)) {
      this.startDate = '';
      this.endDate = '';
      return;
    }

    const tab = localStorage.getItem('tab');

    switch (tab) {
      case 'tank':
        await this.getTab1Data(this.actualTabIndex, startDate, endDate);
        break;
      case 'historical':
        await this.getTab2Data(this.actualTabIndex, startDate, endDate);
        break;
      case 'projection':
        await this.getTab3Data(this.actualTabIndex, startDate, endDate);
        break;
      case 'alarm':
        await this.getTab4Data(this.actualTabIndex, startDate, endDate);
        break;
      case 'energy':
        await this.getTab5Data(this.actualTabIndex, startDate, endDate);
        break;
    }
  }

  async systemDataSetup() {
    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.attributes.timeZone)
      .format('yyyy-MM-DD');
    this.systemId = this.activatedRoute.snapshot.paramMap.get('id');
  }

  async actualTab() {
    const yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    if (localStorage.getItem('tab') != null) {
      const tab = localStorage.getItem('tab');
      if (localStorage.getItem('tab') == 'tank') {
        this.actualTabIndex = this.tabs.indexOf(tab) + 1;
        // this.actualTabIndex = 1;
        await this.getTab1Data(1, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'historical') {
        this.actualTabIndex = this.tabs.indexOf(tab) + 1;
        // this.actualTabIndex = 2;
        await this.getTab2Data(2, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'projection') {
        this.actualTabIndex = this.tabs.indexOf(tab) + 1;
        // this.actualTabIndex = 3;
        await this.getTab3Data(3, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'alarm') {
        this.actualTabIndex = this.tabs.indexOf(tab) + 1;
        // this.actualTabIndex = 4;
        await this.getTab4Data(4, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'energy') {
        this.actualTabIndex = this.tabs.indexOf(tab) + 1;
        // this.actualTabIndex = 5;
        await this.getTab5Data(5, yesterdayDate, this.defaultDate);
      }
    } else {
      this.actualTabIndex = 1;
      await this.getTab1Data(
        this.actualTabIndex,
        yesterdayDate,
        this.defaultDate
      );
    }
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  /**
   * Return the round value of number.
   * This function is useful for the html tags
   */
  public round(number: number) {
    return Math.round(number);
  }

  public String(value) {
    if (value == undefined) {
      return 0;
    }
    const String = value.toString();
    return String.length;
  }

  loadColors() {
    let tab = localStorage.getItem('tab');
    if (tab === 'tank') {
      // set gradient color
      this.tab1ChartData[0].backgroundColor = this.orangeGradient;
      this.tab1ChartData[0].borderColor = '#D9D9D9';
      this.tab1ChartData[0].pointBackgroundColor = '#D9D9D9';
      this.tab1ChartData[0].hoverBackgroundColor = this.orangeGradient;

      this.tab1ChartData[1].backgroundColor = this.blueGradient;
      this.tab1ChartData[1].borderColor = '#001F9D';
      this.tab1ChartData[1].pointBackgroundColor = '#001F9D';
      this.tab1ChartData[1].hoverBackgroundColor = this.blueGradient;
    } else if (tab === 'historical') {
      // set gradient color
    } else if (tab === 'projection') {
    } else if (tab === 'alarm') {
    } else if (tab === 'energy') {
    } else {
      return;
    }
  }

  async reloadNotification(pageIndex: any) {
    //https://server.harvey.energy/notifications/1?userId=1&flagType=[]&systemId=[6]&visibility=true&notificationsVisible=true
    let data = await this.notificationsService.getNotifications(
      pageIndex,
      localStorage.getItem('USER_ID'),
      [],
      [this.systemId],
      this.selectedVisualization[0],
      this.startDate == ''
        ? undefined
        : moment(this.startDate).format('YYYY-MM-D'),
      this.endDate == '' ? undefined : moment(this.endDate).format('YYYY-MM-D')
    );
    this.notificationListLength = data.notifications.length;
    console.log('data notification: ', data);

    // // initial page = 1;
    // this.initialPage = pageIndex;
    // // data
    // this.systemNotifications = data.numberNotification;
    // this.systemAlerts = data.numberAlert;
    // this.systemAlarms = data.numberAlarm;
    // // notification list
    // this.notificationList = data.notifications;
    // // total notifications paginator
    // this.notificationListLength = data.total;

    this.notificationsBySystem = data.notifications.slice(0, 10);

    // contar totales
    data.notifications.forEach((element) => {
      if (element.flagType == 1) {
        this.systemNotifications++;
      }
      if (element.flagType == 2) {
        this.systemAlerts++;
      }
      if (element.flagType == 3) {
        this.systemAlarms++;
      }
    });
  }

  async filterNotifications() {
    const startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
    const endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

    moment(startDate).isAfter(endDate);
    if (moment(startDate).isAfter(endDate)) {
      this.startDate = '';
      this.endDate = '';
      return;
    }

    this.reloadNotification(1);
  }
}

<mat-tab-group
  (selectedTabChange)="tabChanged($event)"
  [selectedIndex]="actualTabIndex"
  dynamicHeight
  mat-align-tabs="center"
  class="mat-tab-group-system"
>
  <!-- RESUME -->
  <mat-tab label="Resumen" disabled>
    <ng-template mat-tab-label>
      <a
        [routerLink]="
          systemsService.getSystemRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            true
          )
        "
        style="text-decoration: none !important"
      >
        <span class="mat-tab-label">
          <img
            style="margin-right: 8px"
            src="../../../assets/logos/favicon.png"
            alt=""
            width="25px"
          />
          {{ 'SYSTEM.resume' | translate }}
        </span>
      </a>
    </ng-template>
  </mat-tab>

  <!-- TABS -->
  <mat-tab *ngFor="let tab of tabs" [label]="tab">
    <ng-template mat-tab-label>
      <span *ngIf="tab == 'tank'">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/summary/tank.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'historical'">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/summary/historical.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'projection'">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/electrical-tower-gray.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'alarm'">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/summary/notification.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'energy'">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/summary/Icons_Energia GRAY.svg"
        ></ion-icon>
      </span>

      {{ 'SYSTEM.' + tab | translate }}
    </ng-template>

    <!-- TANK TAB -->
    <div class="tab" *ngIf="tab == 'tank' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/summary/tank.svg"
          ></ion-icon>

          <div>
            <h1> Tanques </h1>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.tb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.tb.limits.varMax
              ]
            "
            [textValue]="
              systemData.records[0][
                systemData.attributes.structure.system.tb.var
              ] | number : '1.0-2'
            "
            imgValue="assets/icon/home/spinner-gasoline-can.svg"
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.tb.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.tc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.tc.limits.varMax
              ]
            "
            [textValue]="
              systemData.records[0][
                systemData.attributes.structure.system.tc.var
              ] | number : '1.0-2'
            "
            imgValue="assets/icon/home/spinner-gasoline-can.svg"
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.tc.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.td.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.td
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.td.unit }}
                {{ systemData.attributes.structure.system.td.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.te.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.te
                  ) | number : '1.0-0'
                }}
                {{ systemData.attributes.structure.system.te.unit }}
                {{ systemData.attributes.structure.system.te.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.tf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.tf
                  ) | number : '1.0-0'
                }}
                {{ systemData.attributes.structure.system.tf.unit }}
                {{ systemData.attributes.structure.system.tf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>

      <div class="half-tab">
        <div class="dates-container-charts">
          <h4>
            {{ systemData.attributes.structure.system.tg.title }}
          </h4>
        </div>
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            #linearChart
            [labels]="tabsChartLabels"
            [legend]="tabsChartLegend"
            [chartType]="chartType"
            [datasets]="tab1ChartData"
            [options]="tab1ChartOptions"
            [colors]="tab1ChartColors"
            [plugins]="chartPlugins"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="tipContentLoadChart"
            tooltipClass="left chart-tooltip"
            placement="left"
          >
            <ion-icon name="help-circle"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- HISTORICAL TAB -->
    <div class="tab" *ngIf="tab == 'historical' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/summary/historical.svg"
          ></ion-icon>

          <div>
            <h1> Históricos </h1>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.hb.unit"
            [maxSpinnerValue]= 100
            [textValue]="
              systemData.daily[
                systemData.attributes.structure.system.hb.var
              ]
            "
            imgValue="assets/icon/home/spinner-gasoline-can.svg"
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.hb.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.tc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.hc.limits.varMax
              ]
            "
            [textValue]="
              systemData.records[0][
                systemData.attributes.structure.system.hc.var
              ] | number : '1.0-2'
            "
            imgValue="assets/icon/home/spinner-gasoline-can.svg"
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.hc.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.hd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.hd
                  ) | date : 'dd-MM-yyyy'
                }}
                <!-- {{ systemData.attributes.structure.system.hd.unit }} -->
                <!-- {{ systemData.attributes.structure.system.hd.subUnit }} -->
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.he.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.he
                  ) | date : 'dd-MM-yyyy'
                }}
                <!-- {{ systemData.attributes.structure.system.he.unit }}
                {{ systemData.attributes.structure.system.he.subUnit }} -->
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.hf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.hf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.hf.unit }}
                {{ systemData.attributes.structure.system.hf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>

      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="tab2ChartLabels"
            [legend]="tabsChartLegend"
            [chartType]="chartType"
            [datasets]="tab2ChartData"
            [options]="tab2ChartOptions"
            [colors]="tab2ChartColors"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="tipContentLoadChart"
            tooltipClass="left chart-tooltip"
            placement="left"
          >
            <ion-icon name="help-circle"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <!-- PROJECTION TAB -->
    <div class="tab batteries" *ngIf="tab == 'projection' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/electrical-tower-gray.svg"
          ></ion-icon>

          <div>
            <h1> Energía</h1>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.pb.unit"
            [maxSpinnerValue]="100"
            [textValue]="systemData.records[0][
            systemData.attributes.structure.system.pb.var
          ] | number : '1.0-2'"
            imgValue=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.pb.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>
          <krill-spinner-v2
          format="vertical"
          [magnitude]="systemData.attributes.structure.system.pc.unit"
          [maxSpinnerValue]="100"
          [textValue]="systemsService.getSystemSummaryData(
            systemData,
            systemData.attributes.structure.system.pc
          ) | number : '1.0-2'"
          imgValue=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          [descriptionTitle]="systemData.attributes.structure.system.pc.label"
          tooltipClass="top"
          placement="top"
          [description]="false"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <!-- 1 -->
            <mat-card-header
              [ngbTooltip]="tipContent25"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.pd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.pd.var[0]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.pd.var[0]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.pd.var[1]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.pd.var[1]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.pd.var[2]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.pd.var[2]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{ systemData.attributes.structure.system.pd.unit }}
                {{ systemData.attributes.structure.system.pd.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.pe.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.pe
                  ) | number : '1.0-2'
                }}
                {{
                  systemData.attributes.structure.system.pe.unit === 'dateIcon'
                    ? ''
                    : systemData.attributes.structure.system.pe.unit
                }}
                {{ systemData.attributes.structure.system.pe.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.pf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.pf
                  ) | number : '1.0-2'
                }}
                {{
                  systemData.attributes.structure.system.pf.unit === 'dateIcon'
                    ? ''
                    : systemData.attributes.structure.system.pf.unit
                }}
                {{ systemData.attributes.structure.system.pf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="tabsChartLabels"
            [legend]="tabsChartLegend"
            [chartType]="chartType"
            [datasets]="tab3ChartData"
            [options]="tab3ChartOptions"
            [colors]="tab3ChartColors"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="electricalLeftChart"
            tooltipClass="left half-chart-tooltip"
            placement="left"
          >
        
          </div>
        </div>
      </div>
    </div>

    <!-- ALARM TAB -->
    <div class="tab solar" *ngIf="tab == 'alarm' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/summary/notification.svg"
          ></ion-icon>

          <div>
            <h1> Notificaciones </h1>
          </div>
          <button
            mat-icon-button
            [routerLink]="['/dashboard/notifications']"
            [state]="{ id: this.systemId }"
          >
            <ion-icon style="font-size: 20px" name="arrow-redo"> </ion-icon>
          </button>
        </div>
        <div class="summary">
          <div class="percentage-container">
            <mat-card-header
              style="align-items: center"
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle> Estatus red </mat-card-subtitle>
              <mat-card-title>{{
                systemData.records[0].sGridState ? 'Disponible' : 'No Disponible'
              }}</mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>Estatus electrógeno</mat-card-subtitle>
              <mat-card-title>
                {{ systemData.records[0].engineState ? 'Encendido' : 'Apagado' }}
              </mat-card-title>
            </mat-card-header>
          </div>

          <krill-spinner-v2
            format="vertical"
            [maxSpinnerValue]="notificationListLength"
            [textValue]="notificationListLength"
            imgValue=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            descriptionTitle="Notificaciones"
            [ngbTooltip]="tipContent10"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <div class="filter-container">
            <mat-card class="filter-card">
              <mat-card-title>{{
                'HOME.filters.title' | translate
              }}</mat-card-title>
              <mat-accordion>
                <!-- By Visualization -->
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'HOME.filters.visualization.title' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-selection-list
                    [(ngModel)]="selectedVisualization"
                    [multiple]="false"
                  >
                    <mat-list-option
                      *ngFor="let type of visualizationTypes"
                      [value]="type.value"
                    >
                      {{
                        'HOME.filters.visualization.' + type.name | translate
                      }}
                    </mat-list-option>
                  </mat-selection-list>
                </mat-expansion-panel>
              </mat-accordion>
              <!-- <br>
              <div style="text-align: center;">
                <ion-button (click)="filter()">
                  {{'NOTIFICATION.buttons.filter' | translate }}
                </ion-button>
                <ion-button (click)="clean()">
                  {{'NOTIFICATION.buttons.clean' | translate }}
                </ion-button>
              </div> -->
            </mat-card>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="filterNotifications()"
            class="date-button"
            mat-raised-button
            color="primary"
            [disabled]="this.startDate === '' && this.endDate === ''"
          >
            Buscar
          </button>
        </div>
        <div class="linear-chart" style="height: 330px">
          <mat-card class="list-card">
            <mat-accordion>
              <mat-expansion-panel
                [disabled]="clickPanel"
                (click)="clickPanel = false"
                class="matExpansionPanelMobile"
                *ngFor="let notification of notificationsBySystem"
                hideToggle
              >
                <mat-expansion-panel-header
                  class="mat-expansion-panel-header notification"
                >
                  <mat-panel-title>
                    <ion-icon
                      class="zoom-icon status-icon"
                      *ngIf="notification.flagType == 1"
                      src="assets/icon/common/ellipse-notification.svg"
                    >
                    </ion-icon>
                    <ion-icon
                      class="zoom-icon status-icon"
                      *ngIf="notification.flagType == 2"
                      src="assets/icon/common/ellipse-alert.svg"
                    >
                    </ion-icon>
                    <ion-icon
                      class="zoom-icon status-icon"
                      *ngIf="notification.flagType == 3"
                      src="assets/icon/common/ellipse-alarm.svg"
                    >
                    </ion-icon>
                    <!-- notification name-->
                    <div class="notification-name">
                      <p>
                        {{ 'NOTIFICATION.name.name' | translate }}:
                        {{ notification.title }}
                      </p>
                    </div>
                  </mat-panel-title>

                  <mat-panel-description>
                    <!-- notification system name-->
                    <div class="notification-system">
                      <p>
                        {{ 'NOTIFICATION.system.name' | translate }}:
                        {{ notification.sys.name }}
                      </p>
                    </div>
                    <!-- notification date-->
                    <div class="notification-date">
                      <p>
                        {{ 'NOTIFICATION.date' | translate }}:
                        {{
                          notification.createdAt
                            | date
                              : 'yyyy-MM-dd, h:mm a'
                              : notification.sys.timeZone
                        }}
                      </p>
                    </div>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <!-- notification type-->
                <div class="notification-description">
                  <p *ngIf="notification.flagType == 1">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.notification' | translate }}
                  </p>
                  <p *ngIf="notification.flagType == 2">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.alert' | translate }}
                  </p>
                  <p *ngIf="notification.flagType == 3">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.alarm' | translate }}
                  </p>
                </div>

                <!-- notification description-->
                <div class="notification-description">
                  <p>
                    {{ 'NOTIFICATION.description.name' | translate }}:
                    {{ notification.description }}
                  </p>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-card>
        </div>
      </div>
    </div>

    <!-- ENERGY TAB -->
    <div class="tab generatorSet" *ngIf="tab == 'energy' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/summary/Icons_Energia GRAY.svg"
          ></ion-icon>

          <div>
            <h1> Electrógeno </h1>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.eb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.eb.limits.varMax
              ]
            "
            [textValue]="
              systemData.records[0][
                systemData.attributes.structure.system.eb.var
              ] | number : '1.0-2'
            "
            imgValue=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.eb.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.ec.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.ec.limits.varMax
              ]
            "
            [textValue]="
              systemData.records[0][
                systemData.attributes.structure.system.ec.var
              ] | number : '1.0-2'
            "
            imgValue=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.ec.label"
            tooltipClass="top"
            placement="top"
            [description]="false"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.ed.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.ed
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.ed.unit }}
                {{ systemData.attributes.structure.system.ed.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.ee.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.ee
                  ) | date : 'dd-MM-yyyy'
                }}
                <!-- {{ systemData.attributes.structure.system.ee.unit }} -->
                <!-- {{ systemData.attributes.structure.system.ee.subUnit }} -->
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.ef.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{ systemData.records[0].engineState ? 'Encendido' : 'Apagado' }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="tab5ChartLabels"
            [legend]="tabsChartLegend"
            [chartType]="chartType"
            [datasets]="tab5ChartData"
            [options]="tab5ChartOptions"
            [colors]="tab5ChartColors"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="tipContentLoadChart"
            tooltipClass="left chart-tooltip"
            placement="left"
          >
            <ion-icon name="help-circle"></ion-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tabLoading">
      <mat-spinner></mat-spinner>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #tipContent10>
  {{ 'SYSTEM.tooltips.10' | translate }}
</ng-template>
<ng-template #tipContent19>
  {{ 'SYSTEM.tooltips.19' | translate }}
</ng-template>
<ng-template #tipContent20>
  {{ 'SYSTEM.tooltips.20' | translate }}
</ng-template>
<ng-template #tipContent22>
  {{ 'SYSTEM.tooltips.22' | translate }}
</ng-template>

<!-- Load tab tips -->

<ng-template #tipContentLoadChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.4' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.3' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.2' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.1' | translate }}
</ng-template>

<!-- Electrical tab tips -->
<ng-template #tipContent23>
  {{ 'SYSTEM.tooltips.23' | translate }}
</ng-template>
<ng-template #tipContent24>
  {{ 'SYSTEM.tooltips.24' | translate }}
</ng-template>
<ng-template #tipContent25>
  {{ 'SYSTEM.tooltips.25' | translate }}
</ng-template>
<ng-template #tipContent26>
  {{ 'SYSTEM.tooltips.26' | translate }}
</ng-template>
<ng-template #electricalLeftChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.29' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.30' | translate }}
</ng-template>

<!-- Battery tab tips -->
<ng-template #tipContent33>
  {{ 'SYSTEM.tooltips.33' | translate }}
</ng-template>
<ng-template #tipContent34>
  {{ 'SYSTEM.tooltips.34' | translate }}
</ng-template>
<ng-template #tipContent35>
  {{ 'SYSTEM.tooltips.35' | translate }}
</ng-template>
<ng-template #tipContent36>
  {{ 'SYSTEM.tooltips.36' | translate }}
</ng-template>
<ng-template #battLeftChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.40' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.41' | translate }}
</ng-template>

<!-- Solar tab tips -->
<ng-template #tipContent44>
  {{ 'SYSTEM.tooltips.44.1' | translate }}
</ng-template>
<ng-template #tipContent45>
  {{ 'SYSTEM.tooltips.45' | translate }}
</ng-template>
<ng-template #tipContent46>
  {{ 'SYSTEM.tooltips.46' | translate }}
</ng-template>
<ng-template #tipContent47>
  {{ 'SYSTEM.tooltips.47' | translate }}
</ng-template>
<ng-template #solarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.50' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.51' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.52' | translate }}
</ng-template>
<ng-template #solarBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.49' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.epvToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.48' | translate }}
</ng-template>

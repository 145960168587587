<div class="spinner-container">
  <span
    *ngIf="textValue < 9998"
    class="magnitude"
    [ngStyle]="
      { 'padding': imgValue != 0 ? '2px 2px 2px 20px' : '2px 2px 2px 2px' }"
    [ngClass]="
      borderValue == 1
        ? 'magnitude availability-color' 
        : (borderValue == 2 ? 'magnitude rentability-color': 'magnitude sustainability-color')"
        >{{ magnitude }}
  </span>
  <span *ngIf="textValue < 9998" class="maxSpinnerValue">{{ maxSpinnerValue }}</span>
  <span class="redLine"></span>
  <div class="img-value">
    <span *ngIf="borderValue == 1"><img *ngIf="imgValue != 0" src="../../../assets/icons/icon_spinner_krill_remasterized.svg" alt="" /></span>
    <span *ngIf="borderValue == 2"><img *ngIf="imgValue != 0" src="../../../assets/icons/icon_spinner_krill_remasterized.svg" alt="" /></span>
    <span *ngIf="borderValue == 3"><img *ngIf="imgValue != 0" src="../../../assets/icons/icon_spinner_krill_remasterized.svg" alt="" /></span>
  </div>
  <div class="summary-value">
    <h3 *ngIf="textValue < 9997">
      {{ textValue | number: '1.0-2' }}
    </h3>
    <h3 *ngIf="textValue == 9999">
      ON
    </h3>
    <h3 *ngIf="textValue == 9998">
      OFF
    </h3>
    <h3 *ngIf="textValue == 9997">
      N/A
    </h3>
  </div>
  <mat-spinner
  [ngClass]="
    textValue < 9998
      ? 'border-spinner'
      : textValue == 9999
      ? 'border-spinner circleGreen' : 'border-spinner circleRed'
  "
    reversed="true"
    mode="determinate"
    [value]="spinnerValue"
    [diameter]="spinnerDiameter"
  >
  </mat-spinner>
  <!-- <p>{{ description }}</p> -->
</div>

import { Component, OnInit, HostListener } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import * as moment from "moment-timezone";
import { TranslateConfigService } from "src/app/services/translate-config/translate-config.service";
import { SystemsService } from "src/app/services/systems/systems.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { SidenavService } from "src/app/services/sidenav/sidenav.service";
import { IndicatorsService } from "src/app/services/indicators/indicators.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { MathToolsService } from "src/app/services/mathTools/math-tools.service";
import { ConfigsService } from "src/app/services/configs/configs.service";
import { SweetAlertService } from "src/app/services/alert/sweet-alert.service";
import { UtilsService } from "src/app/services/utils/utils.service";

@Component({
  selector: "app-t1-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  // Image to krill spinner from indicator value
  indicatorImageValue = 1;

  pageLoading = true;
  summaryPage = true;

  sidenavOpened: boolean;

  systemId: string;
  systemData: any;
  lastNotification: any;
  systemCreated: string;
  systemCurrentDate: string;
  systemCurrentYear: string;
  systemCurrentHour: string;
  lastConnection: string;
  status = false;

  // indicator image
  imageIndicator: string;

  spinnerRemasterizedDiameter = 135;

  indicatorsDeviation: any;

  systemIndicatorsData: any;
  defaultDate = "";
  startDate = "";
  endDate = "";
  isRefresh: any;

  systemImgs: [];

  indicators: any[] = [
    {
      name: "autonomy",
      off: 0,
    },
    {
      name: "profitability",
      off: 0,
    },
    {
      name: "sustainability",
      off: 0,
    },
  ];

  promEnergiaDiariaCarga = 0;

  message: any;

  // tabs
  tankTab: any;
  historicalTab: any;
  projectionTab: any;
  alarmTab: any;
  energyTab: any;

  // google maps - tankLevel
  mapLat: number;
  mapLng: number;
  mapZoom: number;

  interval: any;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public systemsService: SystemsService,
    public notificationsService: NotificationsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    private config: NgbTooltipConfig,
    public mathTools: MathToolsService,
    public configsService: ConfigsService,
    private sweetAlert: SweetAlertService,
    public utils: UtilsService
  ) {
    config.disableTooltip = true;
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    // Set spinner diameter
    this.spinnerResizes();

    await this.systemDataSetup();

    // If system doesnt have data, show popup
    // await this.showPopup();

    // this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
    //   this.systemData.id
    // );
    this.systemIndicatorsData = {
      Availability: 0,
      Sustainability: 0,
      profitability: 0,
    };

    // this.indicatorsDeviation =
    //   await this.indicatorsService.getIndicatorsDeviation(
    //     this.systemData.attributes.id
    //   );

    this.pageLoading = false;

    // Energia diaria de carga
    // await this.energiaDiariaCarga();

    // Inicia la actualización cada 10 minutos (600,000 milisegundos)
    // Inicia la actualización cada 10 minutos (600,000 milisegundos)
    this.isRefresh = localStorage.getItem("refresh");
    if (this.isRefresh == "yes") {
      this.interval = setInterval(() => {
        this.refresh();
        console.log("refresh");
      }, 240000);
    }
  }

  // Detiene la actualización al salir del componente
  ngOnDestroy() {
    if (this.isRefresh == "yes") {
      clearInterval(this.interval);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.spinnerResizes();
  }

  async showPopup() {
    if (!this.systemData.records[0].data.pLoad) {
      this.message = (
        await this.sweetAlert.message(
          `${this.systemData.attributes.name}`,
          `Actualmente el sistema se encuentra sin datos que reportar.`,
          "info",
          "Regresar"
        )
      ).value;
      if (this.message) {
        this.router.navigate(["/dashboard/home"]);
      }
    }
  }

  async energiaDiariaCarga() {
    const barsData = await this.systemsService.getRecordsBarsEnergy(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      0,
      "lastWeek"
    );
    let sumData = 0;
    for (const x in barsData.lineChartData[2].data) {
      sumData += parseInt(barsData.lineChartData[2].data[x]);
    }
    this.promEnergiaDiariaCarga =
      sumData / barsData.lineChartData[2].data.length;
  }

  /**
   *  Save name tab in localStorage to use in system page
   */
  saveTab(tab: string) {
    // console.log("saveTab, exist ? ", this.systemData.attributes.structure.common.systemTabs[tab], "tab? :", tab)
    if (this.systemData.attributes.structure.common.systemTabs[tab]) {
      localStorage.setItem("tab", tab);
    }
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string) {
    if (indicator) {
      localStorage.setItem("indicator", indicator);
    }
  }

  async systemDataSetup() {
    // get system id && system data
    this.systemId = this.activatedRoute.snapshot.paramMap.get("id");
    this.systemData = await this.systemsService.getSystemById(this.systemId);
    this.systemData = this.systemData.system;

    //monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);
    this.systemData.monthly = monthlyData;

    //daily data
    let dailyData = await this.systemsService.getSystemDaily(this.systemId);
    this.systemData.daily = dailyData;

    //last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: "N/A",
        lastDate: "N/A",
        name: "N/A",
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format("DD/MM/YYYY hh:mm:ss a");

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }
    console.log("lastNotification: ", this.lastNotification);

    // tabs
    this.tankTab = this.systemData.attributes.structure.common.systemTabs.tank
      ? true
      : false;
    ///this.historicalTab = true;
    this.historicalTab = this.systemData.attributes.structure.common.systemTabs
      .historical
      ? true
      : false;
    this.projectionTab = this.systemData.attributes.structure.common.systemTabs
      .projection
      ? true
      : false;
    this.alarmTab = this.systemData.attributes.structure.common.systemTabs.alarm
      ? true
      : false;
    this.energyTab = this.systemData.attributes.structure.common.systemTabs
      .energy
      ? true
      : false;

    // add image
    this.imageIndicator = `../../assets/weather/1x/${this.systemData.records[0].icon}.png`;
    console.log(this.systemData.attributes.structure.common.systemTabs);
    // console.log("systemData: ", this.systemData);
    // this.systemData.attributes.structure.common.indicatorRoute = false;

    // Para deshabilitar cualquier indicador recibido del backend
    if (this.systemData.attributes.plantDetails.inactiveIndicators) {
      this.systemData.attributes.plantDetails.inactiveIndicators.forEach(
        (element: any, index: any) => {
          // Si el indicador esta deshabilitado, deshabilita el indicador en el htmls
          if (element === 1) {
            this.indicators[index].off = element;
          }
        }
      );
    }

    if (this.systemData == null) return;

    this.systemImgs = JSON.parse(this.systemData.attributes.imgs);

    const today = new Date();
    const date = moment(today).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );

    this.systemCurrentDate = date.format("DD/MM");
    this.systemCurrentYear = date.format("YYYY");
    this.systemCurrentHour = date.format("hh:mm a");
    this.systemCreated = moment().format("DD/MM/YYYY hh:mm:ss a");

    this.defaultDate = moment(new Date())
      .utcOffset(
        this.systemData.attributes[
          this.systemData.attributes.structure.common.hb3.var
        ]
      )
      .format("yyyy-MM-DD");

    this.lastConnection = moment
      .unix(this.systemData.records[0].time / 1000)
      .format("DD/MM/YYYY hh:mm:ss a");
    if (this.systemData.attributes.status === "10000") {
      this.status = true;
    }

    localStorage.setItem("eq", this.systemData.attributes.equipmentId);

    // google map - tankLevel
    if (this.systemData) {
      this.mapLat = this.systemData.attributes.latitude;
      this.mapLng = this.systemData.attributes.longitude;
      this.mapZoom = 12;
    }
  }

  async refresh() {
    // update system data
    let auxSystemData = await this.systemsService.getSystemById(this.systemId);

    //update monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);

    //update daily data
    let dailyData = await this.systemsService.getSystemDaily(this.systemId);

    this.systemData = auxSystemData.system;
    this.systemData.monthly = monthlyData;
    this.systemData.daily = dailyData;

    //update last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: "N/A",
        lastDate: "N/A",
        name: "N/A",
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format("DD/MM/YYYY hh:mm:ss a");

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }
    console.log("lastNotification: ", this.lastNotification);

    // add image
    this.imageIndicator = `../../assets/weather/1x/${this.systemData.records[0].icon}.png`;

    const today = new Date();
    const date = moment(today).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );

    this.systemCurrentDate = date.format("DD/MM");
    this.systemCurrentYear = date.format("YYYY");
    this.systemCurrentHour = date.format("hh:mm a");
    this.systemCreated = moment().format("DD/MM/YYYY hh:mm:ss a");

    this.defaultDate = moment(new Date())
      .utcOffset(
        this.systemData.attributes[
          this.systemData.attributes.structure.common.hb3.var
        ]
      )
      .format("yyyy-MM-DD");

    this.lastConnection = moment
      .unix(this.systemData.records[0].time / 1000)
      .format("DD/MM/YYYY hh:mm:ss a");
    if (this.systemData.attributes.status === "10000") {
      this.status = true;
    }
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  public getdefaultValueSpinnerV2(value, units) {
    return "" + value + " " + units;
  }
}

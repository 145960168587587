import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  public async error() {
    Swal.fire({
      title: 'Error!',
      text: 'Do you want to continue',
      icon: 'error',
      confirmButtonText: 'Cool'
    });
  }

  public async warning(title, text, icon, confirmText, cancelText) {
    const swal = await Swal.fire({
      title,
      text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      icon,
      confirmButtonText: confirmText,
      confirmButtonColor: '#001f9d',
      cancelButtonText: cancelText,
      showCancelButton: true,
      backdrop: 'transparent',
      heightAuto: false
    }).then((result) => {
      return result;
    });
    return swal;
  }

  public async message(title, text, icon, confirmText){
    const swal = await Swal.fire({
      title,
      text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      icon,
      confirmButtonText: confirmText,
      confirmButtonColor: '#001f9d',
      // backdrop: 'transparent',
      heightAuto: false
    }).then((result) => {
      return result;
    });
    return swal;
  }
}

import { Component, HostListener, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from './services/translate-config/translate-config.service';
import * as moment from 'moment-timezone';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  sidenavMode = 'side';
  selectedLanguage: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private translate: TranslateConfigService
  ) {
    this.initializeApp();
    this.selectedLanguage = this.translate.getDefaultLanguage();
  }

  ngOnInit() {
    moment.locale('es');

    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
    } else {
      this.sidenavMode = 'side';
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  languageChanged(selectedLanguage: string) {
    this.translate.setLanguage(selectedLanguage);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
    } else {
      this.sidenavMode = 'side';
    }
  }
}

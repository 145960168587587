<div
  [ngClass]="
    format === 'horizontal' ? 'krill-spinner-v2' : 'krill-spinner-v2-vertical'
  "
>
  <div class="information-container">
    <p [ngStyle]="{ 'text-align': format === 'horizontal' ? '' : 'center' }">
      {{ descriptionTitle }}
    </p>
    <p
      *ngIf="description != false"
      [ngStyle]="{ 'text-align': format === 'horizontal' ? '' : 'center' }"
    >
      {{ subtitle }} : <span> {{ descriptionValue }} </span>
    </p>
  </div>
  <div class="spinner-container">
    <span *ngIf="textValue !== maxSpinnerValue" class="max-line"></span>
    <span class="empty-line"></span>
    <div class="img-value">
      <ion-icon mat-card-avatar *ngIf="imgValue" [src]="imgValue"></ion-icon>
    </div>
    <div class="summary-value">
      <h3>
        {{ textValue }}
      </h3>
      <span>{{ magnitude }}</span>
    </div>
    <mat-spinner
      [ngClass]="
        textValue < 99998
          ? 'border-spinner'
          : textValue == 99999
          ? 'border-spinner circleGreen'
          : 'border-spinner'
      "
      reversed="true"
      mode="determinate"
      [value]="
        (textValue === maxSpinnerValue && textValue > 0
          ? 100
          : (textValue * 90) / maxSpinnerValue
        ) | number : '1.0-1'
      "
      [diameter]="spinnerDiameter"
    >
    </mat-spinner>
  </div>
</div>

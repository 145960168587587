import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {
  constructor(private router: Router) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('TOKEN');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
      }
    );
  }

  /**
   *  Get the summaryPage variable
   * @param userId id of the user
   */
  public async getSummaryPage(userId: string) {
    try {
      const response = await axios.get(environment.apiUrl + 'users/' + userId, {
        headers: {}
      });
      return response.data.data.attributes.summaryPage;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Update the summaryPage variable
   * @param userId id of the user
   */
  public async updateSummaryPage(userId: string) {
    try {
      const response = await axios.put(
        environment.apiUrl + 'users/' + userId + '/summaryPage',
        {
          headers: {}
        }
      );
      return response.data.summaryPage;
    } catch (err) {
      return err;
    }
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';

@Component({
  selector: 'krill-spinner-remasterized',
  templateUrl: './krill-spinner-remasterized.component.html',
  styleUrls: ['./krill-spinner-remasterized.component.scss'],
})
export class KrillSpinnerRemasterizedComponent implements OnInit {
  @Input() spinnerValue: number;
  @Input() maxSpinnerValue: number;
  @Input() borderValue: number;
  @Input() imgValue: number;

  @Input() textValue: number;

  @Input() magnitude: string;
  @Input() description: string;

  @Input() spinnerDiameter: number;

  constructor(private translate: TranslateConfigService) {}

  ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private router: Router) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('TOKEN');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
      }
    );
  }

  /**
   *
   * @param companyId
   */
  public async getReportPDF(systemId: string, date: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/' + systemId + '/report/' + date,
        {
          headers: {}
        }
      );
      console.log(response);
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getCheck(systemId: string, date: string) {
    try {
      const response = await axios.get(
        environment.apiUrl +
          'records/' +
          systemId +
          '/report/' +
          date +
          '/check',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
  public async getConsolidatedReportCheck(projectId: string, date: string) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'records/project/' +
          projectId +
          '/report/' +
          date +
          '/check',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getIndividualReportCheck(systemId: string, date: string) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'records/' +
          systemId +
          '/report/' +
          date +
          '/check',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

<div class="page-content" *ngIf="!loading; else matSpinner">
  <div class="main-container">
    <div class="time-range">
      <mat-card class="filter-card">
        <mat-card-title> Rango de tiempo </mat-card-title>
        <mat-card-content>
          <mat-form-field appearance="fill">
            <mat-label>Desde</mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [(ngModel)]="startDate"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Hasta</mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [(ngModel)]="endDate"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to disabled="false"></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Intérvalo</mat-label>
            <mat-select [(value)]="interval">
              <mat-option value="daily"> Diario </mat-option>
              <!-- <mat-option value="weekly"> Semanal </mat-option> -->
              <mat-option value="monthly"> Mensual </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-error
            *ngIf="errorDate"
            style="font-size: 12px; margin-top: -13px; margin-bottom: 10px"
            >{{ error }}
          </mat-error>

          <mat-accordion>
            <!-- By locality -->
            <mat-expansion-panel [expanded]="true" class="filters">
              <mat-expansion-panel-header>
                <mat-panel-title> Por localidad </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-selection-list
                id="system"
                #system
                [(ngModel)]="selectedSystem"
                [multiple]="false"
              >
                <mat-list-option
                  *ngFor="let system of systems"
                  color="primary"
                  [value]="system"
                >
                  {{ system.name }}
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </mat-card-content>

        <div style="text-align: center">
          <ion-button
            (click)="search()"
            [disabled]="
              this.startDate === '' ||
              this.endDate === '' ||
              this.interval === '' ||
              this.selectedSystem.length === 0
            "
          >
            Buscar
          </ion-button>
        </div>
      </mat-card>
    </div>

    <div class="chart">
      <mat-card class="column-card column-card-2">
        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartData"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
          >
          </canvas>
        </div>
      </mat-card>
    </div>

    <div class="bar-charts">
      <!-- card -->
      <mat-card class="column-card">
        <!-- icon card -->
        <div class="icon-card-container">
          <img src="./assets/icon/summary/tank.svg" alt="" />
        </div>

        <!-- title card -->
        <mat-card-title class="title">
          <!-- {{ systemData.attributes.structure.summary.cards.ft0.label }} -->
          Combustible
        </mat-card-title>

        <!-- spinner 1 -->
        <krill-spinner-v2
          format="vertical"
          magnitude="litros"
          [maxSpinnerValue]="1"
          [textValue]="spinnerLeft"
          imgValue=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Litros consumidos"
          tooltipClass="top"
          placement="top"
          [description]="false"
        >
        </krill-spinner-v2>

        <!-- spinner 2 -->
        <krill-spinner-v2
          format="vertical"
          magnitude="litros"
          [maxSpinnerValue]="1"
          [textValue]="spinnerRight"
          imgValue=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle="Litros agregados"
          tooltipClass="top"
          placement="top"
          [description]="false"
        >
        </krill-spinner-v2>
      </mat-card>

      <!-- list -->
      <mat-card class="column-card">
        <!-- table -->
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Type Column -->
            <ng-container matColumnDef="locality">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SYSTEM.balance.table.' + displayedColumns[0] | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.locality }}</td>
            </ng-container>

            <!-- Locality Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SYSTEM.balance.table.' + displayedColumns[1] | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SYSTEM.balance.table.' + displayedColumns[2] | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.type }}</td>
            </ng-container>

            <!-- Hour Column -->
            <ng-container matColumnDef="litres">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'SYSTEM.balance.table.' + displayedColumns[3] | translate }}
              </th>
              <td mat-cell *matCellDef="let element">{{ element.litres }}</td>
            </ng-container>

            

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

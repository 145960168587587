import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  name = localStorage.getItem('USER_NAME');
  email = localStorage.getItem('EMAIL');
  description = '';
  subject = '';
  localidad= '';

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {}

  refreshParche = true;

  ngOnInit() {}

  async onSubmit() {
    let message =
      'Nombre: ' +
      this.name +
      '\n' +
      'Email: ' +
      this.email +
      '\n' +
      'Mensaje: ' +
      this.description+
      '\n' +
      'Localidad: ' +
      this.localidad;

    this.subject == '' ? (this.subject = 'Contacto sin asunto') : this.subject;
    this.authService.contact(this.subject, message, this.email);
    let alert = await this.alertCtrl.create({
      header: 'Solicitud enviada',
      message:
        'Muchas gracias por la información, trabajaremos en su comentario y nos pondremos en contacto de ser necesario',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.router.navigate(['/dashboard/systems']);
          }
        }
      ]
    });
    alert.present();

    //   let { username, email, subject, description } = this.contactForm.value;
    //   let response = await this.authService.contactForm(
    //     username,
    //     email,
    //     subject,
    //     description
    //   );
    //   if (response.status >= 200 && response.status < 400) {
    //     this._snackBar.open(
    //       this.translate.getTranslation('CONTACT.successfully'),
    //       '',
    //       {
    //         duration: 2000
    //       }
    //     );
    //   }
  }
}

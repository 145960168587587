import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IndicatorsService {
  constructor() {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('TOKEN');
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Session expired
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.href = environment.loginUrl;
        }
      }
    );
  }

  /**
   * Get the indicator's deviation data (just for availability?)
   *
   * @param id id of the system
   */
  public async getIndicatorsDeviation(systemId: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/indicator/deviation/' + systemId,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Get the indicator's projections charts (just for availability?)
   *
   * @param id id of the system
   */
  public async getProjectionsCharts(systemId: string) {
    try {
      const response = await axios.get(
        environment.apiUrl + 'records/indicator/projection/' + systemId,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Get the indicator's projection data chart for profiltability
   *
   * @param id id of the system
   */
  public async getProjectionsProfChart() {
    try {
      const response = await axios.get(
        environment.apiUrl + 'indicatorCouter/fin/projection/3',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async availabilityHistorics(
    systemId: string,
    startDate: string,
    endDate: string
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'indicatorCouter/dis/hist/' + systemId,
        {
          systemsId: systemId,
          startDate,
          endDate,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  public async getIndicators(companyId: number, projectId: number) {
    try {
      const response = await axios.get(
        environment.apiUrl +
          'indicatorCouter/resume/' +
          companyId +
          '/' +
          projectId,
        {
          headers: {},
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async sustHistorics(
    systemId: string,
    startDate: string,
    endDate: string
  ) {
    try {
      const response = await axios.post(
        environment.apiUrl + 'indicatorCouter/sus/hist/' + systemId,
        {
          systemsId: systemId,
          startDate,
          endDate,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Returns the records.csv
   * @param systemId The system id
   * @param equipmentId The Equipment id of the system
   * @param startDate start date to download
   * @param endDate start date to download
   * @param timeZone timezone
   */

  public async downloadRecords(
    systemId: number,
    equipmentId: string,
    startDate: string,
    endDate: string,
    timeZone: string,
  ) {
    try{
      const response = await axios.post(
        environment.apiUrl + 'records/download',
        {
          systemsId: Number(systemId),
          equipmentId,
          startDate,
          endDate,
          timeZone,
        },
        {
          headers: {},
        }
      );
      return response.data;
    }catch (err){
      return err;
    }
  }

  /**
   * Returns the indicator's route depending on the equipment id
   * @param equipmentId The Equipment id of the system
   * @param systemId The system id
   */
  public getIndicatorRoute(equipmentId: any, systemId: any, indicatorRoute?: boolean) {
    equipmentId = equipmentId.toString();
    systemId = systemId.toString();
    if (indicatorRoute){
      return '/dashboard/indicators/t1/' + systemId;
    }else{
      return;
    }
    // switch (equipmentId) {
    //   case '1':
    //     if(systemId == 9) return '/dashboard/indicators/sacolar-ns/' + systemId;
    //     else return '/dashboard/indicators/sacolar/' + systemId;

    //   case '2':
    //     return '/dashboard/indicators/studer/' + systemId;

    //   case '3':
    //     return '/dashboard/indicators/studer-inv/' + systemId;

    //   case '4':
    //     return '/dashboard/indicators/krill-box/' + systemId;

    //   case '5':
    //     return '/dashboard/indicators/ups/' + systemId;

    //   case '6':
    //     return '/dashboard/indicators/krill-box-ups/' + systemId;

    //   case '7':
    //     return '/dashboard/indicators/jas/' + systemId;

    //   case '8':
    //     //return '/dashboard/indicators/porvenir/' + systemId;
    //     return;

    //   case '9':
    //     //return '/dashboard/indicators/tigo/' + systemId;
    //     return;

    //   case '10':
    //     return '/dashboard/indicators/eq10kb/' + systemId;

    //   case '11':
    //     return '/dashboard/indicators/eq11kb/' + systemId;
    //     // return;

    //   case '12':
    //     return '/dashboard/indicators/eq12kb/' + systemId;
    //     // return;

    //   case '13':
    //     //return '/dashboard/indicators/eq13kb/' + systemId;
    //     return;

    //   case '14':
    //     //return '/dashboard/indicators/eq14kb/' + systemId;
    //     return;
    // }
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  form: UntypedFormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  responsiveMode = false;
  title: string;
  name: string;
  ip: string;
  description: string;
  id: number;

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateConfigService,
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.name = data.name;
    this.id = data.id;
  }
  ngOnInit() {
    this.form = this.fb.group(
      {
        krill: ['', [Validators.required]],
      },
      {
        validator: this.krillValidator,
      }
    );
  }

  krillValidator(form: UntypedFormGroup) {
    const condition = form.get('krill').value !== 'krill';
    return condition ? { krillDoNotMatch: true } : null;
  }

  async delete() {
    this.dialogRef.close({ id: this.id });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { Component, OnInit, Inject, Input } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'system-images',
  templateUrl: './system-images.component.html',
  styleUrls: ['./system-images.component.scss'],
})
export class SystemImagesComponent {
  @Input() systemImgs: [];

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.data = {
      systemImgs: this.systemImgs,
    };

    this.dialog.open(SystemImagesDialogComponent, dialogConfig);
  }
}

@Component({
  selector: 'system-images-dialog',
  templateUrl: 'system-images-dialog.component.html',
  styleUrls: ['./system-images.component.scss'],
})
export class SystemImagesDialogComponent {
  systemImgs = [];
  constructor(
    public dialogRef: MatDialogRef<SystemImagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    console.log('data: ', data);
    this.systemImgs = data.systemImgs;
  }
}

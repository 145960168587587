import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  constructor(public activatedRoute: ActivatedRoute, public router: Router) {}

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');
    let tokenDecode: any;
    tokenDecode = jwt_decode(token);
    console.log(tokenDecode);
    localStorage.setItem('TOKEN', token);
    localStorage.setItem('COMPANY_ID', tokenDecode.companyId);
    localStorage.setItem('PROJECT_ID', tokenDecode.projectId);
    localStorage.setItem('TYPE', '[]');
    localStorage.setItem('REGION_ID', '[]');
    localStorage.setItem('USER_NAME', tokenDecode.username);
    localStorage.setItem('USER_ID', tokenDecode.userId);
    localStorage.setItem('EMAIL', tokenDecode.email);
    localStorage.setItem('HOME_PATH', tokenDecode.summaryPage);
    localStorage.setItem('SUMMARY_PAGE', 'false');
    localStorage.setItem('LONGITUDE', tokenDecode.longitude);
    localStorage.setItem('LATITUDE', tokenDecode.latitude);
    localStorage.setItem('refresh', "no");
    this.router.navigate(['/dashboard/home']);
  }
}

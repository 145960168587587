import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AddEditDialogComponent } from 'src/app/components/add-edit-dialog/add-edit-dialog.component';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent implements OnInit {
  companies;

  constructor(
    private dialog: MatDialog,
    private companiesService: CompaniesService,
    private _snackBar: MatSnackBar,
    private translate: TranslateConfigService
  ) {}

  async ngOnInit() {
    this.companies = await this.companiesService.getCompaniesList();
  }

  addCompany() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'add',
      title: 'company',
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.companiesService.addCompany(
          data.name
        );
        if (!response) {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
          return;
        }
        if (response.status >= 200 && response.status < 400) {
          this.companies = await this.companiesService.getCompaniesList();
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.companyCreated')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  updateCompany(company) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'edit',
      title: 'company',
      name: company.name,
      ...company
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.companiesService.updateCompany(
          data.id,
          data.name
        );
        if (response.status >= 200 && response.status < 400) {
          this.companies = await this.companiesService.getCompaniesList();
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.companyUpdated')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  deleteCompany(company) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'delete',
      title: 'company',
      name: company.name,
      ...company
    };

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.companiesService.deleteCompany(data.id);
        if (response.status >= 200 && response.status < 400) {
          this.companies = await this.companiesService.getCompaniesList();
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.companyDeleted')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

}

import { Component, OnInit, Input, HostListener } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label } from "ng2-charts";
import * as moment from "moment-timezone";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { NgbTooltipConfig } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { captureRejectionSymbol } from "events";

import { TranslateConfigService } from "src/app/services/translate-config/translate-config.service";
import { SystemsService } from "src/app/services/systems/systems.service";
import { SidenavService } from "src/app/services/sidenav/sidenav.service";
import { IndicatorsService } from "src/app/services/indicators/indicators.service";
import { MathToolsService } from "src/app/services/mathTools/math-tools.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";

@Component({
  selector: "app-t1-system",
  templateUrl: "./system.component.html",
  styleUrls: ["./system.component.scss"],
})
export class SystemComponent implements OnInit {
  imageIndicator: string;
  pageLoading = true;
  tabLoading = false;
  batteryCharge = 10;

  tabs = ["tank", "historical", "projection", "alarm", "energy"];

  systemId: string;
  systemData: any;
  lastNotification: any;
  systemCreated: string;
  systemCurrentDate: string;
  systemCurrentYear: string;
  systemCurrentHour: string;
  lastConnection: string;
  status = false;

  spinnerRemasterizedDiameter = 135;

  systemIndicatorsData: any;
  systemImgs: [];

  sidenavOpened: boolean;

  indicators: any[] = [
    {
      name: "availability",
      off: 0,
    },
    {
      name: "profitability",
      off: 0,
    },
    {
      name: "sustainability",
      off: 0,
    },
  ];

  // tabs
  tankTab: any;
  historicalTab: any;
  projectionTab: any;
  alarmTab: any;
  energyTab: any;

  // google maps - tankLevel
  mapLat: number;
  mapLng: number;
  mapZoom: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    public notificationsService: NotificationsService,
    private config: NgbTooltipConfig,
    public mathTools: MathToolsService,
    public utils: UtilsService
  ) {
    // deshabilitar tooltip
    config.disableTooltip = true;
    config.container = "body";
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    await this.systemDataSetup();

    // Set spinner diameter
    this.spinnerResizes();

    if (this.systemData == null) {
      this.router.navigate(["/dashboard/home"]);
      return;
    }

    // this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
    //   this.systemData.id
    // );
    this.systemIndicatorsData = {
      Availability: 0,
      Sustainability: 0,
      profitability: 0,
    };

    this.pageLoading = false;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.spinnerResizes();
  }

  async systemDataSetup() {
    // get system id && system data
    this.systemId = this.activatedRoute.snapshot.paramMap.get("id");
    this.systemData = await this.systemsService.getSystemById(this.systemId);
    this.systemData = this.systemData.system;
    //monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);
    this.systemData.monthly = monthlyData;

    //daily data
    let dailyData = await this.systemsService.getSystemDaily(this.systemId);
    this.systemData.daily = dailyData;

    //last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: "N/A",
        lastDate: "N/A",
        name: "N/A",
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format("DD/MM/YYYY hh:mm:ss a");

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }

    
    let tabsLength = this.tabs.length;
    // tabs
    for (let i = 0; i < tabsLength; i++) {
      if (
        !this.systemData.attributes.structure.common.systemTabs[this.tabs[i]]
      ) {
        this.tabs.splice(i, 1);
        i = i - 1;
        tabsLength -= 1;
      }
    }

    // tabs
    this.tankTab = this.systemData.attributes.structure.common.systemTabs.tank
      ? true
      : false;

    //this.historicalTab = true;
    this.historicalTab = this.systemData.attributes.structure.common.systemTabs
      .historical
      ? true
      : false;
    this.projectionTab = this.systemData.attributes.structure.common.systemTabs
      .projection
      ? true
      : false;
    this.alarmTab = this.systemData.attributes.structure.common.systemTabs.alarm
      ? true
      : false;
    this.energyTab = this.systemData.attributes.structure.common.systemTabs
      .energy
      ? true
      : false;

    // delete
    this.energyTab = false;
    //

    // add image
    this.imageIndicator = `../../assets/weather/1x/${this.systemData.records[0].icon}.png`;

    // Para deshabilitar cualquier indicador recibido del backend
    if (this.systemData.attributes.plantDetails.inactiveIndicators) {
      this.systemData.attributes.plantDetails.inactiveIndicators.forEach(
        (element: any, index: any) => {
          // Si el indicador esta deshabilitado, deshabilita el indicador en el htmls
          if (element === 1) {
            this.indicators[index].off = element;
          }
        }
      );
    }

    if (this.systemData == null) return;

    this.systemImgs = JSON.parse(this.systemData.attributes.imgs);

    const today = new Date();
    const date = moment(today).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );

    this.systemCurrentDate = date.format("DD/MM");
    this.systemCurrentYear = date.format("YYYY");
    this.systemCurrentHour = date.format("hh:mm a");
    this.systemCreated = moment().format("DD/MM/YYYY hh:mm:ss a");

    this.lastConnection = moment
      .unix(this.systemData.records[0].time / 1000)
      .format("DD/MM/YYYY hh:mm:ss a");
    if (this.systemData.attributes.status === "10000") {
      this.status = true;
    }

    // google map - tankLevel
    if (this.systemData) {
      this.mapLat = this.systemData.attributes.latitude;
      this.mapLng = this.systemData.attributes.longitude;
      this.mapZoom = 12;
    }
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  /**
   * Return the round value of number.
   * This function is useful for the html tags
   */
  public round(number: number) {
    return Math.round(number);
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string) {
    if (indicator) {
      localStorage.setItem("indicator", indicator);
    }
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MathToolsService {
  constructor() {}

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public round(number: number) {
    return Math.round(number);
  }

  /**
   *
   * Return the "number" rounded to a certain value "limit"
   *
   * @param number The number we want to round
   * @param limit  The round limit
   */
  public roundToNumber(number: number, limit: number = 10) {
    return Math.round(number / limit) * limit;
  }

  /**
   *
   * Return the "number" rounded to a certain value "limit"
   *
   * @param number The number we want to round
   * @param limit  The round limit
   */
     public roundToNumber2(number: number, limit: number = 100) {
      return (Math.ceil(number * limit) / limit).toFixed(2);
    }
}

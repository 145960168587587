<div class="page-content" *ngIf="!pageLoading; else matSpinner">
  <mat-card [ngClass]="sidenavOpened ? 'fixed' : 'fixed-full-width'">
    <!-- indicator section -->
    <mat-card class="indicators-section">
      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('availability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[0].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/up-barrel-green.svg"
          ></ion-icon>
          <mat-card-subtitle> Entradas acumuladas </mat-card-subtitle>
          <mat-card-title class="connected-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha1
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha1.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('profitability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[1].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/down-barrel-red.svg"
          ></ion-icon>
          <mat-card-subtitle> Salidas acumuladas </mat-card-subtitle>
          <mat-card-title class="alarm-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha2
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha2.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('sustainability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[2].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/gasoline-can-orange.svg"
          ></ion-icon>
          <mat-card-subtitle> Promedio entre recarga </mat-card-subtitle>
          <mat-card-title class="alert-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha3
              ) | number : '1.0-0'
            }}

            {{ systemData.attributes.structure.common.ha3.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>
    <!-- data section -->
    <mat-card class="data-section">
      <mat-card-header class="bg-gradient-blue">
        <mat-card-subtitle class="system-name">
          {{ systemData.attributes.name }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="system-description-container">
        <div class="system-description-weather">
          <p>
            {{ utils.getWeather(systemData.records[0].icon) }}
          </p>
          <p>
            {{
              systemData.records[0][
                systemData.attributes.structure.common.hb2.var
              ] | number
            }}
            {{ systemData.attributes.structure.common.hb2.unit }}
          </p>
        </div>
        <div class="system-description-dates">
          <p>{{ systemCurrentDate }}</p>
          <p>{{ systemCurrentYear }}</p>
          <p>{{ systemCurrentHour }}</p>
        </div>
        <div class="system-description-logo">
          <ion-icon
            class="weather-icon"
            src="assets/icon/weather/rainy-day.svg"
          ></ion-icon>
          <!-- <img class="weather-icon" [src]="imageIndicator" alt="" /> -->
        </div>
        <div class="system-description-icon">
          <p style="font-size:smaller;">
            <ion-icon
              class="icon"
              name="notifications"
              [ngClass]="
                systemData.records[0].status == null
                  ? 'icon green-icon'
                  : systemData.records[0].status.sNotifications == 'alarm'
                  ? 'icon red-icon'
                  : systemData.records[0].status.sNotifications == 'warning'
                  ? 'icon yellow-icon'
                  : systemData.records[0].status.sNotifications == 'info'
                  ? 'icon blue-icon'
                  : 'icon green-icon'
              "
            >
            </ion-icon>

            {{
              systemsService.getSystemNotification(
                systemData.records,
                lastNotification.notification.title
              )
            }}
          </p>
        </div>
        <div
          [ngClass]="
            systemData.attributes.status == 00000
              ? 'system-description-status bg-black'
              : 'system-description-status bg-green'
          "
        >
          <p>
            {{
              (
                'SYSTEM.status.' +
                  systemsService.getSystemStatus(systemData.attributes.status)
                | translate
              ).toUpperCase()
            }}
          </p>
          <p>{{ lastConnection }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- spinner section -->
    <mat-card class="spinner-section">
      <mat-card-header>
        <mat-card-subtitle> Combustible </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="spinner-v2">
        <krill-spinner-v2
          format="vertical"
          magnitude="Litros"
          [maxSpinnerValue]="
            systemData.attributes.plantDetails.system.FrontLimits[
              systemData.attributes.structure.common.hc1.limits.varMax
            ]
          "
          [textValue]="
            systemData.records[0][
              systemData.attributes.structure.common.hc1.var
            ] | number : '1.0-0'
          "
          imgValue="assets/icon/home/spinner-gasoline-can.svg"
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle=""
          tooltipClass="top"
          placement="top"
          [description]="false"
        >
        </krill-spinner-v2>
      </mat-card-content>
    </mat-card>
    <!-- map section -->
    <mat-card class="map-section">
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <agm-map [latitude]="mapLat" [longitude]="mapLng" [zoom]="mapZoom">
          <agm-marker
            [latitude]="systemData.attributes.latitude"
            [longitude]="systemData.attributes.longitude"
            [iconUrl]="
              './assets/marks/mark-' +
              systemsService.getSystemStatusColor(
                systemData.attributes.status
              ) +
              '.png'
            "
          >
            <agm-info-window>
              <button>
                <ion-img
                  *ngIf="
                    systemData.attributes.imgs != null;
                    else krillThumbnail
                  "
                  [src]="
                    systemsService.getSystemFirstImg(systemData.attributes)
                  "
                  class="thumbnail"
                ></ion-img>
                <ng-template #krillThumbnail>
                  <ion-img
                    src="../../../../assets/logos/favicon.png"
                    class="thumbnail krill-thumbnail"
                  >
                  </ion-img>
                </ng-template>
                <strong style="color: black">{{
                  systemData.attributes.name
                }}</strong>
              </button>
            </agm-info-window>
          </agm-marker>
        </agm-map>
      </mat-card-content>
    </mat-card>
    <!-- images section -->
    <mat-card class="images-section">
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <system-images [systemImgs]="systemImgs"></system-images>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <section class="second-section">
    <!-- premises -->
    <mat-card class="premises-section">
      <mat-card class="premises">
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/speedometer.svg"
          ></ion-icon>
          <mat-card-title>
            Indicadores
            <br />
            Estratégicos
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-blue'
            : 'premises bg-gradient-blue p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pa.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pa
              )| number : '1.0-1'
            }}
            {{ systemData.attributes.structure.summary.promises.pa.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-blue'
            : 'premises bg-gradient-blue p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pb.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pb
              )| number : '1.0-1'
            }}
            {{ systemData.attributes.structure.summary.promises.pb.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-green'
            : 'premises bg-gradient-green p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pc.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pc
              )/1000 | number : '1.0-1'
            }}
            Ton
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-green'
            : 'premises bg-gradient-green p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pd.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pd
              ) /1000 | number : '1.0-1'
            }}
            Ton
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-green'
            : 'premises bg-gradient-green p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pe.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pe
              )/1000 | number : '1.0-1'
            }}
            Ton
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>

    <!-- tank card -->
    <mat-card
      [ngClass]="tankTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        tankTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : null
      "
      (click)="saveTab('tank')"
    >
      <!-- ICON CARD -->
      <div class="icon-card-container">
        <img *ngIf="tankTab" src="./assets/icon/summary/tank.svg" alt="" />
      </div>

      <div *ngIf="!tankTab" class="icon-card-off-container">
        <img src="./assets/icon/summary/tank.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.ft0.label }}
        </mat-card-title>
      </div>

      <!-- TITLE CARD -->
      <mat-card-title *ngIf="tankTab" class="title">
        {{ systemData.attributes.structure.summary.cards.ft0.label }}
      </mat-card-title>

      <!-- BOX 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="tankTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.ft1.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.ft1.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.ft1.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- BOX 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="tankTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.ft2.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.ft2.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.ft2.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- historical card -->
    <mat-card
      [ngClass]="historicalTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        historicalTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : null
      "
      (click)="saveTab('historical')"
    >
      <!-- ICON CARD -->
      <div class="icon-card-container">
        <img
          *ngIf="historicalTab"
          src="./assets/icon/summary/historical.svg"
          alt=""
        />
      </div>
      <div *ngIf="!historicalTab" class="icon-card-off-container">
        <img src="./assets/icon/summary/historical.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fh0.label }}
        </mat-card-title>
      </div>

      <!-- TITLE CARD -->

      <mat-card-title *ngIf="historicalTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fh0.label }}
      </mat-card-title>

      <!-- BOX 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="historicalTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fh1.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fh1.var
            ] |date : 'yyyy-MM-dd, h:mm a' : systemData.attributes.timeZone
          }}
          
        </mat-card-title>
      </mat-card-header>

      <!-- BOX 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="historicalTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fh2.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fh2.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fh2.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- projection card -->
    <mat-card
      [ngClass]="projectionTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        projectionTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : null
      "
      (click)="saveTab('projection')"
    >
      <!-- ICON CARD -->
      <div class="icon-card-container">
        <img
          *ngIf="projectionTab"
          src="./assets/icon/electrical-tower-gray.svg"
          alt=""
        />
      </div>

      <div *ngIf="!projectionTab" class="icon-card-off-container">
        <img src="./assets/icon/electrical-tower-gray.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fp0.label }}
        </mat-card-title>
      </div>

      <!-- TITLE CARD -->
      <mat-card-title *ngIf="projectionTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fp0.label }}
      </mat-card-title>

      <!-- BOX 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="projectionTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fp1.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fp1.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fp1.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- BOX 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="projectionTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fp2.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fp2.var
            ] | date : 'yyyy-MM-dd, h:mm a' : systemData.attributes.timeZone
          }}
          
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- notification card -->
    <mat-card
      [ngClass]="alarmTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        alarmTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : null
      "
      (click)="saveTab('alarm')"
    >
      <!-- ICON CARD -->
      <div class="icon-card-container">
        <img
          *ngIf="alarmTab"
          src="./assets/icon/summary/notification.svg"
          alt=""
        />
      </div>

      <div *ngIf="!alarmTab" class="icon-card-off-container">
        <img src="./assets/icon/summary/notification.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fn0.label }}
        </mat-card-title>
      </div>

      <!-- TITLE CARD -->
      <mat-card-title *ngIf="alarmTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fn0.label }}
      </mat-card-title>

      <!-- BOX 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="alarmTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fn1.label }}
        </mat-card-subtitle>
        <mat-card-title style="font-size: medium">
          {{
            !lastNotification.notification.title
              ? '-'
              : lastNotification.notification.title
          }}
        </mat-card-title>
      </mat-card-header>

      <!-- BOX 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="alarmTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fn2.label }}
        </mat-card-subtitle>
        <mat-card-title style="font-size: 18px !important">
          {{
            !lastNotification.notification.time
              ? '-'
              : (lastNotification.notification.time
                | date : 'yyyy-MM-dd, h:mm a' : systemData.attributes.timeZone)
          }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- energy card -->
    <mat-card
      [ngClass]="energyTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        energyTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : null
      "
      (click)="saveTab('energy')"
    >
      <!-- ICON CARD -->
      <div class="icon-card-container">
        <img
          *ngIf="energyTab"
          src="./assets/icon/summary/Icons_Energia GRAY.svg"
          alt=""
        />
      </div>

      <div *ngIf="!energyTab" class="icon-card-off-container">
        <img src="./assets/icon/summary/energy.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fe0.label }}
        </mat-card-title>
      </div>

      <!-- TITLE CARD -->
      <mat-card-title *ngIf="energyTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fe0.label }}
      </mat-card-title>

      <!-- BOX 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="energyTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fe1.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fe1.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fe1.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- BOX 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="energyTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fe2.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fe2.var
            ] == 0
              ? 'Apagado'
              : !systemData.records[0][
                  systemData.attributes.structure.summary.cards.fe2.var
                ]
              ? '-'
              : 'Encedido'
          }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </section>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #tipContentDiagram>
  {{ 'SYSTEM.tooltips.15.1' | translate }}
  {{ 'SYSTEM.tooltips.15.2' | translate }}
  {{ 'SYSTEM.tooltips.15.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.16.1' | translate }}
  {{ 'SYSTEM.tooltips.16.2' | translate }}
  {{ 'SYSTEM.tooltips.16.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.17.1' | translate }}
  {{ 'SYSTEM.tooltips.17.2' | translate }}
  {{ 'SYSTEM.tooltips.17.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.18.1' | translate }}
  {{ 'SYSTEM.tooltips.18.2' | translate }}
  {{ 'SYSTEM.tooltips.18.3' | translate }}
</ng-template>

<ng-template #tipContent10>
  {{ 'SYSTEM.tooltips.10' | translate }}
</ng-template>
<ng-template #tipContent11>
  {{ 'SYSTEM.tooltips.11' | translate }}
</ng-template>
<ng-template #tipContent12>
  {{ 'SYSTEM.tooltips.12' | translate }}
</ng-template>
<ng-template #tipContent13>
  {{ 'SYSTEM.tooltips.13' | translate }}
</ng-template>
<ng-template #tipContent14>
  {{ 'SYSTEM.tooltips.14' | translate }}
</ng-template>
<ng-template #tipContent23>
  {{ 'SYSTEM.tooltips.23' | translate }}
</ng-template>
<ng-template #tipContent57>
  {{ 'SYSTEM.tooltips.57' | translate }}
</ng-template>
<ng-template #tipContent34>
  {{ 'SYSTEM.tooltips.34' | translate }}
</ng-template>
<ng-template #tipContent59>
  {{ 'SYSTEM.tooltips.59' | translate }}
</ng-template>
<ng-template #tipContent89>
  {{ 'SYSTEM.tooltips.89' | translate }}
</ng-template>
<ng-template #tipContent92>
  {{ 'SYSTEM.tooltips.92' | translate }}
</ng-template>
<ng-template #tipContent68>
  {{ 'SYSTEM.tooltips.68' | translate }}
</ng-template>
<ng-template #tipContent69>
  {{ 'SYSTEM.tooltips.69' | translate }}
</ng-template>
<ng-template #tipContent67>
  {{ 'SYSTEM.tooltips.67' | translate }}
</ng-template>
<ng-template #connected>
  {{ 'SYSTEM.status.1' | translate }}
</ng-template>
<ng-template #disconnected>
  {{ 'SYSTEM.status.0' | translate }}
</ng-template>

<ng-template #charging>
  {{ 'SYSTEM.tooltips.113' | translate }}
</ng-template>
<ng-template #discharging>
  {{ 'SYSTEM.tooltips.114' | translate }}
</ng-template>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../translate-config/translate-config.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private router: Router,
    private translate: TranslateConfigService) {}


  public getWeather(data:string):string {
    let weather = this.translate.getTranslation(`SYSTEM.weather.${data}`);
    weather = weather.split(" ")[0];
    return weather;
  }

  public downloadCSVFile(name: string, data: any, startDate = '', endDate = '') {
    // create blob with data csv
    const blob = new Blob([data], { type: 'text/csv' });
    // generate url from blob
    const url = window.URL.createObjectURL(blob);
    // create an a element with href = url
    const a = document.createElement('a');
    a.href = url;
    // docuement name
    a.download = `${name}${startDate !== '' ? '-' + startDate : ('').trim()}${endDate !== '' ? '-' + endDate : ('').trim()}.csv`;
    a.click();
    // delete url
    URL.revokeObjectURL(url);
  }
}

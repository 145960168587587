import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetConfig
} from '@angular/material/bottom-sheet';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';

import { FilterModalComponent } from '../../../components/filter-modal/filter-modal.component';
import { SystemsService } from '../../../services/systems/systems.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { TranslateConfigService } from '../../../services/translate-config/translate-config.service';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-systems',
  templateUrl: './systems.component.html',
  styleUrls: ['./systems.component.scss']
})
export class SystemsComponent implements OnInit {
  // localStorage
  companyId: string;
  projectId: string;
  type: string;
  regionId: string;
  status = '';

  loading = true;
  loadingData = true;
  summaryPage = true;

  // map
  mapLat: number;
  mapLng: number;
  mapZoom: number;
  mapSystems: [] = [];

  systemList: any[] = [];
  sitesValues: any;
  systemListBackup: any[] = [];

  itemSubsection = 'subsection-no-filter';
  itemFilters = 'item-filter-hidden';

  // paginator
  systemListPaginatorBackup: any[] = [];
  systemListLength: number;
  pageSize = 50;
  pageIndex = 1;

  isRefresh:any;

  // Watch systems: list | mosaic
  watch: string = 'mosaic';

  displayedColumns: string[] = [
    'id',
    'name',
    'location',
    'system',
    'connected',
    'lastConnection',
    'notifications',
    'tank_1',
    'tank_2',
    'grid',
    'net',
    'krill'
  ];

  @ViewChild('paginator') paginator: MatPaginator;

  // filter
  filterData: any[] = [];

  // search term
  searchTerm: string = '';

  interval: any;

  constructor(
    private translate: TranslateConfigService,
    private filterSheet: MatBottomSheet,
    private router: Router,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public configsService: ConfigsService,
    private utilService: UtilsService
  ) {}

  async ngOnInit() {
    this.companyId = localStorage.getItem('COMPANY_ID');
    this.projectId = localStorage.getItem('PROJECT_ID');
    this.type = localStorage.getItem('TYPE');
    this.regionId = localStorage.getItem('REGION_ID');

    // get map & sites data
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status
    );

    // get systems data
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );

    // filter data
    this.filterData = await this.systemsService.getFilterData(this.companyId);

    // Agregando el tipo de sistema que sera
    this.systemListBackup.forEach((system) => (system.type = 'eq12kb'));

    if (this.systemList[0]) {
      this.mapLat = this.systemList[0].latitude
        ? this.systemList[0].latitude
        : 0;
      this.mapLng = this.systemList[0].longitude
        ? this.systemList[0].longitude
        : 0;
      this.mapZoom = 5;
    }
    this.loading = false;

   // Inicia la actualización cada 10 minutos (600,000 milisegundos)
   this.isRefresh = localStorage.getItem('refresh');
   if(this.isRefresh == "yes"){
     this.interval = setInterval(() => {
       this.refresh();
       console.log('refresh');
     },240000);
   }
 }

 // Detiene la actualización al salir del componente
 ngOnDestroy() {
   if(this.isRefresh == "yes"){
     clearInterval(this.interval);
   }
 }

  openFilterSheet(): void {
    // return;

    const config = new MatBottomSheetConfig();
    config.data = {
      filterList: this.filterData
    };

    const bottomSheet = this.filterSheet.open(FilterModalComponent, config);

    bottomSheet.afterDismissed().subscribe(async (data) => {
      if (data == undefined) return;
      console.log('data dissmiss: ', data);
      // map sites
      this.mapSystems = data.mapSites.systems;
      this.sitesValues = data.mapSites.sites;

      // system list
      this.systemList = data.systems.data;

      // total paginator items
      this.systemListLength = this.sitesValues.total;
      this.pageIndex = data.currentPage;
    });
  }

  async searchSystemSites() {
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status,
      this.searchTerm
    );
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status,
      this.searchTerm
    );
  }

  receiveSystemList($event) {
    this.systemList = $event;
  }

  formatDate(date, timeZone) {
    return moment(date).utcOffset(timeZone);
  }

  async filterSites(status: string = '') {
    this.status = status;
    if (status == '') {
      await this.getMapSites(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.status,
        this.searchTerm
      );
      await this.getSystems(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.pageIndex.toString(),
        this.status,
        this.searchTerm
      );
    } else {
      /* 
        11000 notification 6
        10100 alert 30
        10010 alarm 18
        00000 disconnect 182
        10000 connect 469
      */
      await this.getMapSites(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.status,
        this.searchTerm
      );
      await this.getSystems(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.pageIndex.toString(),
        this.status,
        this.searchTerm
      );
    }
  }

  async getMapSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    status = '',
    name = ''
  ) {
    const dataMapSites = await this.systemsService.getMapSites(
      companyId,
      projectId,
      type,
      regionId,
      status,
      name
    );
    this.mapSystems = dataMapSites.systems;
    this.sitesValues = dataMapSites.sites;
  }

  async getSystems(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    page: string,
    status = '',
    name = ''
  ) {
    const dataSystem = await this.systemsService.getSystemListPaginated(
      companyId,
      projectId,
      type,
      regionId,
      page,
      status,
      name
    );
    // system list
    this.systemList = dataSystem.data;
    console.log(this.systemList[0]);

    // total paginator items
    this.systemListLength =
      status === ''
        ? this.sitesValues.total
        : status === 'connected'
        ? this.sitesValues.connected
        : status === 'disconnected'
        ? this.sitesValues.disconnected
        : status === 'notification'
        ? this.sitesValues.notification
        : 0;
    this.loadingData = false;
  }

  async getSystemsByPaginator(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );
  }

  async watchList() {
    if (this.watch === 'list') {
      this.watch = 'mosaic';
    } else if (this.watch === 'mosaic') {
      this.watch = 'list';
    }
  }

  async downloadCSVSites() {
    const csv = await this.systemsService.getCSVSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId
    );
    console.log('csv: ', csv);
    this.utilService.downloadCSVFile('All sites - Harvey Fuel', csv);
  }

  async refresh() {
    // get map & sites data
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status
    );

    // get systems data
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );

    // filter data
    this.filterData = await this.systemsService.getFilterData(this.companyId);

    if (this.systemList[0]) {
      this.mapLat = this.systemList[0].latitude
        ? this.systemList[0].latitude
        : 0;
      this.mapLng = this.systemList[0].longitude
        ? this.systemList[0].longitude
        : 0;
      this.mapZoom = 5;
    }
    this.loading = false;
  }
}

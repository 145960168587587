import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AddEditDialogComponent } from 'src/app/components/add-edit-dialog/add-edit-dialog.component';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users;

  constructor(
    private dialog: MatDialog,
    private usersService: UsersService,
    // private toastrService: ToastrService,
    private snackBar: MatSnackBar,
    private translate: TranslateConfigService
  ) {}

  async ngOnInit() {
    this.users = await this.usersService.getUsersList();
  }

  addUser() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'add',
      title: 'user',
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.usersService.addUser(
          data.username,
          data.password,
          data.companyId,
          data.projectId,
          data.email.toLowerCase(),
          data.role,
          data.latitude,
          data.longitude,
        );
        if (!response) {
          this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
          return;
        }
        if (response.status >= 200 && response.status < 400) {
          this.users = await this.usersService.getUsersList();
          this.snackBar.open(
            this.translate.getTranslation('DIALOG.userCreated')
          );
        } else {
          this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  updateUser(user) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'edit',
      title: 'user',
      name: user.username,
      ...user,
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.usersService.updateUser(
          data.id,
          data.username,
          data.password,
          data.companyId,
          data.projectId,
          data.email.toLowerCase(),
          data.role,
          data.latitude,
          data.longitude,
        );
        if (response.status >= 200 && response.status < 400) {
          this.users = await this.usersService.getUsersList();
          this.snackBar.open(
            this.translate.getTranslation('DIALOG.userEdited')
          );
        } else {
          this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  deleteUser(user) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'delete',
      title: 'user',
      name: user.username,
      ...user,
    };

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const response = await this.usersService.deleteUser(data.id);
        if (response.status >= 200 && response.status < 400) {
          this.users = await this.usersService.getUsersList();
          this.snackBar.open(
            this.translate.getTranslation('DIALOG.userDeleted')
          );
        } else {
          this.snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }
}
